import { memo, PropsWithChildren, ReactNode, CSSProperties } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  UIPageMobileViewContentInnerScrollStyle,
  UIPageMobileViewContentScrollStyle,
  UIPageMobileViewContentStyle,
  UIPageMobileViewHeaderOnCloseStyle,
  UIPageMobileViewHeaderStyle,
  UIPageMobileViewHeaderTitleStyle,
  UIPageMobileViewHeaderTitleTextStyle,
  UIPageMobileViewStyle,
  UIPageStyle,
} from "./ui-page.styles";
import { TypographyUITextStyles } from "../typography/typography.types";
import { colors } from "../common/colors";
import { useSelector, useDispatch } from "react-redux";
import { getGoBackOption } from "client/model/operations/operations.selectors";
import { resetGoBackOption } from "client/model/operations/operations";

export interface UIPageMobileViewComponentProps {
  viewContentScroll?: boolean;
  viewHeaderRightSide?: ReactNode;
  viewHeaderTitle?: string;
  viewHeaderOnClose?(): void;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
}

export const defaultProps = {
  viewContentScroll: false,
  viewHeaderTitle: "",
};

const UIPageComponent = ({ children, ...props }: PropsWithChildren<{}>) => {
  return <UIPageStyle {...props}>{children}</UIPageStyle>;
};

const UIPageMobileViewComponent = ({
  children,
  viewContentScroll = defaultProps.viewContentScroll,
  viewHeaderTitle = defaultProps.viewHeaderTitle,
  viewHeaderRightSide,
  viewHeaderOnClose,
  style,
  headerStyle,
  bodyStyle,
  ...props
}: PropsWithChildren<UIPageMobileViewComponentProps>) => {
  const dispatch = useDispatch();
  const goBackOption = useSelector(getGoBackOption);

  const onClick = () => {
    if (typeof goBackOption === "function") {
      dispatch(resetGoBackOption());
      goBackOption();
    } else {
      if (typeof viewHeaderOnClose === "function") viewHeaderOnClose();
    }
  };

  return (
    <UIPageMobileViewStyle style={{ ...style }} {...props}>
      <UIPageMobileViewHeaderStyle>
        <UIPageMobileViewHeaderTitleStyle>
          <UIPageMobileViewHeaderOnCloseStyle onClick={onClick} />
          <UIPageMobileViewHeaderTitleTextStyle
            textStyle={TypographyUITextStyles.h6}
            color={colors.textBlack}
            style={headerStyle}
            isRightCornerElement={!!viewHeaderRightSide}
          >
            {viewHeaderTitle}
          </UIPageMobileViewHeaderTitleTextStyle>
        </UIPageMobileViewHeaderTitleStyle>
        {viewHeaderRightSide}
      </UIPageMobileViewHeaderStyle>
      {viewContentScroll ? (
        <UIPageMobileViewContentScrollStyle>
          <Scrollbars>
            <UIPageMobileViewContentInnerScrollStyle style={bodyStyle}>
              {children}
            </UIPageMobileViewContentInnerScrollStyle>
          </Scrollbars>
        </UIPageMobileViewContentScrollStyle>
      ) : (
        <UIPageMobileViewContentStyle style={bodyStyle}>
          {children}
        </UIPageMobileViewContentStyle>
      )}
    </UIPageMobileViewStyle>
  );
};

export const UIPage = memo(UIPageComponent);
export const UIPageMobileView = memo(UIPageMobileViewComponent);
