import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
} from "@reduxjs/toolkit";
import {
  postFormData,
  postJson,
  fetchExchangeRate,
  deletetJson,
} from "client/common/api";
import {
  AccountType,
  Currency,
  OperationType,
  SnackbarMessageType,
} from "../enums";
import { snackbarShow } from "../snackbar/snackbar";
import { StoreState } from "../types";
import {
  BaseCurrency,
  OperationDebtModelElement,
  OperationExploitationModelElement,
  OperationModel,
  OperationModelElement,
  OperationModelElementSave,
} from "./types";
import {
  loggedUersDebt,
  operationsSave,
  operationCategorySave,
  operationCategoryUpdate,
  operationDelete,
  getCurrencies,
  updateCurrencies,
  addCurrency,
} from "./operations.thunks";
import { Debtholder } from "./types";
import { operationFormIntialValues } from "./core";
import { convertCurrObj } from "client/pages/projects/operations/operations.component commons/custom.currency/custom.currency.funcs";

const initialState: OperationModel = {
  isCategoryMenuOpen: "",
  operationFormValues: operationFormIntialValues,
  myDebts: [],
  exchangeRate: null,
  category: [],
  operations: [],
  operationsDebts: [],
  operationExploitation: {
    volume: 0,
    list: [],
  },
  operationExploitationDetail: null,
  operation: {
    id: "",
    volume: 0,
    category: {
      id: "",
      title: "",
    },
    operationType: OperationType.INCOME,
    description: "",
    photo: "",
    createAt: "",
    project: {
      id: "",
      title: "",
    },
    makerAccount: {
      id: "",
      title: "",
      accountType: AccountType.CASH,
      accountCurrency: Currency.RUB,
    },
    makerUser: {
      id: "",
      firstName: "",
      lastName: "",
    },
    takerAccount: null,
    takerUser: null,
  },
  initOperationType: OperationType.INCOME,
  loading: false,
  goBackOption: null,
  currencies: [],
};

export const setInitOperationType = createAction<OperationType>(
  "setInitOperationType"
);

export const setIsCategoryMenuOpen = createAction<string>(
  "setIsCategoryMenuOpen"
);

export const setOperationFormValues = createAction<any>(
  "setOperationFormValues"
);

export const setBaseCurrencies = createAction<any>("setBaseCurrencies");

export const resetOperationList = createAction("resetOperationList");

export const resetExploitationDetail = createAction("resetExploitationDetail");

export const resetOperationFormValues = createAction(
  "resetOperationFormValues"
);

export const resetGoBackOption = createAction("resetGoBackOption");

type voidFunc = () => void;

export const setGoBackOption = createAction<voidFunc>("setGoBackOption");

export const operationsGetProjectList = createAsyncThunk(
  "operationsGetProjectList",
  async (projectId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await postJson<{ list: OperationModelElement[] }>(
        "operations-project-list",
        { projectId }
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const operationsGetCategoryList = createAsyncThunk(
  "operationsGetCategoryList",
  async (projectId: string, { rejectWithValue, dispatch }) => {
    try {
      // TODO: type categories
      const response = await postJson<{ list: Array<object> }>(
        "operations-categories-list",
        { projectId }
      );

      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const operationsGetCurrencyRate = createAsyncThunk(
  "operationsGetCurrencyRate",
  async (
    registeredCurrencies: { [key: string]: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetchExchangeRate(registeredCurrencies);
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Курс не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const operationsGetUsertList = createAsyncThunk(
  "operationsGetUsertList",
  async (projectId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await postJson<{ list: OperationModelElement[] }>(
        "operations-user-list",
        { projectId }
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const operationsDebtsGetUsertList = createAsyncThunk(
  "operations-user-debtors",
  async (projectId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await postJson<Debtholder[]>("operations-user-debtors", {
        projectId,
      });
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const operationsExploitationGetList = createAsyncThunk(
  "exploitations-project-list",
  async (projectId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await postJson<any>("exploitations-project-list", {
        projectId,
      });

      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const operationsGetDetails = createAsyncThunk(
  "operationsGetDetails",
  async (
    { projectId, operationId }: { projectId: string; operationId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postJson<OperationModelElement>("operations-get", {
        projectId,
        operationId,
      });
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const exploitationGetDetail = createAsyncThunk(
  "exploitationGetDetail",
  async (
    {
      projectId,
      exploitationId,
    }: { projectId: string; exploitationId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postJson<OperationModelElement>(
        "exploitations-get",
        { projectId, exploitationId }
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

//=================operationDelete start =====================================

//=================operationDelete end =====================================

export const operationsExRatetModel = createSelector(
  (state: StoreState) => state.operations.exchangeRate,
  (exchangeRate) => exchangeRate
);

export const operationsListModel = createSelector(
  (state: StoreState) => state.operations.operations,
  (operations) => operations
);

export const exploitationListModal = createSelector(
  (state: StoreState) => state.operations.operationExploitation,
  (operationExploitation) => operationExploitation
);

export const operationsCategoriesListModel = (operationType: OperationType) =>
  createSelector(
    (state: StoreState) => state.operations.category,
    (categories) => {
      return categories.filter((category) => category.type === operationType);
    }
  );

export const operationsDebtdListModel = createSelector(
  (state: StoreState) => state.operations.operationsDebts,
  (operationsDebts) => operationsDebts
);

export const operationsDebtdModel = (debtId: string) =>
  createSelector(
    (state: StoreState) => state.operations.operationsDebts,
    (operationsDebts) => operationsDebts.find(({ id }) => id === debtId)
  );

export const operationModel = createSelector(
  (state: StoreState) => state.operations.operation,
  (operation) => operation
);

export const operationsInitOperationTypeModel = createSelector(
  (state: StoreState) => state.operations.initOperationType,
  (initOperationType) => initOperationType
);

export const operationsLoadingModel = createSelector(
  (state: StoreState) => state.operations.loading,
  (loading) => loading
);

export const exploitationDetailModel = createSelector(
  (state: StoreState) => state.operations.operationExploitationDetail,
  (operationExploitationDetail) => operationExploitationDetail
);

export default createReducer(initialState, (builder) => {
  builder
    .addCase(resetOperationList, (state) => {
      state.operations = initialState.operations;
    })
    .addCase(setInitOperationType, (state, { payload }) => {
      state.initOperationType = payload;
    })
    .addCase(setIsCategoryMenuOpen, (state, { payload }) => {
      state.isCategoryMenuOpen = payload;
    })
    .addCase(setOperationFormValues, (state, { payload }) => {
      state.operationFormValues = payload;
    })
    .addCase(loggedUersDebt.pending, (state) => {
      state.myDebts = [];
      state.loading = true;
    })
    .addCase(loggedUersDebt.rejected, (state) => {
      state.myDebts = [];
      state.loading = false;
    })
    .addCase(loggedUersDebt.fulfilled, (state, { payload }) => {
      state.myDebts = payload;
      state.loading = false;
    })
    .addCase(operationsGetProjectList.pending, (state) => {
      state.operations = [];
      state.loading = true;
    })
    .addCase(operationsGetProjectList.rejected, (state) => {
      state.operations = [];
      state.loading = false;
    })
    .addCase(operationsGetProjectList.fulfilled, (state, { payload }) => {
      state.operations = payload.list;
      state.loading = false;
    })
    .addCase(operationsGetUsertList.pending, (state) => {
      state.operations = [];
      state.loading = true;
    })
    .addCase(operationsGetUsertList.rejected, (state) => {
      state.operations = [];
      state.loading = false;
    })
    .addCase(operationsGetUsertList.fulfilled, (state, { payload }) => {
      state.operations = payload.list;
      state.loading = false;
    })
    .addCase(operationDelete.pending, (state) => {
      state.loading = true;
    })
    .addCase(operationDelete.rejected, (state) => {
      state.loading = false;
    })
    .addCase(operationDelete.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(operationsExploitationGetList.pending, (state) => {
      state.operationExploitation.list = [];
      state.loading = true;
    })
    .addCase(operationsExploitationGetList.rejected, (state) => {
      state.operationExploitation.list = [];
      state.loading = false;
    })
    .addCase(operationsExploitationGetList.fulfilled, (state, { payload }) => {
      state.operationExploitation.list = payload.list;
      state.operationExploitation.volume = payload.list.reduce(
        (acc: number, cur: OperationExploitationModelElement) =>
          (acc += cur.volume),
        0
      );
      state.loading = false;
    })

    .addCase(exploitationGetDetail.pending, (state) => {
      state.operationExploitationDetail = {};
      state.loading = true;
    })
    .addCase(exploitationGetDetail.rejected, (state) => {
      state.operationExploitationDetail = {};
      state.loading = false;
    })
    .addCase(exploitationGetDetail.fulfilled, (state, { payload }) => {
      state.operationExploitationDetail = payload;
      state.loading = false;
    })
    .addCase(resetExploitationDetail, (state) => {
      state.operationExploitationDetail = null;
      state.loading = false;
    })
    // 8888888888888888888888888888888888888888888888888888888888888888888

    .addCase(resetOperationFormValues, (state) => {
      state.operationFormValues = operationFormIntialValues;
      state.isCategoryMenuOpen = "";
      state.loading = false;
    })

    .addCase(setGoBackOption, (state, { payload }) => {
      state.goBackOption = payload;
      state.loading = false;
    })

    .addCase(resetGoBackOption, (state) => {
      state.goBackOption = null;
      state.loading = false;
    })
    // 8888888888888888888888888888888888888888888888888888888888888888888
    .addCase(operationsDebtsGetUsertList.pending, (state) => {
      state.operationsDebts = [];
      state.loading = true;
    })
    .addCase(operationsDebtsGetUsertList.rejected, (state) => {
      state.operationsDebts = [];
      state.loading = false;
    })
    .addCase(operationsDebtsGetUsertList.fulfilled, (state, { payload }) => {
      state.operationsDebts = payload;
      state.loading = false;
    })

    .addCase(operationsGetDetails.pending, (state) => {
      state.loading = true;
    })
    .addCase(operationsGetDetails.rejected, (state) => {
      state.loading = false;
    })
    .addCase(operationsGetDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.operation = payload;
    })

    .addCase(operationsSave.pending, (state) => {
      state.loading = true;
    })
    .addCase(operationsSave.rejected, (state) => {
      state.loading = false;
    })
    .addCase(operationsSave.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.operation = payload;
    })

    // -------------
    .addCase(operationCategorySave.pending, (state) => {
      state.loading = true;
    })
    .addCase(operationCategorySave.rejected, (state) => {
      state.loading = false;
    })
    .addCase(operationCategorySave.fulfilled, (state, { payload }) => {
      state.loading = false;
      // state.operation = payload;
    })

    //-------------

    .addCase(operationCategoryUpdate.pending, (state) => {
      state.loading = true;
    })
    .addCase(operationCategoryUpdate.rejected, (state) => {
      state.loading = false;
    })
    .addCase(operationCategoryUpdate.fulfilled, (state, { payload }) => {
      state.loading = false;
      // state.operation = payload;
    })

    //******************************** */

    .addCase(operationsGetCategoryList.pending, (state) => {
      state.loading = true;
    })
    .addCase(operationsGetCategoryList.rejected, (state) => {
      state.loading = false;
    })
    .addCase(operationsGetCategoryList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.category = payload.list;
    })

    .addCase(getCurrencies.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCurrencies.rejected, (state) => {
      state.loading = false;
    })
    .addCase(getCurrencies.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currencies = payload.map((curr) => convertCurrObj(curr));
    })

    .addCase(addCurrency.pending, (state) => {
      state.loading = true;
    })
    .addCase(addCurrency.rejected, (state) => {
      state.loading = false;
    })
    .addCase(addCurrency.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currencies = payload.map((curr: BaseCurrency) =>
        convertCurrObj(curr)
      );
    })

    .addCase(setBaseCurrencies, (state, { payload }) => {
      state.currencies = payload;
    })

    .addCase(updateCurrencies.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateCurrencies.rejected, (state) => {
      state.loading = false;
    })
    .addCase(updateCurrencies.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currencies = payload.map((curr) => convertCurrObj(curr));
    })

    .addCase(operationsGetCurrencyRate.pending, (state) => {
      state.loading = true;
    })
    .addCase(operationsGetCurrencyRate.rejected, (state) => {
      state.loading = false;
    })
    .addCase(operationsGetCurrencyRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.exchangeRate = payload;
    });
});
