import { ElementType, lazy } from "react";
import { createPageContext } from "client/common/pages/page-context";
import { PageName } from "./page-name";
import { PagePath } from "./page-path";

export interface PageParams {
  [key: string]: string | number;
}

export interface RouterConfig<PageName> {
  children?: RouterConfig<PageName>[];
  component?: ElementType;
  name: PageName;
  needAuthorization?: boolean;
  needRedirectIfAuthorization?: boolean;
  path?: string;
  redirectTo?: PageName;
  sort?: number;
}

export const pageContext = createPageContext<PageName>();

export const getPages = (): RouterConfig<PageName>[] => [
  {
    children: [
      {
        component: lazy(() => import("../../pages/auth/auth.component")),
        name: PageName.AUTH,
        path: PagePath.AUTH,
        needRedirectIfAuthorization: true,
      },
      {
        component: lazy(() => import("../../pages/help/help.component")),
        name: PageName.HELP,
        path: PagePath.HELP,
        needAuthorization: true,
      },
      {
        component: lazy(() => import("../../pages/about/about.component")),
        name: PageName.ABOUT_PROJECT,
        path: PagePath.ABOUT_PROJECT,
        needAuthorization: true,
      },
      {
        children: [
          {
            component: lazy(
              () => import("../../pages/accounts/create/create.component")
            ),
            name: PageName.MY_ACCOUNTS_CREATE,
            path: PagePath.MY_ACCOUNTS_CREATE,
            needAuthorization: true,
          },
          {
            component: lazy(
              () => import("../../pages/accounts/details/details.component")
            ),
            name: PageName.MY_ACCOUNTS_DETAILS,
            path: PagePath.MY_ACCOUNTS_DETAILS,
            needAuthorization: true,
          },
          {
            component: lazy(
              () => import("../../pages/accounts/accounts.component")
            ),
            name: PageName.MY_ACCOUNTS,
            path: PagePath.MY_ACCOUNTS,
            needAuthorization: true,
          },
        ],
        name: PageName.MY_ACCOUNTS,
        path: PagePath.MY_ACCOUNTS,
        needAuthorization: true,
      },
      {
        children: [
          {
            component: lazy(
              () =>
                import("../../pages/projects/operations/operations.component")
            ),
            name: PageName.PROJECTS_OPERATION,
            path: PagePath.PROJECTS_OPERATION,
            needAuthorization: true,
          },
          {
            children: [
              {
                children: [
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/category/subcategory/subcategory-create.component"
                        )
                    ),
                    name: PageName.PROJECTS_CREATE_CATEGORY_ID,
                    path: PagePath.PROJECTS_CREATE_CATEGORY_ID,
                    needAuthorization: true,
                  },
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/category/categories-create.component"
                        )
                    ),
                    name: PageName.PROJECTS_CREATE_CATEGORY,
                    path: PagePath.PROJECTS_CREATE_CATEGORY,
                    needAuthorization: true,
                  },
                ],
                name: PageName.PROJECTS_CREATE_CATEGORY,
                path: PagePath.PROJECTS_CREATE_CATEGORY,
                needAuthorization: true,
              },
              {
                children: [
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/invite/invite-create.component"
                        )
                    ),
                    name: PageName.PROJECTS_CREATE_PARTICIPANTS_INVITE,
                    path: PagePath.PROJECTS_CREATE_PARTICIPANTS_INVITE,
                    needAuthorization: true,
                  },
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/participants/list/list-create.component"
                        )
                    ),
                    name: PageName.PROJECTS_CREATE_PARTICIPANTS_LIST,
                    path: PagePath.PROJECTS_CREATE_PARTICIPANTS_LIST,
                    needAuthorization: true,
                  },
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/participants/add/add-create.component"
                        )
                    ),
                    name: PageName.PROJECTS_CREATE_PARTICIPANTS_ADD_ID,
                    path: PagePath.PROJECTS_CREATE_PARTICIPANTS_ADD_ID,
                    needAuthorization: true,
                  },
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/participants/edit/edit-create.component"
                        )
                    ),
                    name: PageName.PROJECTS_CREATE_PARTICIPANTS_EDIT_ID,
                    path: PagePath.PROJECTS_CREATE_PARTICIPANTS_EDIT_ID,
                    needAuthorization: true,
                  },
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/participants/participants-create.component"
                        )
                    ),
                    name: PageName.PROJECTS_CREATE_PARTICIPANTS,
                    path: PagePath.PROJECTS_CREATE_PARTICIPANTS,
                    needAuthorization: true,
                  },
                ],
                name: PageName.PROJECTS_CREATE_PARTICIPANTS,
                path: PagePath.PROJECTS_CREATE_PARTICIPANTS,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/operations/operations.component commons/custom.currency/custom.currency.edit"
                    )
                ),
                name: PageName.PROJECTS_CREATE_CUSTOM_CURRENCIES_EDIT,
                path: PagePath.PROJECTS_CREATE_CUSTOM_CURRENCIES_EDIT,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/operations/operations.component commons/custom.currency/custom.currency.add"
                    )
                ),
                name: PageName.PROJECTS_CREATE_CUSTOM_CURRENCIES_ADD,
                path: PagePath.PROJECTS_CREATE_CUSTOM_CURRENCIES_ADD,
                needAuthorization: true,
              },
            ],
            component: lazy(
              () => import("../../pages/projects/create/create.component")
            ),
            name: PageName.PROJECTS_CREATE,
            path: PagePath.PROJECTS_CREATE,
            needAuthorization: true,
          },
          {
            children: [
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/details/operations-list/custom.categories/create/custom.categories.create.form"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_OPERATION_CREATE_CUSTOM_CATEGORY,
                path: PagePath.PROJECTS_DETAILS_OPERATION_CREATE_CUSTOM_CATEGORY,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/details/operations-list/custom.categories/update/custom.categories.update.form"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CATEGORY,
                path: PagePath.PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CATEGORY,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/operations/operations.component commons/custom.currency/custom.currency.edit"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CURRENCY,
                path: PagePath.PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CURRENCY,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/operations/operations.component commons/custom.currency/custom.currency.add"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_OPERATION_ADD_CUSTOM_CURRENCY,
                path: PagePath.PROJECTS_DETAILS_OPERATION_ADD_CUSTOM_CURRENCY,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/details/operations-list/OperationsList"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_OPERATIONS_LIST,
                path: PagePath.PROJECTS_DETAILS_OPERATIONS_LIST,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/details/exploitations-list/index"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_EXPLOITATIONS_LIST,
                path: PagePath.PROJECTS_DETAILS_EXPLOITATIONS_LIST,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/details/operations-list/debt-pay-off-form/PayOffForm"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_DEBT_PAYOFF,
                path: PagePath.PROJECTS_DETAILS_DEBT_PAYOFF,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/details/my-debts-list/MyDebtsList"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_MY_DEBT,
                path: PagePath.PROJECTS_DETAILS_MY_DEBT,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/details/my-debtholders-list/MyDebtholders"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_MY_DEBTHOLDERS,
                path: PagePath.PROJECTS_DETAILS_MY_DEBTHOLDERS,
                needAuthorization: true,
              },
              {
                children: [
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/operations/operations-debt.component"
                        )
                    ),
                    name: PageName.PROJECTS_DETAILS_DEBTS_CREATE,
                    path: PagePath.PROJECTS_DETAILS_DEBTS_CREATE,
                    needAuthorization: true,
                  },
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/operations/operations-debtholders.component"
                        )
                    ),
                    name: PageName.PROJECTS_DETAILS_MYDEBTHOLDERS_CREATE,
                    path: PagePath.PROJECTS_DETAILS_MYDEBTHOLDERS_CREATE,
                    needAuthorization: true,
                  },
                  {
                    component: lazy(
                      () => import("../../pages/projects/debts/debts.component")
                    ),
                    name: PageName.PROJECTS_DETAILS_DEBTS,
                    path: PagePath.PROJECTS_DETAILS_DEBTS,
                    needAuthorization: true,
                  },
                ],
                name: PageName.PROJECTS_DETAILS_DEBTS,
                path: PagePath.PROJECTS_DETAILS_DEBTS,
                needAuthorization: true,
              },
              {
                children: [
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/details/operations-list/operation-details/OperationDetails"
                        )
                    ),
                    name: PageName.PROJECTS_DETAILS_OPERATION_INFO,
                    path: PagePath.PROJECTS_DETAILS_OPERATION_INFO,
                    needAuthorization: true,
                  },
                  {
                    component: lazy(
                      () =>
                        import(
                          "../../pages/projects/operations/operations.component"
                        )
                    ),
                    name: PageName.PROJECTS_DETAILS_OPERATION,
                    path: PagePath.PROJECTS_DETAILS_OPERATION,
                    needAuthorization: true,
                  },
                ],
                name: PageName.PROJECTS_DETAILS_OPERATION,
                path: PagePath.PROJECTS_DETAILS_OPERATION,
                needAuthorization: true,
              },
              {
                children: [
                  {
                    children: [
                      {
                        component: lazy(
                          () =>
                            import(
                              "../../pages/projects/category/subcategory/subcategory-info.component"
                            )
                        ),
                        name: PageName.PROJECTS_DETAILS_INFO_CATEGORY_ID,
                        path: PagePath.PROJECTS_DETAILS_INFO_CATEGORY_ID,
                        needAuthorization: true,
                      },
                      {
                        component: lazy(
                          () =>
                            import(
                              "../../pages/projects/category/categories-info.component"
                            )
                        ),
                        name: PageName.PROJECTS_DETAILS_INFO_CATEGORY,
                        path: PagePath.PROJECTS_DETAILS_INFO_CATEGORY,
                        needAuthorization: true,
                      },
                    ],
                    name: PageName.PROJECTS_DETAILS_INFO_CATEGORY,
                    path: PagePath.PROJECTS_DETAILS_INFO_CATEGORY,
                    needAuthorization: true,
                  },
                  {
                    children: [
                      {
                        component: lazy(
                          () =>
                            import(
                              "../../pages/projects/invite/invite-info.component"
                            )
                        ),
                        name: PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS_INVITE,
                        path: PagePath.PROJECTS_DETAILS_INFO_PARTICIPANTS_INVITE,
                        needAuthorization: true,
                      },
                      {
                        component: lazy(
                          () =>
                            import(
                              "../../pages/projects/participants/list/list-info.component"
                            )
                        ),
                        name: PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS_LIST,
                        path: PagePath.PROJECTS_DETAILS_INFO_PARTICIPANTS_LIST,
                        needAuthorization: true,
                      },
                      {
                        component: lazy(
                          () =>
                            import(
                              "../../pages/projects/participants/add/add-info.component"
                            )
                        ),
                        name: PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS_ADD_ID,
                        path: PagePath.PROJECTS_DETAILS_INFO_PARTICIPANTS_ADD_ID,
                        needAuthorization: true,
                      },
                      {
                        component: lazy(
                          () =>
                            import(
                              "../../pages/projects/participants/edit/edit-info.component"
                            )
                        ),
                        name: PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS_EDIT_ID,
                        path: PagePath.PROJECTS_DETAILS_INFO_PARTICIPANTS_EDIT_ID,
                        needAuthorization: true,
                      },
                      {
                        component: lazy(
                          () =>
                            import(
                              "../../pages/projects/participants/participants-info.component"
                            )
                        ),
                        name: PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS,
                        path: PagePath.PROJECTS_DETAILS_INFO_PARTICIPANTS,
                        needAuthorization: true,
                      },
                    ],
                    name: PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS,
                    path: PagePath.PROJECTS_DETAILS_INFO_PARTICIPANTS,
                    needAuthorization: true,
                  },
                ],
                component: lazy(
                  () =>
                    import(
                      "../../pages/projects/details/details-info/details-info.component"
                    )
                ),
                name: PageName.PROJECTS_DETAILS_INFO,
                path: PagePath.PROJECTS_DETAILS_INFO,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () => import("../../pages/projects/details/details.component")
                ),
                name: PageName.PROJECTS_DETAILS,
                path: PagePath.PROJECTS_DETAILS,
                needAuthorization: true,
              },
            ],
            name: PageName.PROJECTS_DETAILS,
            path: PagePath.PROJECTS_DETAILS,
            needAuthorization: true,
          },
          {
            component: lazy(
              () => import("../../pages/projects/projects.component")
            ),
            name: PageName.PROJECTS,
            path: PagePath.PROJECTS,
            needAuthorization: true,
          },
        ],
        name: PageName.PROJECTS,
        path: PagePath.PROJECTS,
        needAuthorization: true,
      },
      {
        component: lazy(() => import("../../pages/exploitation/create")),
        name: PageName.PROJECTS_DETAILS_OPERATION_PROJECTS_LIST,
        path: PagePath.PROJECTS_DETAILS_OPERATION_PROJECTS_LIST,
        needAuthorization: true,
      },
      {
        children: [
          {
            children: [
              {
                component: lazy(
                  () =>
                    import(
                      "../../pages/account/set-password/set-password.component"
                    )
                ),
                name: PageName.ACCOUNT_SETTINGS_PASSWORD,
                path: PagePath.ACCOUNT_SETTINGS_PASSWORD,
                needAuthorization: true,
              },
              {
                component: lazy(
                  () =>
                    import("../../pages/account/settings/settings.component")
                ),
                name: PageName.ACCOUNT_SETTINGS,
                path: PagePath.ACCOUNT_SETTINGS,
                needAuthorization: true,
              },
            ],
            name: PageName.ACCOUNT_SETTINGS,
            path: PagePath.ACCOUNT_SETTINGS,
            needAuthorization: true,
          },
          {
            component: lazy(
              () => import("../../pages/account/edit/edit.component")
            ),
            name: PageName.ACCOUNT_EDIT,
            path: PagePath.ACCOUNT_EDIT,
            needAuthorization: true,
          },
          {
            component: lazy(
              () =>
                import("../../pages/account/edit-photo/edit-photo.component")
            ),
            name: PageName.ACCOUNT_EDIT_PHOTO,
            path: PagePath.ACCOUNT_EDIT_PHOTO,
            needAuthorization: true,
          },
          {
            component: lazy(
              () => import("../../pages/account/account.component")
            ),
            name: PageName.ACCOUNT,
            path: PagePath.ACCOUNT,
            needAuthorization: true,
          },
        ],
        name: PageName.ACCOUNT,
        path: PagePath.ACCOUNT,
        needAuthorization: true,
      },
      {
        children: [
          // {
          //   component: lazy(
          //     () =>
          //       import("../../pages/projects/details/exploitations-list/index")
          //   ),
          //   name: PageName.PROJECTS_DETAILS_EXPLOITATION_DETAILS,
          //   path: PagePath.PROJECTS_DETAILS_EXPLOITATION_DETAILS,
          //   needAuthorization: true,
          // },
          {
            component: lazy(() => import("../../pages/exploitation/create")),
            name: PageName.EXPLOITATION_CREATE,
            path: PagePath.EXPLOITATION_CREATE,
            needAuthorization: true,
          },
          {
            component: lazy(
              () => import("../../pages/exploitation/create/form")
            ),
            name: PageName.EXPLOITATION_CREATE_WITH_PROJECT,
            path: PagePath.EXPLOITATION_CREATE_WITH_PROJECT,
            needAuthorization: true,
          },
          {
            component: lazy(
              () =>
                import("../../pages/exploitation/exploitation-detail.component")
            ),
            name: PageName.EXPLOITATION_SHOWDETAILS,
            path: PagePath.EXPLOITATION_SHOWDETAILS,
            needAuthorization: true,
          },
          {
            component: lazy(
              () =>
                import(
                  "../../pages/projects/details/exploitations-list/exploitation-details/index"
                )
            ),
            name: PageName.EXPLOITATION_DETAIL,
            path: PagePath.EXPLOITATION_DETAIL,
            needAuthorization: true,
          },
        ],
        name: PageName.EXPLOITATION,
        path: PagePath.EXPLOITATION,
        needAuthorization: true,
      },
      {
        name: PageName.PAGE_NOT_FOUND,
        path: PagePath.PAGE_NOT_FOUND,
        redirectTo: PageName.HOME,
      },
    ],
    component: lazy(() => import("../../pages/home/home.component")),
    name: PageName.HOME,
    path: PagePath.HOME,
  },
];
