import { PageName } from "./page-name";

export const PagePath: { [key in PageName]: string } = {
  [PageName.HOME]: "/",
  [PageName.AUTH]: "auth",
  [PageName.PROJECTS]: "projects",
  [PageName.PROJECTS_OPERATION]: "operation",
  [PageName.PROJECTS_DETAILS]: "/:projectId",
  [PageName.PROJECTS_DETAILS_DEBTS]: "debts",
  [PageName.PROJECTS_DETAILS_DEBTS_CREATE]: "/:debtId",
  [PageName.PROJECTS_DETAILS_MYDEBTHOLDERS_CREATE]: "/:debtId",
  [PageName.PROJECTS_DETAILS_OPERATION]: "operation",
  [PageName.PROJECTS_DETAILS_OPERATION_PROJECTS_LIST]: "projects-list",
  [PageName.PROJECTS_DETAILS_OPERATION_CREATE_CUSTOM_CATEGORY]:
    "operationCustomCategory/create",
  [PageName.PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CATEGORY]:
    "operationCustomCategory/update",
  [PageName.PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CURRENCY]:
    "operationCurrency/update",
  [PageName.PROJECTS_DETAILS_OPERATION_ADD_CUSTOM_CURRENCY]:
    "operationCurrency/add",
  [PageName.PROJECTS_DETAILS_OPERATION_INFO]: "/:operationId",
  [PageName.PROJECTS_DETAILS_EXPLOITATION_DETAILS]: "/:exploitationId",
  [PageName.PROJECTS_DETAILS_INFO]: "info",
  [PageName.PROJECTS_DETAILS_INFO_CATEGORY]: "category",
  [PageName.PROJECTS_DETAILS_INFO_CATEGORY_ID]: "/:subcategoryId",
  [PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS]: "participants",
  [PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS_LIST]: "list",
  [PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS_INVITE]: "invite",
  [PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS_EDIT_ID]: "edit/:participantId",
  [PageName.PROJECTS_DETAILS_INFO_PARTICIPANTS_ADD_ID]: "add/:participantId",
  [PageName.PROJECTS_CREATE]: "create",
  [PageName.PROJECTS_CREATE_CATEGORY]: "category",
  [PageName.PROJECTS_CREATE_CATEGORY_ID]: "/:subcategoryId",
  [PageName.PROJECTS_CREATE_PARTICIPANTS]: "participants",
  [PageName.PROJECTS_CREATE_CUSTOM_CURRENCIES_EDIT]: "customCurrencies/edit",
  [PageName.PROJECTS_CREATE_CUSTOM_CURRENCIES_ADD]: "customCurrencies/add",
  [PageName.PROJECTS_CREATE_PARTICIPANTS_LIST]: "list",
  [PageName.PROJECTS_CREATE_PARTICIPANTS_INVITE]: "invite",
  [PageName.PROJECTS_CREATE_PARTICIPANTS_EDIT_ID]: "edit/:participantId",
  [PageName.PROJECTS_CREATE_PARTICIPANTS_ADD_ID]: "add/:participantId",
  [PageName.ACCOUNT]: "account",
  [PageName.ACCOUNT_EDIT]: "edit",
  [PageName.ACCOUNT_EDIT_PHOTO]: "edit-photo",
  [PageName.ACCOUNT_SETTINGS]: "settings",
  [PageName.ACCOUNT_SETTINGS_PASSWORD]: "password",
  [PageName.MY_ACCOUNTS]: "accounts",
  [PageName.MY_ACCOUNTS_CREATE]: "create",
  [PageName.MY_ACCOUNTS_DETAILS]: "/:accountId",
  [PageName.HELP]: "help",
  [PageName.ABOUT_PROJECT]: "about",
  [PageName.PAGE_NOT_FOUND]: "*",
  [PageName.PROJECTS_DETAILS_OPERATIONS_LIST]: "operationList",
  [PageName.PROJECTS_DETAILS_EXPLOITATIONS_LIST]: "exploitationsList",
  [PageName.PROJECTS_DETAILS_MY_DEBT]: "myDebt",
  [PageName.PROJECTS_DETAILS_MY_DEBTHOLDERS]: "myDebtholders",
  [PageName.PROJECTS_DETAILS_DEBT_PAYOFF]: "debtPayOff",
  [PageName.EXPLOITATION_DETAIL]: "/:projectId/:exploitationId",
  [PageName.EXPLOITATION_SHOWDETAILS]: "/detail/:projectId/:exploitationId",
  [PageName.EXPLOITATION]: "exploitation",
  [PageName.EXPLOITATION_CREATE_WITH_PROJECT]: "/project/:projectId",
  [PageName.EXPLOITATION_CREATE]: "create",
};
