import { HTMLAttributes, memo } from "react";
import { avatarUIsizes } from "./avatar.constants";
import { AvatarUIStyle, AvatarUIWrapperStyle } from "./avatar.styles";
import { AvatarUISizes } from "./avatar.types";
import { getApiStatic } from "client/common/api";
import { IconWrapper, Icon } from "./avatar.styles";
import { defaultAvatarIcon } from "../icons";

export interface AvatarUIComponentProps extends HTMLAttributes<HTMLDivElement> {
  avatarSize?: AvatarUISizes;
  avatar?: string;
  avatarDefault: string;
}

export const defaultProps = {
  avatarSize: AvatarUISizes.small,
};

const AvatarUIComponent = ({
  avatarSize = defaultProps.avatarSize,
  avatar,
  avatarDefault,
  ...props
}: AvatarUIComponentProps) => {
  const { size } = avatarUIsizes[avatarSize];
  const isValid = !!avatar;
  const src = isValid ? avatar : avatarDefault;
  const className = isValid ? "avatar" : "default";

  return (
    <AvatarUIWrapperStyle {...props} avatarSize={size}>
      <AvatarUIStyle className={className} src={src} />
    </AvatarUIWrapperStyle>
  );
};

interface UserAvatarProps {
  userPicture: string | null | undefined;
  size: string;
  marginBottom?: string;
}

export const StyledUserAvatar = ({
  userPicture,
  size,
  marginBottom,
}: UserAvatarProps) => {
  const source =
    userPicture?.slice(0, 4) === "blob"
      ? userPicture
      : getApiStatic(userPicture);
  return (
    <IconWrapper size={size} marginBottom={marginBottom}>
      <Icon
        src={source || defaultAvatarIcon}
        // getApiStatic(userPicture) || defaultAvatarIcon
        alt="user icon"
      />
    </IconWrapper>
  );
};

export const AvatarUI = memo(AvatarUIComponent);