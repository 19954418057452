import { useCallback, useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PageName } from "client/common/pages/page-name";
import { pageContext } from "client/common/pages/page.settings";
import { setInitOperationType } from "client/model/operations/operations";
import { Exploitation, OperationType } from "client/model/enums";
import { PagePath } from "client/common/pages/page-path";
import { projectsElementModel } from "client/model/projects/projects";
import { revenue, expense, expluatation } from "client/ui/icons";

interface OperationDescription {
  title: string;
  instruction: string;
  iconUrl: string;
  onClick(): void;
}

const useGetOperationTypes = (handleCloseMenu: () => void) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getPageUrl } = useContext(pageContext);
  const { projectId } = useParams<{ projectId: string }>();
  const currentProject = useSelector(projectsElementModel);

  const handleToCreateOperation = useCallback(() => {
    history.push(getPageUrl(PageName.PROJECTS_OPERATION));
  }, [history, getPageUrl]);

  const handleOnCreateIncome = useCallback(() => {
    dispatch(setInitOperationType(OperationType.INCOME));
    handleCloseMenu();
    if (projectId) {
      handleToCreateOperation();
    } else {
      history.push({
        pathname: getPageUrl(PageName.PROJECTS_DETAILS_OPERATION_PROJECTS_LIST),
        state: {
          forwardLink: getPageUrl(PageName.PROJECTS_OPERATION),
          operatonType: OperationType.INCOME,
        },
      });
    }
  }, [dispatch, handleCloseMenu, handleToCreateOperation]);

  const handleOnCreateExpenses = useCallback(() => {
    dispatch(setInitOperationType(OperationType.EXPENSES));
    handleCloseMenu();
    if (projectId) {
      handleToCreateOperation();
    } else {
      history.push({
        pathname: getPageUrl(PageName.PROJECTS_DETAILS_OPERATION_PROJECTS_LIST),
        state: {
          forwardLink: getPageUrl(PageName.PROJECTS_OPERATION),
          operatonType: OperationType.EXPENSES,
        },
      });
    }
  }, [dispatch, handleCloseMenu, handleToCreateOperation]);

  const handleOnCreateProject = () => {
    handleCloseMenu();
    if (projectId) {
      history.push({
        pathname: getPageUrl(PageName.EXPLOITATION_CREATE_WITH_PROJECT, {
          projectId,
        }),
      });
    } else {
      history.push({
        pathname: getPageUrl(PageName.EXPLOITATION_CREATE),
      });
    }
  };

  const operationTypes: OperationDescription[] = useMemo(
    () =>
      [
        {
          title: "Доход",
          instruction: "Добавьте свои доходы",
          iconUrl: revenue,
          onClick: handleOnCreateIncome,
        },
        {
          title: "Расход",
          instruction: "Добавьте свои расходы",
          iconUrl: expense,
          onClick: handleOnCreateExpenses,
        },
        {
          title: "Эксплуатация",
          instruction: "Добавьте эксплуатацию объекта",
          iconUrl: expluatation,
          onClick: handleOnCreateProject,
        },
      ].filter(
        (operationType) =>
          operationType.title === "Расход" ||
          (operationType.title === "Доход" &&
            (history.location.pathname === `/${PagePath.PROJECTS}` ||
              currentProject.exploitation === Exploitation.NONE)) ||
          // operationType.title === "Эксплуатация" ||
          (operationType.title === "Эксплуатация" &&
            (history.location.pathname === `/${PagePath.PROJECTS}` ||
              currentProject.exploitation !== Exploitation.NONE))
      ),
    []
  );

  return operationTypes;
};

export default useGetOperationTypes;
