import { ButtonHTMLAttributes, memo, PropsWithChildren } from 'react';
import { buttonUIdisabledColors, buttonUIsizes } from './button.constants';
import { ButtonUIDisabledColors, ButtonUISizes, ButtonUItextTransform } from './button.types';
import { ButtonUIStyle } from './button.styles';

export interface ButtonUIComponentProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    size?: ButtonUISizes,
    disabledColor?: ButtonUIDisabledColors;
    textTransform?: ButtonUItextTransform;
}

export const defaultProps = {
    disabled: false,
    size: ButtonUISizes.large,
    variantDisabled: ButtonUIDisabledColors.Trout,
    textTransform: ButtonUItextTransform.Uppercase,
}

const ButtonUIComponent = ({
    children,
    disabled = defaultProps.disabled,
    size = defaultProps.size,
    disabledColor = defaultProps.variantDisabled,
    textTransform = defaultProps.textTransform,
    ...props
}: PropsWithChildren<ButtonUIComponentProps>) => {
    const currentlySize = buttonUIsizes[size];
    const currentlydisabledColor = buttonUIdisabledColors[disabledColor];

    return (
        <ButtonUIStyle
            element='button'
            disabled={disabled}
            size={currentlySize}
            disabledColor={currentlydisabledColor}
            textTransform={textTransform}
            {...props}
        >
            {children}
        </ButtonUIStyle>
    );
};

export const ButtonUI = memo(ButtonUIComponent);
