export interface AvatarUISize {
    size: number;
}

export enum AvatarUISizes {
    small = 'small',
    mediumSmall = 'mediumSmall',
    medium = 'medium',
    large = 'large',
}
