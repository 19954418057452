import { memo } from "react";
import {
  OptionWrap,
  IconWrapper,
  Icon,
  OptionDescription,
  OperationTitle,
  OperationInstruction,
} from "../createOperaionsMenu.stlyles";
interface OptionProps {
  operationTitle: string;
  operationInstruction: string;
  iconUrl: string;
  onClick(): void;
}

const OperationOption = ({
  operationTitle,
  operationInstruction,
  iconUrl,
  onClick,
}: OptionProps) => {
  return (
    <OptionWrap onClick={onClick}>
      <IconWrapper>
        <Icon src={iconUrl} />
      </IconWrapper>
      <OptionDescription>
        <OperationTitle>{operationTitle}</OperationTitle>
        <OperationInstruction>{operationInstruction}</OperationInstruction>
      </OptionDescription>
    </OptionWrap>
  );
};

export default memo(OperationOption);
