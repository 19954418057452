import {
  createAsyncThunk,
  createReducer,
  createSelector,
  createAction,
} from "@reduxjs/toolkit";

import {
  userInviteProject,
  projectsDelete,
  projectsGetListCategories,
  projectsSave,
  projectsUpdate,
  projectsGetListParticipants,
  makeProjectVisited,
} from "./projectThunks";

import { getJson, postJson } from "client/common/api";
import {
  Currency,
  ProjectsParticipantsRole,
  SnackbarMessageType,
  ExploitationType,
  Exploitation,
} from "../enums";
import { snackbarShow } from "../snackbar/snackbar";
import { StoreState } from "../types";
import {
  ParticipantAggregateModelElement,
  ParticipantModelElement,
  ProjectModelElement,
  ProjectModelListElement,
  ProjectsModel,
  InvitedUser,
  NewProjectCurrency,
} from "./types";

const initialState: ProjectsModel = {
  participants: [],
  categories: [],
  subCategories: [],
  projects: [],
  projectsOperations: [],
  project: {
    currency: { id: "RUB", picture: "", type: 0 },
    id: "",
    userId: "",
    currentUserRole: ProjectsParticipantsRole.USER,
    title: "",
    projectCurrency: Currency.RUB,
    accountingMethod: 10,
    exploitation: Exploitation.NONE,
    description: "",
    photo: "",
    budget: 0,
    category: {
      id: "",
      title: "",
      icon: "",
      parent: {
        id: "",
        title: "",
        icon: "",
      },
    },
    participants: [],
  },
  invitedParticipants: [],
  loading: false,
};

export const projectsGetListProjects = createAsyncThunk(
  "projectsGetListProjects",
  async (search: string = "", { rejectWithValue, dispatch }) => {
    try {
      const response = await postJson<{ list: ProjectModelListElement[] }>(
        "projects-list",
        { search }
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const projectsGetListProjectsOperations = createAsyncThunk(
  "projectsGetListProjectsOperations",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await postJson<{
        list: Omit<ProjectModelListElement, "lastOperation">[];
      }>("projects-list-operations");
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const projectsGetProject = createAsyncThunk(
  "projectsGetProject",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getJson<ProjectModelElement>(`projects-get/${id}`);
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const clearProject = createAction("clearProject");

export const addSubcategory = createAction<Array<any>>("addSubcategory");

export const setInvitedParticipants = createAction<InvitedUser>(
  "setInvitedParticipants"
);

export const resetInvitedParticipants = createAction(
  "resetInvitedParticipants"
);
// category
export const allProjectModel = createSelector(
  (state: StoreState) => state.projects,
  (projects) => projects
);

export const projectsModel = createSelector(
  (state: StoreState) => state.projects.projects,
  (projects) => projects
);

export const projectsCategoriesModel = createSelector(
  (state: StoreState) => state.projects.categories,
  (categories) => categories
);

export const projectsCategoriesInfoModel = (categoryId: string) =>
  createSelector(
    (state: StoreState) => state.projects.categories,
    (categories) => categories.find(({ id }) => id === categoryId)
  );

export const projectsSubcategoryModel = (categoryId: string) =>
  createSelector(
    (state: StoreState) => state.projects.categories,
    (categories) =>
      categories
        .flatMap(({ subcategories }) => subcategories)
        .find(({ id }) => id === categoryId)
  );

// participants
export const projectsParticipantsModel = createSelector(
  (state: StoreState) => state.projects.participants,
  (participants) => participants
);

export const projectsParticipantsAggregateModel = createSelector(
  (state: StoreState) => state.user,
  (state: StoreState) => state.projects.participants,
  (creater, participants) =>
    participants.reduce<{
      [key: string]: ParticipantAggregateModelElement | undefined;
    }>(
      (aggregate, user) => {
        aggregate[user.userId] = user;

        return aggregate;
      },
      {
        [creater.id]: {
          userId: creater.id,
          firstName: creater.firstName,
          lastName: creater.lastName,
          phone: creater.phone,
          photo: creater.photo,
          confirmed: creater.confirmed,
        },
      }
    )
);

// project
export const projectsListModel = createSelector(
  (state: StoreState) => state.projects.projects,
  (projects) => projects
);
// project
export const projectsListOperationsModel = createSelector(
  (state: StoreState) =>
    state.projects.projectsOperations.filter((el) => {
      return el.exploitation !== ExploitationType.NONE;
    }),
  (projectsOperations) => projectsOperations
);

export const projectsElementModel = createSelector(
  (state: StoreState) => state.projects.project,
  (project) => project
);

export const projectCurrencyModel = createSelector(
  (state: StoreState) => state.projects.project.projectCurrency,
  (project) => project
);

// loading
export const projectsLoadingModel = createSelector(
  (state: StoreState) => state.projects.loading,
  (loading) => loading
);

export const projectsSubcategories = createSelector(
  (state: StoreState) => state.projects.subCategories,
  (subCategories) => subCategories
);

export const getInvitedUsers = createSelector(
  (state: StoreState) => state.projects.invitedParticipants,
  (invitedParticipants) => invitedParticipants
);

export default createReducer(initialState, (builder) => {
  builder
    .addCase(clearProject, (state) => {
      state.project = initialState.project;
    })
    .addCase(projectsGetListCategories.pending, (state) => {
      state.loading = true;
    })
    .addCase(projectsGetListCategories.rejected, (state) => {
      state.loading = false;
    })
    .addCase(projectsGetListCategories.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.categories = payload.list;
    })

    .addCase(projectsGetListParticipants.pending, (state) => {
      state.loading = true;
    })
    .addCase(projectsGetListParticipants.rejected, (state) => {
      state.loading = false;
    })
    .addCase(projectsGetListParticipants.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.participants = payload.list;
    })

    .addCase(projectsGetListProjects.pending, (state) => {
      state.loading = true;
    })
    .addCase(projectsGetListProjects.rejected, (state) => {
      state.loading = false;
    })
    .addCase(projectsGetListProjects.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.projects = payload.list;
    })

    .addCase(projectsGetListProjectsOperations.pending, (state) => {
      state.loading = true;
    })
    .addCase(projectsGetListProjectsOperations.rejected, (state) => {
      state.loading = false;
    })
    .addCase(
      projectsGetListProjectsOperations.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.projectsOperations = payload.list;
      }
    )
    .addCase(projectsGetProject.pending, (state) => {
      state.loading = true;
    })
    .addCase(projectsGetProject.rejected, (state) => {
      state.loading = false;
    })
    .addCase(projectsGetProject.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.project = payload;
    })

    .addCase(projectsSave.pending, (state) => {
      state.loading = true;
    })
    .addCase(projectsSave.rejected, (state) => {
      state.loading = false;
    })
    .addCase(projectsSave.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.project = payload;
    })

    .addCase(projectsUpdate.pending, (state) => {
      state.loading = true;
    })
    .addCase(projectsUpdate.rejected, (state) => {
      state.loading = false;
    })
    .addCase(projectsUpdate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.project = payload;
    })
    .addCase(projectsDelete.pending, (state) => {
      state.loading = true;
    })
    .addCase(projectsDelete.rejected, (state) => {
      state.loading = false;
    })
    .addCase(projectsDelete.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(userInviteProject.pending, (state) => {
      state.loading = true;
    })
    .addCase(userInviteProject.rejected, (state) => {
      state.loading = false;
    })
    .addCase(userInviteProject.fulfilled, (state, { payload }) => {
      const participants = [...state.participants];
      const isParticipantIncluded = participants.filter(
        (participant) => participant.userId === payload.userId
      );
      if (!isParticipantIncluded.length) participants.push(payload);
      state.participants = participants;
      state.loading = false;
    })
    .addCase(addSubcategory, (state, { payload }) => {
      state.subCategories = payload;
    })
    .addCase(setInvitedParticipants, (state, { payload }) => {
      state.invitedParticipants.push(payload);
    })
    .addCase(resetInvitedParticipants, (state, { payload }) => {
      state.invitedParticipants = [];
    })
    .addCase(makeProjectVisited.fulfilled, (state, { payload }) => {
      const rightProject = state.projects.find(
        (p) => p.id === payload.project.id
      );
      const participants = rightProject!.participants.map((prtcp) => {
        if (prtcp.user.userId === payload.project.userId) {
          prtcp.newProj = false;
        }
        return prtcp;
      });
      state.projects = state.projects.map((pr) => {
        if (pr.id === payload.project.id) {
          pr.participants = participants;
        }
        return pr;
      });
    })
    .addCase(makeProjectVisited.rejected, (state) => {
      state.loading = false;
    });
});
