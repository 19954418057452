export interface LogoUISize {
    height: number;
    width: number;
    src: string;
}

export enum LogoUISizes {
    small = 'small',
    large = 'large',
}
