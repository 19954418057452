import { memo, useState } from "react";
import { HeaderWrapper, HeaderTitle, CancelText } from "./header.styles";
import SearchButton from "./ProjectSearchButton";

export const Header = memo(() => {
  return (
    <HeaderWrapper>
      <SearchButton />
    </HeaderWrapper>
  );
});
