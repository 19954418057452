import styled, { css } from "styled-components";
import { colors } from "../common/colors";
import { TypographyUI } from "../typography/typography.component";

export const KeyboardUIStyle = styled.div`
  width: 272px;
  height: 438px;
  margin: 0 auto;
`;

export const KeyboardUIPassworWrapperdStyle = styled.div`
  margin: 0 auto;
  margin-bottom: 24px;
`;

export const KeyboardUIPasswordStyle = styled.div`
  width: 80px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;

export const KeyboardUIPasswordPointWrapperStyle = styled.div`
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const KeyboardUIPasswordPointStyle = styled(TypographyUI)<{
  isSecurity?: boolean;
  isActive?: boolean;
}>`
  ${({ isSecurity, isActive }) =>
    (isSecurity || !isActive) &&
    css`
      width: 10px;
      height: 10px;
      background: ${isActive ? colors.CaribbeanGreen : colors.Silver};
      border-radius: 50%;
      opacity: ${isActive ? 1 : 0.2};
    `}
  color: ${colors.textBlack};
  font-family: WIX-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
`;

export const KeyboardUIRowsStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const KeyboardUIRowStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 16px;

  &:last-of-type {
    justify-content: flex-end;
    margin-bottom: 0;
  }
`;

export const KeyboardUIButtonStyle = styled(TypographyUI)`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9e9e9;
  color: ${colors.textBlack};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  transition: opacity 0.4s ease-in-out;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;

  &:disabled {
    opacity: 0.4;
  }
`;

export const KeyboardUIImgStyle = styled.img`
  width: 24px;
  height: 24px;
`;
