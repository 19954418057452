import styled, { css } from 'styled-components';
import { TypographyUIElementStyle } from './typography.types';

export const TypographyUIStyle = styled.span<{
    elementStyle: TypographyUIElementStyle;
    textColor: string;
    whiteSpace: string;
}>`
    ${({
    whiteSpace,
    textColor,
    elementStyle: {
        fontWeight,
        fontSize,
        lineHeight,
    } }) => css`
        margin: 0;
        padding: 0;
        font-family: 'Roboto Condensed', sans-serif;
        font-style: normal;
        font-weight: ${fontWeight};
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;
        color: ${textColor};
        letter-spacing: 0.035em;
        white-space: ${whiteSpace};
    `}
`;
