import { Currency, OperationType } from "client/model/enums";
import { mapCurrency, mapOperationsTypeSymbol } from "client/model/transformer";
import { RefObject, useCallback, useEffect, useState } from "react";
import { API_URL } from "client/common/constants/constants";
import { othersAddProjectType } from "client/ui/icons";
import momentjs from "moment";
import "moment/locale/ru";
import {
  OperationModelElement,
  TabObject,
  projectOperationsTabbedDetails,
  RussianMomentConfig,
} from "client/model/operations/types";
import { useTranslation } from "react-i18next";
import { PagePath } from "client/common/pages/page-path";
import { PageName } from "client/common/pages/page-name";
import { isNamedExports, StringLiteral } from "typescript";
import { StringDecoder } from "string_decoder";
import { currencyObj } from "client/pages/projects/operations/operations.component commons/AccountsField";

export const useTranslatedMonths = (): RussianMomentConfig => {
  const { t } = useTranslation();
  const months: string[] = t("DAYPICKER.MONTHS", { returnObjects: true });
  return {
    months,
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "MMMM YYYY",
      LLL: "D MMMM YYYY",
      LLLL: "dddd D MMMM YYYY HH:mm",
    },
  };
};

export const getPhone = (phone: string | null | undefined): string =>
  !!phone ? phone.replace(/\D+/g, "") : "";

export const getPhoneWithMask = (phone: string | null | undefined): string => {
  return getPhone(phone).replace(
    /(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/,
    `+$1 $2 $3 $4-$5`
  );
};

const normalizeWithDecimals = (value: string): string => {
  const mainPart = normalizeThousands(value.split(".")[0]);
  let decimalPart = value.split(".")[1];
  if (decimalPart.length < 2) decimalPart += "0";
  return `${mainPart}.${decimalPart}`;
};

export const getCurrencyFormat = (
  currency: Currency = Currency.RUB,
  value: number = 0
): string => {
  const valueWithSeperatedZeros = value.toString().includes(".")
    ? normalizeWithDecimals(value.toString())
    : normalizeThousands(value.toString());
  return `${valueWithSeperatedZeros} ${currencyObj[currency]}`;
};

export const getCurrencyFormatWithOperationType = (
  currency: Currency,
  operationType: OperationType,
  value: number
): string => {
  const symbolStr = mapOperationsTypeSymbol[operationType];
  return `${symbolStr} ${getCurrencyFormat(currency, value)}`;
};

export const getExploitationFormat = (
  exp: number | string,
  value: number
): string => {
  const expValue = ["Часы/Мин", "Часы/Доли", "КМ"];
  const index = Number(exp) - 1;
  if (index === 2) {
    value = value / 1000;
    return `${value} ${expValue[index]}`;
  }
  if (index === 0) {
    const minutesInSeconds = value % 3600;
    const hours = (value - minutesInSeconds) / 3600;
    const minutes = minutesInSeconds / 60;
    return `${hours}Ч ${minutes}М`;
  }
  if (index === 1) {
    value = value / 3600;
    return `${value}ч`;
  }
  return "значения нет";
};

export const useTimer = (
  seconds: number = 30
): {
  timerTick: number;
  timerDown: boolean;
  onHandleTimerStart(): void;
} => {
  const [timer, setTimer] = useState({
    timerTick: seconds,
    timerDown: false,
  });

  const onHandleTimerStart = useCallback(() => {
    setTimer({ timerTick: seconds, timerDown: false });
  }, [setTimer, seconds]);

  useEffect(() => {
    if (!timer.timerDown) {
      const test = setInterval(
        () =>
          setTimer(({ timerTick, ...prevState }) => {
            const nextTick = timerTick - 1;

            return {
              ...prevState,
              timerDown: nextTick === 0,
              timerTick: nextTick,
            };
          }),
        1000
      );

      return () => clearInterval(test);
    }
  }, [timer.timerDown, setTimer]);

  return {
    timerTick: timer.timerTick,
    timerDown: timer.timerDown,
    onHandleTimerStart,
  };
};

export const getUserName = (
  firstName = "",
  lastName = "",
  phone = ""
): string => {
  const newFirstName = firstName ?? "";
  const newLastName = lastName?.length ? `${lastName.charAt(0)}.` : "";

  return newFirstName && newLastName
    ? `${newFirstName} ${newLastName}`
    : getPhoneWithMask(phone);
};

export const getUserNameAndInitial = (
  firstName = "",
  lastName = "",
  phone = ""
): string => {
  const newFirstName = firstName ?? "";
  const newLastName = lastName?.length ? `${lastName.charAt(0)}.` : "";

  return newFirstName && newLastName
    ? `${newFirstName} ${newLastName.slice(0, 1)}.`
    : getPhoneWithMask(phone);
};

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void
) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const imgagePath = (iconPath: string | undefined): string =>
  iconPath ? `${API_URL}${iconPath}` : othersAddProjectType;

export const cutText = (text = "", limit = 25) => {
  text = text.trim();
  if (text.length <= limit) return text;
  text = text.slice(0, limit);

  return `${text.trim()}...`;
};

interface momentjsFunc {
  (inp?: momentjs.MomentInput, strict?: boolean | undefined): momentjs.Moment;
}

export const getTabbedOperationLists = (
  dateChanger: momentjsFunc,
  arr: OperationModelElement[]
): projectOperationsTabbedDetails[] => {
  const changeDateFormat = (a: string): string => {
    const day = a.substring(0, 2);
    const month = a.substring(3, 5);
    const rest = a.substring(5.9);
    return `${month}.${day}${rest}`;
  };
  const operationsListWithDate: OperationModelElement[] = arr.map((obj) => ({
    ...obj,
    date: dateChanger(changeDateFormat(obj.createAt)).format("LL"),
  }));
  const resultObj: TabObject = {};
  for (let obj of operationsListWithDate) {
    if (obj.date) {
      const date: string = obj.date.toString();
      if (!resultObj[date]) resultObj[date] = [obj];
      else resultObj[date].push(obj);
    }
  }

  return Object.entries(resultObj).map((el) => {
    let type: OperationType = 0;
    const total: number = el[1].reduce((prev, curr) => {
      let cv: number;
      if (curr.operationType === 0) {
        cv = curr.volume * -1;
      } else {
        cv = curr.volume;
      }
      let acc: number = prev + cv;
      type = acc > 0 ? 1 : 0;
      return acc;
    }, 0);
    const finalObj: projectOperationsTabbedDetails = {
      date: el[0],
      arraOfOperations: [...el[1]],
      currency: el[1][0]?.makerAccount.accountCurrency,
      total: Math.abs(total) / 100,
      operationType: type,
    };
    return finalObj;
  });
};

export const prevPathIfComingFromOperations = new RegExp(
  `${PagePath[PageName.PROJECTS]}/[a-zA-Z0-9-]+/${
    PagePath[PageName.PROJECTS_OPERATION]
  }`
);

export const seperateZeros = (num: number | string): string => {
  const resultArr = num.toString().split("");
  const indecesWhereToSplit: number[] = [];

  for (let i = resultArr.length; i > 3; i = i - 3) {
    let j = i - 3;
    indecesWhereToSplit.push(j);
  }
  indecesWhereToSplit.forEach((index) => {
    resultArr.splice(index, 0, " ");
  });

  return resultArr.join("");
};

export const normalizeThousands = (value: string): string => {
  let helperString = "";
  const onlyNums = value.replace(/[a-zA-Z|\s|,]/gm, "");

  if (onlyNums.length > 9)
    helperString = `${onlyNums.slice(
      onlyNums.length - 9,
      onlyNums.length - 6
    )} ${onlyNums.slice(
      onlyNums.length - 6,
      onlyNums.length - 3
    )} ${onlyNums.slice(onlyNums.length - 3, onlyNums.length)}`;

  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length > 3 && onlyNums.length <= 6)
    return `${onlyNums.slice(0, onlyNums.length - 3)} ${onlyNums.slice(
      onlyNums.length - 3,
      onlyNums.length
    )}`;
  if (onlyNums.length > 6 && onlyNums.length <= 9)
    return `${onlyNums.slice(0, onlyNums.length - 6)} ${onlyNums.slice(
      onlyNums.length - 6,
      onlyNums.length - 3
    )} ${onlyNums.slice(onlyNums.length - 3, onlyNums.length)}`;
  if (onlyNums.length > 9 && onlyNums.length <= 12)
    return `${onlyNums.slice(0, onlyNums.length - 9)} ${helperString}`;
  if (onlyNums.length > 12 && onlyNums.length <= 15)
    return `${onlyNums.slice(0, onlyNums.length - 12)} ${onlyNums.slice(
      onlyNums.length - 12,
      onlyNums.length - 9
    )} ${helperString}`;
  if (onlyNums.length > 15 && onlyNums.length <= 18)
    return `${onlyNums.slice(0, onlyNums.length - 15)}  ${onlyNums.slice(
      onlyNums.length - 15,
      onlyNums.length - 12
    )} ${onlyNums.slice(
      onlyNums.length - 12,
      onlyNums.length - 9
    )} ${helperString}`;
  else return value;
};

export const normalizeThousandsWithDecimal = (
  value: string | number
): string => {
  let stringifiedValue: string;
  if (typeof value === "number") stringifiedValue = value.toString();
  else stringifiedValue = value;
  stringifiedValue = stringifiedValue.replace(/[a-zA-Z|\s|,]/gm, "");
  if (stringifiedValue.includes(".")) {
    const arrayedValue = stringifiedValue.split(".");
    const wholeNumPart = normalizeThousands(arrayedValue[0]);
    const decimalPart = arrayedValue[0].slice(0, 2);

    return `${wholeNumPart}.${decimalPart}`;
  }
  return normalizeThousands(stringifiedValue);
};

export const normolizeSpaces = (value: string) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\.|^\d]/gm, "");
  return onlyNums;
};

export const separateZerosinActiveInputs = (
  isInputFocused: boolean,
  inputVal: number | string,
  stateChangeer:
    | React.Dispatch<React.SetStateAction<string | number>>
    | React.Dispatch<React.SetStateAction<string | string>>,
  condition2: boolean = true,
  functionSeperatingZeros: Function = seperateZeros
): void => {
  if (!isInputFocused && condition2 && !inputVal.toString().includes(" "))
    stateChangeer(functionSeperatingZeros(inputVal));
};

export const getRidOffSpaces = (value: string): number => {
  return Number(
    value
      .split("")
      .filter((char) => char !== " ")
      .join("")
  );
};
