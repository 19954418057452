import { FC } from "react";
import {
  DivStyledLikeLoader,
  LoaderContainer,
  LoaderProgressBar,
  ProgressBarFill,
} from "./loader.styles";
import {
  AuthCheckPhoneLogoImgStyle,
  AuthCheckPhoneLogoStyle,
} from "client/pages/auth/check-phone/check-phone.styles";
import { LogoUI } from "../logo/logo.component";
import { StyledAppTitle } from "client/pages/auth/check-phone/check-phone.styles";
import { LogoUISizes } from "../logo/logo.types";

export const Loader: FC = () => (
  <LoaderContainer>
    <AuthCheckPhoneLogoStyle>
      <AuthCheckPhoneLogoImgStyle>
        <LogoUI logoSize={LogoUISizes.small} />
      </AuthCheckPhoneLogoImgStyle>
      <StyledAppTitle>Total Cash</StyledAppTitle>
    </AuthCheckPhoneLogoStyle>
    {/* <DivStyledLikeLoader /> */}
    <LoaderProgressBar>
      <ProgressBarFill />
    </LoaderProgressBar>
  </LoaderContainer>
);
