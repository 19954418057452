import { BaseCurrency } from "client/model/operations/types";

export const convertCurrObj = (baseCurr: BaseCurrency) => ({
  title: baseCurr.currency.title,
  id: baseCurr.id.toString(),
  active: baseCurr.active,
  icon: baseCurr.currency.picture,
  type: baseCurr.currency.type,
  rate: baseCurr.currency.rate,
  checked: baseCurr.active,
});
