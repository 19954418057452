import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
  PayloadAction,
} from "@reduxjs/toolkit";
import { postJson, putFormData, putJson, setToken } from "client/common/api";
import { UserRole, SnackbarMessageType } from "../enums";
import { snackbarShow } from "../snackbar/snackbar";
import { StoreState } from "../types";
import { UserModelElement, UserModel, UserAuthModel } from "./types";

type UserEdit = Pick<
  UserModelElement,
  "firstName" | "lastName" | "phone" | "email"
>;
type UserEditPhoto = Pick<UserModelElement, "photo">;

// const initialState: UserModel = {
//   confirmed: true,
//   email: '',
//   firstName: 'Alex',
//   id: 'ac495af7-7c73-4abc-a5d7-40ebf1e3604c',
//   lastName: 'Girin',
//   phone: '79279164187',
//   photo: '',
//   userRole: UserRole.USER,
//   isAuthorized: true,
//   loading: false,
// };

const initialState: UserModel = {
  id: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  photo: "",
  userRole: UserRole.GUEST,
  confirmed: false,
  isAuthorized: false,
  loading: false,
};

export const userSavePhone = createAction<{ phone: string }>("userSavePhone");

export const userLogOut = createAction("userLogOut");

export const userCheckPhone = async (phone: string) => {
  // console.log("from usercheckphone", phone);
  const res = postJson<object>("user-check-phone", { phone });
  return res;
};

export const userCheckPhoneCode = async (phone: string, code: string) =>
  postJson<object>("user-check-phone-code", { phone, code });

export const userRecovery = async (phone: string) =>
  postJson<object>("user-recovery-phone", { phone });

export const userRecoveryCheckCode = async (phone: string, code: string) =>
  postJson<object>("user-recovery-phone-code", { phone, code });

export const userResetPassword = () => postJson("user-reset-password");

export const userResetPasswordCheckCode = async (code: string) =>
  postJson<object>("user-reset-password-code", { code });

// *************** new routes ************************

export const updatePhone = async (phone: string) =>
  postJson<object>("user-updatephone", { phone });

export const checkSmsCode = async (phone: string, code: string) =>
  postJson<object>("user-updatephone-ph", { phone, code });

// *************** new routes ************************

export const userSetPassword = createAsyncThunk(
  "userSetPassword",
  async (
    { phone, password }: { phone: string; password: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postJson<UserAuthModel>("user-registration", {
        phone,
        password,
      });
      setToken(response.token);
      dispatch(
        snackbarShow({
          message: "Добро пожаловать",
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      dispatch(
        snackbarShow({
          message: "Неизвестная ошибка, попробуйте позже",
          messageType: SnackbarMessageType.WRONG,
        })
      );
      return rejectWithValue(error.data);
    }
  }
);

export const userLogin = createAsyncThunk(
  "userLogin",
  async (
    { phone, password }: { phone: string; password: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postJson<UserAuthModel & { token: string }>(
        "user-auth",
        { phone, password }
      );
      setToken(response.token);
      dispatch(
        snackbarShow({
          message: "Добро пожаловать",
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Пин-код введён неверно",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const userSetRecoveryPassword = createAsyncThunk(
  "userSetRecoveryPassword",
  async (
    { phone, password }: { phone: string; password: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postJson<UserAuthModel>("user-recovery", {
        phone,
        password,
      });
      setToken(response.token);
      dispatch(
        snackbarShow({
          message: "Доступ восстановлен",
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

type UserOnly = Omit<UserEdit, "phone">;

export const userEdit = createAsyncThunk(
  "userEdit",
  async (
    { data, isPhoneToo }: { data: UserEdit; isPhoneToo: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const _data: UserOnly = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      };
      const phone = data.phone;
      const response = await putJson<UserOnly>("user-update", _data);
      let response2 = { message: "" };
      if (isPhoneToo) {
        response2 = await postJson<{ message: string }>("user-updatephone", {
          phone,
        });
      }
      // console.log("response2 with phone", response2);

      dispatch(
        snackbarShow({
          message: "Профиль изменен",
          messageType: SnackbarMessageType.SUCCESS,
        })
      );

      return [response, response2];
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const userEditPhoto = createAsyncThunk(
  "userEditPhoto",
  async (
    { photo, isPhoneTоо }: { photo: FileList; isPhoneTоо: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const data = new FormData();
      if (photo instanceof FileList && !!photo[0]) {
        data.append("photo", photo[0], photo[0].name);
      }
      const response = await putFormData<UserEditPhoto>(
        "user-update-photo",
        data
      );
      !isPhoneTоо &&
        dispatch(
          snackbarShow({
            message: "Фото измененo",
            messageType: SnackbarMessageType.SUCCESS,
          })
        );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else if (error.status === 413) {
        dispatch(
          snackbarShow({
            message: "Файл слишком большой максимальный размер 1мб",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const userUpdatePassword = createAsyncThunk(
  "userUpdatePassword",
  async (data: { password: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await putJson<null>("user-update-password", data);
      dispatch(
        snackbarShow({
          message: "Пароль изменен",
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const userModel = createSelector(
  (state: StoreState) => state.user,
  (user) => user
);

export const userPhoneModel = createSelector(
  (state: StoreState) => state.user.phone,
  (phone) => phone
);

export const snackbarModel = createSelector(
  (state: StoreState) => state.snackbar,
  (snackbar) => snackbar
);

export const userPhotoModel = createSelector(
  (state: StoreState) => state.user.photo,
  (photo) => photo
);

export const userAuthorizationModel = createSelector(
  (state: StoreState) => state.user.isAuthorized,
  (isAuthorized) => isAuthorized
);

export const userLoadingModel = createSelector(
  (state: StoreState) => state.user.loading,
  (loading) => loading
);

const setAuth = (
  state: UserModel,
  { payload }: PayloadAction<UserAuthModel>
) => {
  state.isAuthorized = true;
  state.id = payload.id;
  state.firstName = payload.firstName;
  state.lastName = payload.lastName;
  state.phone = payload.phone;
  state.email = payload.email;
  state.photo = payload.photo;
  state.userRole = payload.userRole;
  state.confirmed = payload.confirmed;
  state.loading = false;
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(userSavePhone, (state, { payload }) => {
      state.phone = payload.phone;
    })

    .addCase(userSetPassword.pending, (state) => {
      state.isAuthorized = false;
      state.loading = true;
    })
    .addCase(userSetPassword.rejected, (state) => {
      state.isAuthorized = false;
      state.loading = false;
    })
    .addCase(userSetPassword.fulfilled, setAuth)

    .addCase(userLogin.pending, (state) => {
      state.isAuthorized = false;
      state.loading = true;
    })
    .addCase(userLogin.rejected, (state) => {
      state.isAuthorized = false;
      state.loading = false;
    })
    .addCase(userLogin.fulfilled, setAuth)

    .addCase(userSetRecoveryPassword.pending, (state) => {
      state.isAuthorized = false;
      state.loading = true;
    })
    .addCase(userSetRecoveryPassword.rejected, (state) => {
      state.isAuthorized = false;
      state.loading = false;
    })
    .addCase(userSetRecoveryPassword.fulfilled, setAuth)

    .addCase(userEdit.pending, (state) => {
      state.loading = true;
    })
    .addCase(userEdit.rejected, (state) => {
      state.loading = false;
    })
    .addCase(userEdit.fulfilled, (state, { payload }) => {
      function instanceOfUserOnly(object: any): object is UserOnly {
        return "firstName" in object;
      }
      if (instanceOfUserOnly(payload[0])) {
        state.firstName = payload[0].firstName;
        state.lastName = payload[0].lastName;
        state.email = payload[0].email;
      }
      state.loading = false;
    })

    .addCase(userEditPhoto.pending, (state) => {
      state.loading = true;
    })
    .addCase(userEditPhoto.rejected, (state) => {
      state.loading = false;
    })
    .addCase(userEditPhoto.fulfilled, (state, { payload }) => {
      state.photo = payload.photo;
      state.loading = false;
    })

    .addCase(userUpdatePassword.pending, (state) => {
      state.loading = true;
    })
    .addCase(userUpdatePassword.rejected, (state) => {
      state.loading = false;
    })
    .addCase(userUpdatePassword.fulfilled, (state) => {
      state.loading = false;
    })

    .addCase(userLogOut, () => initialState);
});
