import { memo, useCallback, useMemo, useRef, useState } from "react";
import { useOutsideClick } from "../../common/utils";
import { TypographyUI } from "../typography/typography.component";
import { TypographyUITextStyles } from "../typography/typography.types";
import {
  SelectUISelectElementsStyle,
  SelectUISelectElementStyle,
  SelectUISelectElementTitleStyle,
  SelectUISelectStyle,
  SelectUISelectWrapperElementsStyle,
  SelectUIWrapperStyle,
  CustomSelect,
  PopUpSelectOptions,
  StyledOption,
} from "./select.styles";
import styled from "styled-components";
import { colors } from "client/ui";

export interface SelectUIOptionsProps {
  value: string | number;
  title: string;
  icon?: string;
  iconBg?: string;
}

export interface SelectUIComponentProps {
  selectOptions: SelectUIOptionsProps[];
  value: string | number;
  onChange(value: string | number): void;
  placeholder?: string | null;
  disabled?: boolean;
  defaultSelect?: string;
  defaultPlaceholder?: string;
  showLabel?: boolean;
}

export const defaultProps = {
  selectOptions: [],
};

const SelectUIComponent = ({
  selectOptions = defaultProps.selectOptions,
  value: originValue,
  onChange,
  placeholder,
  disabled,
}: SelectUIComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const currentSelect = useMemo(
    () =>
      selectOptions.find(
        ({ value }) => value?.toString() === originValue.toString()
      ),
    [selectOptions, originValue]
  );

  const handleSelect = useCallback(
    (value: any) => () => {
      setIsOpen(!isOpen);
      onChange(value);
    },
    [isOpen, setIsOpen, onChange]
  );

  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const itemsSelectRender = useMemo(() => {
    return selectOptions.map(({ title, value }, index) => {
      const isActive =
        selectOptions[index]?.value?.toString() === originValue.toString();
      return (
        <SelectUISelectElementStyle key={index} onClick={handleSelect(value)}>
          <SelectUISelectElementTitleStyle
            textStyle={TypographyUITextStyles.subtitle1}
            isActive={isActive}
          >
            {title}
          </SelectUISelectElementTitleStyle>
        </SelectUISelectElementStyle>
      );
    });
  }, [selectOptions, handleSelect, originValue]);

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <SelectUIWrapperStyle ref={ref} isDisabled={disabled}>
      <SelectUISelectStyle onClick={handleOpen}>
        <TypographyUI textStyle={TypographyUITextStyles.subtitle1}>
          {currentSelect?.title || placeholder}
        </TypographyUI>
      </SelectUISelectStyle>
      {isOpen && (
        <SelectUISelectWrapperElementsStyle>
          <SelectUISelectElementsStyle>
            {itemsSelectRender}
          </SelectUISelectElementsStyle>
        </SelectUISelectWrapperElementsStyle>
      )}
    </SelectUIWrapperStyle>
  );
};

/////////////////////////////////////////////////////////////////////////////////////////////////

const InputLabel = styled(TypographyUI)`
  display: flex;
  justify-content: flex-start;
  // margin-left:2px;
  color: ${colors.StormGrey};
`;

const InputLabelContainer = styled(TypographyUI)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SelectUIComponentWithLable = ({
  selectOptions = defaultProps.selectOptions,
  value: originValue,
  onChange,
  defaultPlaceholder,
  disabled,
  defaultSelect,
  showLabel = false,
}: SelectUIComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const currentSelect = useMemo(
    () =>
      selectOptions.find(
        ({ value }) => value?.toString() === originValue.toString()
      ),
    [selectOptions, originValue]
  );

  const handleSelect = useCallback(
    (value: any) => () => {
      setIsOpen(!isOpen);
      onChange(value);
    },
    [isOpen, setIsOpen, onChange]
  );

  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const itemsSelectRender = useMemo(() => {
    return selectOptions.map(({ title, value }, index) => {
      const isActive =
        selectOptions[index].value?.toString() === originValue.toString();
      return (
        <SelectUISelectElementStyle key={index} onClick={handleSelect(value)}>
          <SelectUISelectElementTitleStyle
            textStyle={TypographyUITextStyles.subtitle1}
            isActive={isActive}
          >
            {title}
          </SelectUISelectElementTitleStyle>
        </SelectUISelectElementStyle>
      );
    });
  }, [selectOptions, handleSelect, originValue]);

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <SelectUIWrapperStyle
      style={{ height: "60px" }}
      ref={ref}
      isDisabled={disabled}
    >
      <SelectUISelectStyle style={{ height: "100%" }} onClick={handleOpen}>
        <InputLabelContainer>
          {showLabel && (
            <InputLabel textStyle={TypographyUITextStyles.button14}>
              {defaultPlaceholder}
            </InputLabel>
          )}
          <TypographyUI
            style={{ marginTop: "4px" }}
            textStyle={TypographyUITextStyles.subtitle1}
          >
            {currentSelect?.title}
          </TypographyUI>
        </InputLabelContainer>
      </SelectUISelectStyle>

      {isOpen && (
        <SelectUISelectWrapperElementsStyle>
          <SelectUISelectElementsStyle>
            {itemsSelectRender}
          </SelectUISelectElementsStyle>
        </SelectUISelectWrapperElementsStyle>
      )}
    </SelectUIWrapperStyle>
  );
};

export const SelectUI = memo(SelectUIComponent);

export const SelectUIWithLable = memo(SelectUIComponentWithLable);
