import { CSSProperties } from "react";
import styled from "styled-components";
import {
  CustomSelectWrapper,
  SelectTitleIconWrapper,
  ImgWrapper,
  SelectIcon,
  ChevronDownWrapper,
} from "./select.styles";
import { chevronDown } from "../icons";
import { colors } from "../common/colors";

export const StyledAccountsDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`;
export const StyledAccountsText = styled.span`
  height: 24px;
  font-family: "WIX-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${colors.textBlack};
`;

export const CustomSelectAccounts = ({
  title,
  balance,
  icon,
  onClick,
  background,
  style,
  isDefault,
}: {
  title: string;
  balance: string;
  icon?: string;
  onClick: () => void;
  background: string;
  style?: CSSProperties;
  isDefault: boolean;
}) => {
  return (
    <CustomSelectWrapper onClick={onClick} style={style}>
      <SelectTitleIconWrapper>
        <ImgWrapper background={background}>
          <SelectIcon src={icon} />
        </ImgWrapper>
        <StyledAccountsDescription>
          <StyledAccountsText style={{ paddingTop: "3px" }}>
            {title}
          </StyledAccountsText>
          {!isDefault && (
            <StyledAccountsText style={{ paddingBottom: "3px" }}>
              {balance}
            </StyledAccountsText>
          )}
        </StyledAccountsDescription>
      </SelectTitleIconWrapper>
      <ChevronDownWrapper src={chevronDown} alt={"chevron down icon"} />
    </CustomSelectWrapper>
  );
};

// export const StyledOption = ({
//   icon,
//   title,
//   isActive,
//   onClick,
//   height = "72px",
//   background = colors.BackgroundGray,
//   isIcon = true,
// }: {
//   icon?: string;
//   title: string;
//   isActive: boolean;
//   onClick: () => void;
//   height?: string;
//   background: string;
//   isIcon?: boolean;
// }) => {
//   return (
//     <CustomSelectWrapper onClick={onClick}>
//       <SelectTitleIconWrapper>
//         {isIcon && (
//           <ImgWrapper background={background}>
//             <SelectIcon src={icon} />
//           </ImgWrapper>
//         )}
//         <StyledSelectText>{title}</StyledSelectText>
//       </SelectTitleIconWrapper>
//       <StyledOptionRadio isActive={isActive}>
//         {isActive && <RadioInnerCircle />}
//       </StyledOptionRadio>
//     </CustomSelectWrapper>
//   );
// };
