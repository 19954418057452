import {
  memo,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from "react";
import {
  SearchButtonWrapper,
  ButtonIcon,
  CancelText,
  HeaderButtonsNoSearch,
  HeaderTitle,
} from "./header.styles";
import { searchButtonIcon } from "../icons";
import {
  ProjectsListSearchStyle,
  ProjectsListSearchInputStyle,
} from "client/pages/projects/projects.styles";
import { useDispatch } from "react-redux";
import { projectsGetListProjects } from "client/model/projects/projects";
import AddProjectButton from "./AddProjectButton";

const SearchButton = () => {
  const [isSearchButtonAnimated, setSearchButtonAnimated] = useState(true);
  const [isTitleAnimated, setisTitleAnimated] = useState(false);
  const [showSearchField, setShowSearchField] = useState(false);
  const [search, setSearch] = useState("");
  const [showCancelButton, setShowCancelButton] = useState<boolean>(false);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(projectsGetListProjects(search));
  }, [dispatch, search]);

  const handleProjectsSearch = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setSearch(value);
    },
    [setSearch]
  );

  const handleOpenSearchField = useCallback(() => {
    setSearchButtonAnimated(false);
    setShowSearchField(true);
    setShowCancelButton(true);
    setTimeout(() => setisTitleAnimated(true), 180);
  }, [setSearch]);

  const cancelSearch = () => {
    setisTitleAnimated(false);
    setShowAnimation(false);
    setShowCancelButton(false);
    setSearch("");
    setTimeout(() => setShowSearchField(false), 200);
    setTimeout(() => setSearchButtonAnimated(true), 200);
  };

  useEffect(() => {
    setShowAnimation(showSearchField);
  }, [showSearchField]);

  // 1) animation started
  // 1) "Проект" gets smaller

  return (
    <>
      <HeaderButtonsNoSearch>
        <HeaderTitle isAnimated={isTitleAnimated}>Проекты</HeaderTitle>
        {showSearchField && (
          <ProjectsListSearchStyle showAnimation={showAnimation}>
            <ProjectsListSearchInputStyle
              value={search}
              onChange={handleProjectsSearch}
            />
          </ProjectsListSearchStyle>
        )}

        {isSearchButtonAnimated && (
          <SearchButtonWrapper
            onClick={handleOpenSearchField}
            isAnimated={showAnimation}
          >
            <ButtonIcon src={searchButtonIcon} alt="search project icon" />
          </SearchButtonWrapper>
        )}
      </HeaderButtonsNoSearch>
      {showCancelButton ? (
        <CancelText onClick={cancelSearch}>{"Отменить"}</CancelText>
      ) : (
        <AddProjectButton />
      )}
    </>
  );
};

export default memo(SearchButton);
