import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors, TypographyUI, UIPage } from "client/ui";
import { searchButtonIcon } from "client/ui/icons";

export const ProjectsListPageStyle = styled(UIPage)`
  flex: 1 1 0%;
  /* position: relative; */
  /* overflow: visible; */
  margin-bottom: 18px;
  min-height: auto;
  height: auto;
  /* margin-top: 40px; */
`;

export const ProjectsListSearchStyle = styled.div<{ showAnimation: boolean }>`
  position: absolute;
  top: 22px;
  right: 92px;
  padding: 0;
  height: 48px;
  width: ${({ showAnimation }) =>
    showAnimation ? "calc(100% - 108px)" : "66px"};
  transition: width 200ms ease;
  z-index: 3;
  margin: 0;
  display: flex;
`;

export const ProjectsListSearchInputStyle = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 16px 0 52px;
  background: #f9f9f9 url(${searchButtonIcon}) no-repeat 16px center;
  border: none;
  border-radius: 12px;
  color: ${colors.textBlack};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  text-overflow: ellipsis;
  outline: none;
  appearance: none;
  margin: 0;
`;

export const ProjectsListIconStyle = styled.img`
  width: 24px;
  height: 24px;
`;

export const ProjectsListCreateLinkStyle = styled(Link)`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  background: ${colors.Transparent};
  text-decoration: none;
  margin-bottom: 16px;
`;

export const ProjectsListCreateLinkTextStyle = styled(TypographyUI)`
  margin-right: 16px;
`;

export const ProjectsListLinkDetailsStyle = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 93px;
  padding: 12px 16px;
  background: ${colors.white};
  box-shadow: 0px 20px 40px rgba(212, 212, 212, 0.25);
  border-radius: 15px;
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ProjectsListLinkDetailsTitleStyle = styled.div`
  display: flex;
  align-items: center;
`;

export const ProjectsListProjectTypeIconStyle = styled.img`
  width: 34px;
  height: 34px;
`;

export const ProjectsListLinkDetailsTextStyle = styled(TypographyUI)`
  padding-left: 12px;
`;

export const ProjectsListLinkDetailsLastPaymentStyle = styled(TypographyUI)`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 12px;
`;
