import { ColorsVariant } from "./types";

export const colors: { [key in ColorsVariant]: string } = {
  [ColorsVariant.CaribbeanGreen]: "#00E096",
  [ColorsVariant.CaribbeanGreen2]: "#00C785",
  [ColorsVariant.Aquamarine]: "#56FFA5",
  [ColorsVariant.AeroBlue]: "#B2FFD6",
  [ColorsVariant.Tuna]: "#F3F3F3",
  [ColorsVariant.Tuna2]: "#F9F9F9",
  [ColorsVariant.Concrete]: "#F2F2F2",
  [ColorsVariant.Trout]: "#4A4A57",
  [ColorsVariant.Gray]: "#9D9DA1",
  [ColorsVariant.MidGray]: "#64646C",
  [ColorsVariant.DarkGray]: "#373740",
  [ColorsVariant.GoldenTainoi]: "#FFC94D",
  [ColorsVariant.Carnation]: "#F85D5D",
  [ColorsVariant.BaliHai]: "#8F9BB3",
  [ColorsVariant.AzureRadiance]: "#0095FF",
  [ColorsVariant.Heliotrope]: "#C57CFF",
  [ColorsVariant.Mercury]: "#E5E5E5",
  [ColorsVariant.Mako]: "#4A4A57",
  [ColorsVariant.SilverChalice]: "#A6A6A6",
  [ColorsVariant.Silver]: "#999999",
  [ColorsVariant.Bombay]: "#B0B0B3",
  [ColorsVariant.Jumbo]: "#74747B",
  [ColorsVariant.VividTangerine]: "#FF857C",
  [ColorsVariant.Manatee]: "#95959A",
  [ColorsVariant.Mandy]: "#EB526E",
  [ColorsVariant.FuelYellow]: "#ED9E2A",
  [ColorsVariant.Cerulean]: "#0092E4",
  [ColorsVariant.RadicalRed]: "#FF3D71",
  [ColorsVariant.StormGrey]: "#7C7C8B",
  [ColorsVariant.Transparent]: "transparent",
  [ColorsVariant.Gray85]: "#D9D9D9",
  [ColorsVariant.White]: "#FFFFFF",
  [ColorsVariant.GreenButton]: "#00E096",
  [ColorsVariant.TextBlack]: "#1A1C1D",
  [ColorsVariant.BackgroundGray]: "#E9E9E9",
  [ColorsVariant.TextGray]: "#999999",
  [ColorsVariant.RedText]: "#F85D5D",
};
