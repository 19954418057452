import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { REACT_ROOT_ID } from "client/common/constants/constants";
import { Client } from "./client/client.component";
import "../src/client/ui/fonts/fonts.css";

ReactDOM.render(
  <React.StrictMode>
    <Client />
  </React.StrictMode>,
  document.getElementById(REACT_ROOT_ID)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
