import { memo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './pages';
import { CommonStyle } from './ui/common/common.styles';

function ClientComponent() {

    return (
        <BrowserRouter>
            <CommonStyle />
            <App />
        </BrowserRouter>
    );
}

export const Client = memo(ClientComponent);
