import styled from "styled-components";
import { colors } from "../common/colors";
import { TypographyUI } from "../typography/typography.component";

export const InputCodeUIStyle = styled.div`
  width: 216px;
  height: 54px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  &:first-child {
    margin-left: 0;
  }
`;

export const InputCodeInputUIStyle = styled(TypographyUI)<{
  isActive?: boolean;
}>`
  height: 51px;
  width: 56px;
  background: ${colors.Tuna2};
  border-radius: 12px;
  border: 1px solid
    ${({ isActive }) => (isActive ? colors.CaribbeanGreen : "#E7EAEB")};
  outline: none;
  appearance: none;
  text-align: center;
  margin-left: 10px;
  color: ${colors.textBlack};
`;

// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: flex-start;
// padding: 7px 109px 8px 14px;
// gap: 4px;

// width: 51px;
// height: 56px;

// background: #F9F9F9;
// /* Neutral/200 */

// border: 1px solid #E7EAEB;
// border-radius: 12px;
