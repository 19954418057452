import {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  CSSProperties,
} from "react";
import { useOutsideClick } from "../../common/utils";
import {
  SelectUIWrapperStyle,
  CustomSelect,
  PopUpSelectOptions,
  StyledOption,
} from "./select.styles";
import { colors } from "client/ui";
import { StyledCurtain } from "./select.styles";
import { CustomSelectAccounts } from "./styled.select.accounts.styles";

export interface OptionsProps {
  id?: string;
  value: string | number;
  title: string;
  balance: string;
  icon?: string;
  iconBg?: string;
}

export const defaultProps = {
  selectOptions: [],
};

interface StyledSelectProps {
  selectOptions: OptionsProps[];
  value: string | number;
  onChange(value: string | number): void;
  disabled?: boolean;
  fieldName: string;
  style?: CSSProperties;
  defaultOption?: OptionsProps;
  resetFieldState?: (name: string) => void;
  fieldName2?: string;
}

const StyledSelectAccount = ({
  selectOptions = defaultProps.selectOptions,
  value: originValue,
  onChange,
  disabled,
  fieldName,
  style,
  defaultOption,
  resetFieldState,
  fieldName2,
}: StyledSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  
  const currentSelect = useMemo(
    () =>
      selectOptions.find(
        ({ value }) => value.toString() === originValue.toString()
      ),
    [selectOptions, originValue]
  );

  const handleSelect = useCallback(
    (value: any) => () => {
      setIsOpen(!isOpen);
      onChange(value);
    },
    [isOpen, setIsOpen, onChange]
  );

  const handleOpen = useCallback(() => {
    if (resetFieldState && fieldName2) resetFieldState(fieldName2);
    setIsOpen(!isOpen);
  }, [isOpen]);

  const ItemsSelectRender = useMemo(() => {
    return (
      <PopUpSelectOptions
        options={selectOptions}
        isOpen={isOpen}
        fieldTitle={fieldName}
        onClose={() => setIsOpen(false)}
      >
        {selectOptions.map(({ title, value, icon, iconBg }, index) => {
          const isActive =
            selectOptions[index].value.toString() === originValue.toString();
          return (
            <StyledOption
              key={title}
              icon={icon || ""}
              title={title}
              isActive={isActive}
              onClick={handleSelect(value)}
              height="80px"
              background={iconBg || colors.BackgroundGray}
            />
          );
        })}
      </PopUpSelectOptions>
    );
  }, [selectOptions, handleSelect, originValue]);

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <SelectUIWrapperStyle ref={ref} isDisabled={disabled}>
      <CustomSelectAccounts
        icon={currentSelect?.icon || defaultOption?.icon || ""}
        title={currentSelect?.title || defaultOption?.title || ""}
        balance={currentSelect?.balance || defaultOption?.balance || ""}
        onClick={handleOpen}
        background={currentSelect?.iconBg || colors.BackgroundGray}
        style={{ ...style }}
        isDefault={!currentSelect?.title}
      />

      {isOpen && <StyledCurtain onClick={() => setIsOpen(false)} />}

      {ItemsSelectRender}
    </SelectUIWrapperStyle>
  );
};

export default memo(StyledSelectAccount);
