import { memo } from 'react';
import { matchPath, Redirect, Route, RouteProps } from 'react-router-dom';

export  type ActivationRedirectState = {
    from: Location;
} | null;

export  type AuthorizationRedirectState = {
    from: Location;
} | null;

interface PageRouteComponentProps extends RouteProps {
    needAuthorization?: boolean;
    needRedirectIfAuthorization?: boolean;
    isAuthorized: boolean;
    authorizedRedirectTo: string;
    baseRedirectTo: string;
    pageRedirectTo?: string;
}

class PageRouteComponent extends Route<PageRouteComponentProps> {
    render() {
        const {
            needAuthorization,
            needRedirectIfAuthorization,
            isAuthorized,
            authorizedRedirectTo,
            baseRedirectTo,
            pageRedirectTo,
            location,
        } = this.props;

        if(isAuthorized === false && needAuthorization) {
            return (
                <Redirect 
                    to={{
                        pathname: authorizedRedirectTo,
                        state: { from: location },
                    }}
                />
            );
        }

        if(isAuthorized && needRedirectIfAuthorization === true) {
            return (
                <Redirect 
                    to={{
                        pathname: baseRedirectTo,
                        state: { from: location },
                    }}
                />
            );
        }

        return (pageRedirectTo === undefined || !this.isMatch()) ? super.render() : <Redirect to={pageRedirectTo} />;
    }

    private isMatch() {
        const { path, location = { pathname: '' } } = this.props;
        const match = matchPath(location.pathname, { path });

        return match !== null && match.isExact;
    }
};

export const PageRoute = memo(PageRouteComponent);
