import styled from "styled-components";

export const MenuWrapper = styled.main`
  display: flex;
  flex-direction: column;
  padding: 19px 18px;
  width: 100%;
  height: 100%;
`;

export const MenuTitle = styled.section`
  height: 24px;
  font-family: "PP Pangram Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1a1c1d;
  margin-top: 5px;
`;

export const CloseButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #00e096;
  border: none;
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionWrap = styled.div`
  display: flex;
  margin-bottom: 16px;
  text-decoration: none;
  border: none;
`;
export const IconWrapper = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  background: #f3f3f3;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img``;

export const OptionDescriptionWrap = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;

export const OptionDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;

export const OperationTitle = styled.span`
  height: 16px;
  font-family: "PP Pangram Medium";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #33333d;
  margin-bottom: 6px;
`;
export const OperationInstruction = styled.span`
  height: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #8f8f8f;
  flex: none;
`;
