import styled from 'styled-components';

export const ListtUIStyle = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ListtUIElementStyle = styled.div<{marginBottom?: string}>`
    margin-bottom: ${({marginBottom})=>marginBottom ? marginBottom : '16px'};

    &:last-of-type {
        margin-bottom: 0;
    }
`;
