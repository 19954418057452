import {
  Sides,
  MenuButtonSection,
  ButtonImg,
  DotSection,
  MenuTitleSecton,
  UpperEmptyDiv,
  Dot,
  StyledTitle,
} from "./root-header.styles";

import { memo } from "react";

interface SideSectionProps {
  imageSource: string;
  isActive: boolean;
  title?: string;
  onClick: () => void;
}

const SideSection = ({
  imageSource,
  isActive,
  title,
  onClick,
}: SideSectionProps) => {
  return (
    <Sides onClick={onClick}>
      <ButtonImg src={imageSource} />
      <Dot isActive={isActive} />

      <StyledTitle isActive={isActive}>{title}</StyledTitle>
    </Sides>
  );
};

export default memo(SideSection);
