import { memo, Fragment, PropsWithChildren } from "react";
import { ColorsVariant } from "../common/types";
import { TypographyUITextStyles } from "../typography/typography.types";
import {
  ErrorWrapperUIStyle,
  ComplexErrorWrapperUIStyle,
  WrapperNoMistakesYet,
  MainContainer,
} from "./error-wrapper.styles";

export interface InputUIComponentProps {
  disabled?: boolean;
  errorText: string;
}

export const defaultProps = {
  disabled: false,
};

const ErrorWrapperUIComponent = ({
  children,
  disabled = defaultProps.disabled,
  errorText,
}: PropsWithChildren<InputUIComponentProps>) => {
  return (
    <Fragment>
      {children}
      {disabled && (
        <ErrorWrapperUIStyle
          textColor={ColorsVariant.Carnation}
          textStyle={TypographyUITextStyles.errorText}
        >
          {errorText}
        </ErrorWrapperUIStyle>
      )}
    </Fragment>
  );
};

interface ComplexDoubleWrapperProps {
  errorText: string;
  isError: boolean;
}

// export const ComplexDoubleWrapper = ({
//     children,

// }) => {

//     return (
//         <MainContainer>
//             <WrapperNoMistakesYet>
//                 {children}
//             </WrapperNoMistakesYet>

//         </MainContainer>
//     );
// };

export const ErrorWrapperUI = memo(ErrorWrapperUIComponent);
