import { ColorsVariant } from "client/ui";
import {
  AccountType,
  Currency,
  Exploitation,
  OperationType,
  ProjectsParticipantsRole,
  AccountingMethod,
  ExploitationTrackingUnits,
  ExploitationType,
  CurrName,
} from "./enums";
import { dollarIcon, rubleIcon, euroIcon } from "client/ui/icons";

export const mapAccountType: { [key: string]: string } = {
  [AccountType.CASH]: AccountType[AccountType.CASH],
  [AccountType.NON_CASH]: AccountType[AccountType.NON_CASH],
  [AccountType.BANK_CARD]: AccountType[AccountType.BANK_CARD],
  [AccountType.E_WALLET]: AccountType[AccountType.E_WALLET],
};

// export const mapCurrency: { [key: string]: string } = {
//   [Currency.RUB]: Currency[Currency.RUB],
//   [Currency.USD]: Currency[Currency.USD],
//   [Currency.EUR]: Currency[Currency.EUR],
// };

export const mapCurrency: { [key: Currency]: CurrName } = Object.fromEntries(
  Object.entries(Currency).map((a) => a.reverse())
);

// export const mapCurrencyIcon: { [key: string]: string } = {
//   [Currency.RUB]: rubleIcon,
//   [Currency.USD]: dollarIcon,
//   [Currency.EUR]: euroIcon,
// };

export const mapCurrencyIcon: { [key: Currency]: string } = {
  [Currency.RUB]: rubleIcon,
  [Currency.USD]: dollarIcon,
  [Currency.EUR]: euroIcon,
};

export const mapExploitation: { [key: string]: string } = {
  [Exploitation.NONE]: Exploitation[Exploitation.NONE],
  [Exploitation.KILO]: Exploitation[Exploitation.KILO],
  [Exploitation.HOURS_MINUTES]: Exploitation[Exploitation.HOURS_MINUTES],
  [Exploitation.HOURS_FRACTION]: Exploitation[Exploitation.HOURS_FRACTION],
};

export const mapExploitationTypes: { [key: string]: string } = {
  [ExploitationType.ABSOLUTE_VALUE]:
    ExploitationType[ExploitationType.ABSOLUTE_VALUE],
  [ExploitationType.TRACKING_DEVICE]:
    ExploitationType[ExploitationType.TRACKING_DEVICE],
  [ExploitationType.NONE]: ExploitationType[ExploitationType.NONE],
};

export const mapExpoitationTrackingUnits: { [key: string]: string } = {
  [ExploitationTrackingUnits.NONE]:
    ExploitationTrackingUnits[ExploitationTrackingUnits.NONE],
  [ExploitationTrackingUnits.HOURS_MINUTES]:
    ExploitationTrackingUnits[ExploitationTrackingUnits.HOURS_MINUTES],
  [ExploitationTrackingUnits.HOURS_FRACTIONS]:
    ExploitationTrackingUnits[ExploitationTrackingUnits.HOURS_FRACTIONS],
  [ExploitationTrackingUnits.KMS]:
    ExploitationTrackingUnits[ExploitationTrackingUnits.KMS],
};

export const mapParticipantsRole: { [key: string]: string } = {
  [ProjectsParticipantsRole.USER]:
    ProjectsParticipantsRole[ProjectsParticipantsRole.USER],
  [ProjectsParticipantsRole.ADMIN]:
    ProjectsParticipantsRole[ProjectsParticipantsRole.ADMIN],
  [ProjectsParticipantsRole.SUPER_ADMIN]:
    ProjectsParticipantsRole[ProjectsParticipantsRole.SUPER_ADMIN],
};

export const mapParticipantsRoleColor: { [key: string]: ColorsVariant } = {
  [ProjectsParticipantsRole.USER]: ColorsVariant.FuelYellow,
  [ProjectsParticipantsRole.ADMIN]: ColorsVariant.Cerulean,
  [ProjectsParticipantsRole.SUPER_ADMIN]: ColorsVariant.CaribbeanGreen,
};
export const mapParticipantsRoleColorText: { [key: string]: ColorsVariant } = {
  [ProjectsParticipantsRole.USER]: ColorsVariant.Mercury,
  [ProjectsParticipantsRole.ADMIN]: ColorsVariant.Mercury,
  [ProjectsParticipantsRole.SUPER_ADMIN]: ColorsVariant.Mako,
};

export const mapOperationsTypeColor: { [key: string]: ColorsVariant } = {
  [OperationType.USER]: ColorsVariant.FuelYellow,
  [OperationType.INCOME]: ColorsVariant.CaribbeanGreen,
  [OperationType.EXPENSES]: ColorsVariant.Mandy,
};

export const mapOperationsTypeSymbol: { [key: string]: string } = {
  [OperationType.USER]: "",
  [OperationType.INCOME]: "+",
  [OperationType.EXPENSES]: "-",
};

export const mapOperationsType: { [key: string]: string } = {
  [OperationType.USER]: OperationType[OperationType.USER],
  [OperationType.INCOME]: OperationType[OperationType.INCOME],
  [OperationType.EXPENSES]: OperationType[OperationType.EXPENSES],
};
