import { useState, ReactChild, memo } from "react";
import { AddCategoryWrapper, ButtonWrapper } from "./category.select.styles";
import { addProjectIcon } from "../icons";
import {
  AddUserIconWrapper,
  AddUserIcon,
  AddUserText,
} from "client/pages/projects/details-info/projectDetailsFormFields/ProjectParticipantsField.styles";

const CategorySelectWrapper = ({
  children,
  onAddCategory,
  onAddText = "Добавить категорию",
}: {
  children: ReactChild | ReactChild[];
  onAddCategory?: () => void;
  onAddText?: string;
}) => {
  return (
    <AddCategoryWrapper>
      <ButtonWrapper onClick={onAddCategory} type="button">
        <AddUserIconWrapper>
          <AddUserIcon src={addProjectIcon} alt="add category icon" />
        </AddUserIconWrapper>
        <AddUserText>{onAddText}</AddUserText>
      </ButtonWrapper>
      {children}
    </AddCategoryWrapper>
  );
};

export default memo(CategorySelectWrapper);
