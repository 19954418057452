import { colors } from '../common/colors';
import { ButtonUIDisabledColor, ButtonUISize, ButtonUISizes, ButtonUIDisabledColors } from './button.types';

export const buttonUIsizes: { [key in ButtonUISizes]: ButtonUISize } = {
    [ButtonUISizes.small]: {
        height: 32,
        padding: 16,
        fontSize: 14,
        lineHeight: 16,
    },
    [ButtonUISizes.medium]: {
        height: 40,
        padding: 24,
        fontSize: 14,
        lineHeight: 16,
    },
    [ButtonUISizes.large]: {
        height: 56,
        padding: 30,
        fontSize: 18,
        lineHeight: 21,
    },
};

export const buttonUIdisabledColors: { [key in ButtonUIDisabledColors]: ButtonUIDisabledColor } = {
    [ButtonUIDisabledColors.Trout]: {
        background: colors.Trout,
        color: colors.Mercury,
    },
    [ButtonUIDisabledColors.MidGray]: {
        background: colors.MidGray,
        color: colors.SilverChalice,
    },
};
