import { createGlobalStyle } from "styled-components";
import { colors } from "./colors";

export const CommonStyle = createGlobalStyle`
*, :after, :before {
    box-sizing: border-box;
    /* user-select: none; */
}

html {
    min-height: 100vh;
  display: flex;
  width: 100%;
  font-size: 50%;
}

body, #app {
  min-height: 100%;
  display: flex;
  width: 100%;
}

body {
  /* outline: 2px solid red; */
  flex-grow: 5
}

#app {
  flex-grow: 5;
}

body {
  background: ${colors.Tuna};
}
`;
