import { memo, useState, useEffect } from "react";
import {
  CloseButton,
  OptionDescriptionWrap,
  MenuTitle,
  Icon,
  MenuWrapper,
} from "../createOperaionsMenu.stlyles";
import OperationOption from "./OperationOption";
import { closeButton } from "client/ui/icons";
import useGetOperationTypes from "./useGetOperatonTypes";
import PopUpWrapper from "client/ui-components/pop-up-menu/popUpMenu";
import { BlockLike } from "typescript";

interface CreateOperationsOptionsProps {
  handleCloseMenu(): void;
  isOnProjectDetailsPage: boolean;
}

const CreateOperationsOptions = ({
  handleCloseMenu,
  isOnProjectDetailsPage,
}: CreateOperationsOptionsProps) => {
  const operationTypes = useGetOperationTypes(handleCloseMenu);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
    setTimeout(handleCloseMenu, 350);
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <PopUpWrapper
      isOpen={isOpen}
      menuHeight={operationTypes?.length === 3 ? "330" : "280"}
    >
      <MenuWrapper>
        <MenuTitle>Добавить в проект</MenuTitle>
        <OptionDescriptionWrap>
          {operationTypes.map((operationType) => (
            <OperationOption
              operationTitle={operationType.title}
              operationInstruction={operationType.instruction}
              iconUrl={operationType.iconUrl}
              onClick={operationType.onClick}
              key={operationType.title}
            />
          ))}
        </OptionDescriptionWrap>
        <CloseButton onClick={handleClose}>
          <Icon src={closeButton} />
        </CloseButton>
      </MenuWrapper>
    </PopUpWrapper>
  );
};

export default memo(CreateOperationsOptions);
