import { ChangeEvent, InputHTMLAttributes, memo, useCallback } from "react";
import { TypographyUITextStyles } from "../typography/typography.types";
import {
  InputUIStyle,
  InputUIWrapperStyle,
  DoubleInputUIWrapperStyle,
  ProxyInputUIStyle,
} from "./input.styles";
//import { ProjectsOperationButtonTitleStyleWlabel, InputLabel } from '../../pages/projects/operations/operations.styles'
import styled from "styled-components";
import { colors, TypographyUI } from "client/ui";

const ProjectsOperationButtonStyle = styled.button<{
  isFocused: boolean;
  isError: boolean;
}>`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: ${colors.Tuna2};
  border-radius: 4px;
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  outline: none;
  border: ${({ isFocused, isError }) =>
    isFocused
      ? `1px solid ${colors.CaribbeanGreen}`
      : isError
      ? `1px solid ${colors.Carnation}`
      : "green"};
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
`;

const ProjectsOperationDivStyle = styled.div<{
  isFocused: boolean;
  isError: boolean;
  isDisabled: boolean;
  maxWidth: string;
}>`
    height: 56px;
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth}; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background: ${colors.Tuna2};
    border-radius: 4px;
    text-decoration: none;
    cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")} 
    outline: none;
    border: ${({ isFocused, isError }) =>
      isFocused
        ? `1px solid ${colors.CaribbeanGreen}`
        : isError
        ? `1px solid ${colors.Carnation}`
        : "none"};
     opacity: ${({ isDisabled }) => (isDisabled ? "0.7" : "1")}
 `;

const ProjectsOperationButtonTitleStyleWlabel = styled.div<{
  smallerLabel: boolean;
}>`
    width:100%;
    height: 100%
    display: flex;
    flex-direction: ${({ smallerLabel }) => (smallerLabel ? "column" : "row")};
    align-items:  ${({ smallerLabel }) =>
      smallerLabel ? "flex-start" : "center"};
       // align-items: center;
`;

const InputLabel = styled(TypographyUI)`
  display: flex;
  justify-content: flex-start;
  margin-left: 12px;
  margin-bottom: 4px;
  color: ${colors.StormGrey};
`;

export interface InputUIComponentProps
  extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  isHasError?: boolean;
  placeholderText?: string;
  onlyNumver?: boolean;
  numberWithDecimals?: boolean;
  isFocused?: boolean;
}

export interface ProxyInputProps extends InputHTMLAttributes<HTMLInputElement> {
  maxWidth?: string;
  inputWidth?: string;
  disabled?: boolean;
  isHasError?: boolean;
  placeholderText?: string;
  onlyNumver?: boolean;
  isClicked?: boolean;
  label?: string;
  isFocused?: boolean;
  characterLimit?: number;
}

export interface DoubleInputUIComponentProps
  extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  isHasError?: boolean;
  placeholderText1?: string;
  placeholderText2?: string;
  onlyNumver?: boolean;
}

export const defaultProps = {
  disabled: false,
  isHasError: false,
  onlyNumver: false,
};

const InputUIComponent = ({
  numberWithDecimals = false,
  children,
  disabled = defaultProps.disabled,
  isHasError = defaultProps.isHasError,
  onlyNumver = defaultProps.onlyNumver,
  placeholderText,
  onChange,
  isFocused = false,
  ...props
}: InputUIComponentProps) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === "function" && onChange !== undefined) {
        const value = event.target.value;
        if (onlyNumver) {
          if (value === "" || !/[^0-9]/.test(value)) {
            onChange(event);
          }
        } else if (numberWithDecimals) {
          if (
            value === "" ||
            /^[0-9]{1,2}(,[0-9]{0,2})?$/.test(value) ||
            value === "100"
          ) {
            onChange(event);
          }
        } else {
          onChange(event);
        }
      }
    },
    [onlyNumver, onChange]
  );

  return (
    <InputUIWrapperStyle>
      {/* <InputUIPlaceholderStyle>
                placeholderText
            </InputUIPlaceholderStyle> */}
      <InputUIStyle
        {...props}
        autoComplete="off"
        element="input"
        textStyle={TypographyUITextStyles.inputText}
        disabled={disabled}
        isHasError={isHasError}
        isFocused={isFocused}
        onChange={handleOnChange}
      />
    </InputUIWrapperStyle>
  );
};

const InputDouobleUIComponent = ({
  children,
  disabled = defaultProps.disabled,
  isHasError = defaultProps.isHasError,
  onlyNumver = defaultProps.onlyNumver,
  placeholderText1,
  placeholderText2,
  onChange,
  ...props
}: DoubleInputUIComponentProps) => {
  const handleOnChange1 = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === "function" && onChange !== undefined) {
        const value = event.target.value;
        if (onlyNumver) {
          if (value === "" || !/[^0-9]/.test(value)) {
            onChange(event);
          }
        } else {
          onChange(event);
        }
      }
    },
    [onlyNumver, onChange]
  );

  const handleOnChange2 = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === "function" && onChange !== undefined) {
        const value = event.target.value;
        if (onlyNumver) {
          if (value === "" || !/[^0-9]/.test(value)) {
            onChange(event);
          }
        } else {
          onChange(event);
        }
      }
    },
    [onlyNumver, onChange]
  );

  return (
    <DoubleInputUIWrapperStyle>
      <InputUIStyle
        {...props}
        autoComplete="off"
        element="input"
        textStyle={TypographyUITextStyles.subtitle1}
        disabled={disabled}
        isHasError={isHasError}
        onChange={handleOnChange1}
        placeholder={placeholderText1}
        style={{ width: "48%" }}
        name={`${props.name}1`}
      />
      <InputUIStyle
        {...props}
        autoComplete="off"
        element="input"
        textStyle={TypographyUITextStyles.subtitle1}
        disabled={disabled}
        isHasError={isHasError}
        onChange={handleOnChange2}
        placeholder={placeholderText2}
        style={{ width: "48%" }}
        name={`${props.name}2`}
      />
    </DoubleInputUIWrapperStyle>
  );
};

export const SimpleInputUIComponent = ({
  children,
  disabled = defaultProps.disabled,
  isHasError = defaultProps.isHasError,
  placeholderText,
  ...props
}: InputUIComponentProps) => {
  return (
    <InputUIWrapperStyle>
      <InputUIStyle
        {...props}
        autoComplete="off"
        element="input"
        textStyle={TypographyUITextStyles.subtitle1}
        disabled={disabled}
        isHasError={isHasError}
      />
    </InputUIWrapperStyle>
  );
};

export const ProxyInput = ({
  inputWidth,
  maxWidth = "100%",
  children,
  isFocused = false,
  disabled = defaultProps.disabled,
  isHasError = defaultProps.isHasError,
  onlyNumver = defaultProps.onlyNumver,
  placeholderText,
  onChange,
  label,
  isClicked = false,
  characterLimit,
  ...props
}: ProxyInputProps) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === "function" && onChange !== undefined) {
        const value = event.target.value;
        if (onlyNumver && !characterLimit) {
          if (value === "" || /[0-9]/.test(value)) onChange(event);
        } else if (onlyNumver && characterLimit) {
          if (
            (value === "" || /[0-9]/.test(value)) &&
            value.length <= characterLimit
          )
            onChange(event);
        } else {
          onChange(event);
        }
      }
    },
    [onlyNumver, onChange]
  );

  return (
    <ProjectsOperationDivStyle
      isFocused={isFocused}
      isError={isHasError}
      isDisabled={disabled}
      maxWidth={label === "Километры" ? "170px" : maxWidth}
    >
      <ProjectsOperationButtonTitleStyleWlabel smallerLabel={isClicked}>
        {isClicked && (
          <InputLabel textStyle={TypographyUITextStyles.button14}>
            {label}
          </InputLabel>
        )}

        <ProxyInputUIStyle
          {...props}
          autoComplete="off"
          element="input"
          textStyle={TypographyUITextStyles.subtitle1}
          disabled={disabled}
          onChange={handleOnChange}
          type={"passowrd"}
        />
      </ProjectsOperationButtonTitleStyleWlabel>
    </ProjectsOperationDivStyle>
  );
};

export const InputUI = memo(InputUIComponent);

export const DoubleInputUI = memo(InputDouobleUIComponent);
