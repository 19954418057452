import { memo, useContext } from "react";
import {
  AddProjectButtonWrapper,
  ButtonIcon,
  AddProjectButtonLink,
} from "./header.styles";
import { addProjectIcon } from "../icons";
import { pageContext } from "client/common/pages/page.settings";
import { PageName } from "client/common/pages/page-name";

const AddProjectButton = () => {
  const { getPageUrl } = useContext(pageContext);
  return (
    <AddProjectButtonLink to={getPageUrl(PageName.PROJECTS_CREATE)}>
      <AddProjectButtonWrapper>
        <ButtonIcon src={addProjectIcon} alt="add project icon" />
      </AddProjectButtonWrapper>
    </AddProjectButtonLink>
  );
};

export default memo(AddProjectButton);
