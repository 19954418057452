import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deletetJson,
  getJson,
  postFormData,
  postJson,
  putFormData,
  putJson,
} from "client/common/api";
import {
  CategoryModelElement,
  ParticipantModelElement,
  ProjectModelElement,
  ProjectModelElementSave,
  ProjectModelElementUpdate,
} from "./types";
import { snackbarShow } from "../snackbar/snackbar";
import { Exploitation, SnackbarMessageType } from "../enums";
import { RootStateOrAny } from "react-redux";
import { InvitedUser } from "./types";
import { projectsGetProject } from "./projects";

export const userInviteProject = createAsyncThunk(
  "userInviteProject",
  async (
    { phone, projectId }: { phone: string; projectId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postJson<ParticipantModelElement>("user-invite", {
        phone,
        projectId,
      });
      dispatch(
        snackbarShow({
          message: "Участник добавлен",
          messageType: SnackbarMessageType.SUCCESS,
        })
      );

      return response;
    } catch (error: any) {
      if (error.status === 400) {
        dispatch(
          snackbarShow({
            message: "Номер неверный.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const projectsDelete = createAsyncThunk(
  "projectsDelete",
  async (
    { id }: Pick<ProjectModelElement, "id">,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await deletetJson<{}>(`projects-delete`, { id });
      dispatch(
        snackbarShow({
          message: "Проект удален",
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const projectsGetListCategories = createAsyncThunk(
  "projectsGetListCategories",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getJson<{ list: CategoryModelElement[] }>(
        "categories-list"
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

//-------------projectSave start ------------------------------
export const projectsSave = createAsyncThunk(
  "projectsSave",
  async (
    { photo, ...data }: ProjectModelElementSave,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      if (data.subCategoryId) {
        formData.append("categoryId", data.subCategoryId);
      } else {
        formData.append("categoryId", data.categoryId);
      }
      formData.append("participants", JSON.stringify(data.participants));
      formData.append("projectCurrency", data.projectCurrency.toString());
      formData.append("description", data.description);
      if (data.exploitation || data.exploitation === 0) {
        formData.append("exploitation", data.exploitation.toString());
      }
      if (data.exploitation === 0) {
        formData.append("accountingMethod", "0");
      }
      if (data.exploitation) {
        formData.append("accountingMethod", "2");
      }
      if (data.deviceInitialValues) {
        formData.append(
          "deviceInitialValues",
          data.deviceInitialValues.toString()
        );
      }

      if (photo instanceof FileList && !!photo[0]) {
        formData.append("photo", photo[0], photo[0].name);
      }

      const response = await postFormData<ProjectModelElement>(
        "projects-create",
        formData
      );
      dispatch(
        snackbarShow({
          message: `Проект ${response.title} создан`,
          messageType: SnackbarMessageType.SUCCESS,
        })
      );

      return response;
    } catch (error: any) {
      console.log("ERROR", error);

      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);
//--------------------projectSave end -------------------------

// --------------------projectUpdate start----------------------
export const projectsUpdate = createAsyncThunk(
  "projectsUpdate",
  async (
    { photo, ...data }: ProjectModelElementUpdate,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append("title", data.title);
      if (data.subCategoryId) {
        formData.append("categoryId", data.subCategoryId);
      } else {
        formData.append("categoryId", data.categoryId);
      }

      formData.append("participants", JSON.stringify(data.participants));
      formData.append("projectCurrency", data.projectCurrency.toString());
      formData.append("description", data.description);

      if (data.exploitation !== undefined) {
        formData.append("exploitation", data.exploitation.toString());
        if (data.exploitation === Exploitation.NONE) {
          formData.append("accountingMethod", "0");
        } else {
          formData.append("accountingMethod", "2");
        }
      } else {
        formData.append("accountingMethod", "0");
      }

      if (data.deviceInitialValues) {
        if (data.exploitation === Exploitation.NONE) {
          formData.append("deviceInitialValues", "0");
        } else {
          formData.append(
            "deviceInitialValues",
            data.deviceInitialValues.toString()
          );
        }
      }

      if (photo instanceof FileList && !!photo[0]) {
        formData.append("photo", photo[0], photo[0].name);
      }

      const response = await putFormData<ProjectModelElement>(
        "projects-update",
        formData
      );
      dispatch(projectsGetProject(data.id));
      dispatch(
        snackbarShow({
          message: `Проект ${response.title} изменен`,
          messageType: SnackbarMessageType.SUCCESS,
        })
      );

      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
// ---------------projectUpdate end ----------------------------

//----------------projectGetParticipanets start -----------------
export const projectsGetListParticipants = createAsyncThunk(
  "projectsGetListParticipants",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getJson<{ list: ParticipantModelElement[] }>(
        "participants-list"
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);
//---------------projectGetParticipants edn ----------------------

export const makeProjectVisited = createAsyncThunk(
  "makeProjectVisited",
  async (
    { projectId }: { projectId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await putJson<any>("projects-participant-new", {
        projectId,
      });
      return response;
    } catch (error: any) {
      dispatch(
        snackbarShow({
          message: "Ошибка сервера, попробуйте позже",
          messageType: SnackbarMessageType.WRONG,
        })
      );

      return rejectWithValue(error.data);
    }
  }
);
