import { memo, useCallback, useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Field, useField, useForm } from "react-final-form";
import { ErrorWrapperUI } from "client/ui";
import { colors } from "client/ui";
import { InputWrapper, Label } from "client/pages/account/edit/edit.styles";
import { accountsListModel } from "client/model/accounts/accounts";
import { bxWallet } from "client/ui/icons";
import StyledSelecAccounts from "client/ui/select/styled.selec.accounts";
import { seperateZeros } from "client/common/utils";
import { isRightAccount } from "./operation.fields.core";
import { Currency } from "client/model/enums";
import { inputStyle, labelStyle } from "./operation.fields.core";
import { projectsElementModel } from "client/model/projects/projects";
import { AccountsModelElement } from "client/model/accounts/types";
import { useDispatch } from "react-redux";
import {
  setOperationFormValues,
  setIsCategoryMenuOpen,
} from "client/model/operations/operations";
import { pageContext } from "client/common/pages/page.settings";
import { OperationModelElementSave } from "client/model/operations/types";
import { useHistory } from "react-router";
import { PageName } from "client/common/pages/page-name";
import { userModel } from "client/model/user/user";
import { OperationNewAccountSelect } from "client/ui/select/operation.newAccount.select";

interface SelectedOptions {
  title: string;
  balance: string;
  value: string;
  icon: string;
  iconBg: string;
}

export const currencyObj: { [key: Currency]: string } = {
  [Currency.RUB]: "₽",
  [Currency.USD]: "$",
  [Currency.EUR]: "€",
};

const getSelectOptions = (accounts: AccountsModelElement[]) => {
  return accounts.map((acc) => ({
    title: acc.title,
    balance: `${seperateZeros(acc.volume)} ${currencyObj[acc.accountCurrency]}`,
    value: acc.id,
    icon: bxWallet,
    iconBg: colors.BackgroundGray,
  }));
};

const defaultOption = {
  id: "Выберите счёт",
  title: "Выберите счёт",
  icon: bxWallet,
  iconBg: colors.BackgroundGray,
  value: "Выберите счёт",
  balance: "",
};

const AccountsField = ({ values }: { values: OperationModelElementSave }) => {
  const { t } = useTranslation();
  const myAccounts = useSelector(accountsListModel);
  const loggedUser = useSelector(userModel);
  const history = useHistory();
  const { getPageUrl } = useContext(pageContext);
  const dispatch = useDispatch();
  const { focus } = useForm();
  const { input: projectIdField } = useField("projectId");
  const { input: currencyField } = useField("newCurrency");
  const { input: participantField } = useField("userId");
  const { participants } = useSelector(projectsElementModel);
  const [isFieldClicked, setIsClicked] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const usersAccounts = participants.map((participant) => ({
    userId: participant.user.userId,
    accounts: participant.user.accounts,
  }));
  const [selectedOption, setSelectedOption] = useState<SelectedOptions[]>(
    getSelectOptions(myAccounts)
  );

  useEffect(() => {
    if (loggedUser?.id === participantField?.value) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [loggedUser?.id, participantField?.value]);

  const missingAccountMsg = t(
    "PROJECTS.PAGE_OPERATION.INPUT_ERRORS.MAKER_ACCOUNT_ID"
  );

  const wrongAccountMsg = t(
    "PROJECTS.PAGE_OPERATION.INPUT_ERRORS.WRONG_ACCOUNT"
  );

  const wrongAccount = isRightAccount(
    wrongAccountMsg,
    currencyField.value as Currency,
    myAccounts
  );

  const addNewAccount = useCallback(() => {
    dispatch(setOperationFormValues(values));
    dispatch(setIsCategoryMenuOpen("Счет операции"));
    history.push(getPageUrl(PageName.MY_ACCOUNTS_CREATE));
  }, [values]);

  useEffect(() => {
    const accounts = usersAccounts.find(
      (acc) => acc.userId === participantField.value
    )?.accounts;

    if (typeof accounts !== "undefined" && !isAuthorized) {
      setSelectedOption(getSelectOptions(accounts));
    }
    if (typeof myAccounts !== undefined && isAuthorized)
      setSelectedOption(getSelectOptions(myAccounts));
  }, [
    participantField?.value,
    myAccounts?.length,
    usersAccounts?.length,
    isAuthorized,
  ]);

  return (
    <InputWrapper style={{ ...inputStyle }}>
      <Label
        style={{ ...labelStyle, marginBottom: "18px", lineHeight: "20px" }}
      >
        {"Счет операции"}
      </Label>
      {/* {!!selectedOption[0]?.value && ( */}
      <Field
        name="makerAccountId"
        // initialValue={selectedOption[0].value}
        // validate={wrongAccount}
      >
        {(props) => {
          const { input, meta } = props;
          return (
            <ErrorWrapperUI
              disabled={meta.error && meta.visited}
              errorText={meta.error}
            >
              {isAuthorized ? (
                <OperationNewAccountSelect
                  onChange={input.onChange}
                  selectOptions={selectedOption}
                  value={input.value}
                  icon={""}
                  fieldName="Счет операции"
                  style={{ borderBottom: "none", height: "40px" }}
                  defaultOption={defaultOption}
                  resetFieldState={focus}
                  fieldName2={input.name}
                  setIsClicked={setIsClicked}
                  onAddCategory={addNewAccount}
                />
              ) : (
                <StyledSelecAccounts
                  onChange={input.onChange}
                  selectOptions={selectedOption}
                  value={input.value}
                  fieldName="Счет операции"
                  style={{ borderBottom: "none", height: "40px" }}
                  defaultOption={defaultOption}
                  resetFieldState={focus}
                  fieldName2={input.name}
                />
              )}
            </ErrorWrapperUI>
          );
        }}
      </Field>
      {/* )} */}
    </InputWrapper>
  );
};

export default memo(AccountsField);
