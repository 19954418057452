import styled, { css } from 'styled-components';
import { TypographyUI } from '../typography/typography.component';
import { colors } from '../common/colors';

import { ButtonUIDisabledColor, ButtonUISize, ButtonUItextTransform } from './button.types';

export const ButtonUIStyle = styled(TypographyUI)<{
    size: ButtonUISize;
    disabledColor: ButtonUIDisabledColor;
    textTransform: ButtonUItextTransform;
}>`
    ${({ 
        size: { height, padding, fontSize, lineHeight },
        disabledColor: { background, color },
        textTransform,
        }) => css`
        width: 100%;
        height: ${height}px;
        min-height: ${height}px;
        padding: 0 ${padding}px;
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;
        text-transform: ${textTransform};

        &:disabled {
            background: ${background};
            color: ${color};
        }
    `}

    background: ${colors.CaribbeanGreen};
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    text-align: center;
    text-decoration: none;
    letter-spacing: 0.035em;
    color: ${colors.Mako};
    -webkit-appearance: none;
    display: block;

    &:focus {
        background: ${colors.CaribbeanGreen2};
    }
`;
