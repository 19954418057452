import { memo } from 'react';
import { logoUIsizes } from './logo.constants';
import { LogoUIStyle } from './logo.styles';
import { LogoUISizes } from './logo.types';

export interface LogoUIComponentProps {
    logoSize?: LogoUISizes;
}

export const defaultProps = {
    logoSize: LogoUISizes.large,
}

const LogoUIComponent = ({
    logoSize = defaultProps.logoSize,
}: LogoUIComponentProps) => {
    const { src, ...params } = logoUIsizes[logoSize];
    
    return (<LogoUIStyle src={src} {...params}/>);
};

export const LogoUI = memo(LogoUIComponent);
