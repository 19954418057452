import styled, { css } from "styled-components";
import { colors } from "client/ui";

// const menuHeight = "-330px";

export const PopUpMenuWrapper = styled.main<{
  isOpen: boolean;
  menuHeight: string;
}>`
  position: fixed;
  bottom: ${({ menuHeight }) => `-${menuHeight}px`};
  z-index: 8;
  left: 0;
  display: flex;
  width: 100%;
  height: ${({ menuHeight }) => `${menuHeight}px`};
  background: ${colors.white};
  border-radius: 16px;
  transition: transform 350ms ease;
  transform: ${({ isOpen, menuHeight }) =>
    isOpen ? `translateY(-${menuHeight}px)` : "translateY(0)"};
`;
