import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
} from "@reduxjs/toolkit";
import {
  postFormData,
  postJson,
  fetchExchangeRate,
  deletetJson,
  getJson,
  putJson,
} from "client/common/api";
import {
  AccountType,
  Currency,
  OperationType,
  SnackbarMessageType,
  currencies,
  currName,
} from "../enums";
import { snackbarShow } from "../snackbar/snackbar";
import { StoreState } from "../types";
import {
  OperationDebtModelElement,
  OperationExploitationModelElement,
  OperationModel,
  OperationModelElement,
  OperationModelElementSave,
  CustomCategory,
  UpdatedCategory,
  BaseCurrency,
} from "./types";
import { DebtPayOffFormValues } from "client/pages/projects/details/operations-list/debt-pay-off-form/PayOffForm";
import {
  operationsExploitationGetList,
  operationsGetProjectList,
  operationsDebtsGetUsertList,
} from "./operations";
import { projectsGetProject } from "../projects/projects";
import { UpdtCurrs } from "client/pages/projects/operations/operations.component commons/custom.currency/custom.currency.edit";
import { AddCurr } from "client/pages/projects/operations/operations.component commons/custom.currency/custom.currency.add";
import { mapCurrency, mapCurrencyIcon } from "../transformer";
import { currencyObj } from "client/pages/projects/operations/operations.component commons/AccountsField";

const updateCurrObjects = (response: BaseCurrency[]) => {
  console.log("from updateCurrObjects-1", response);
  for (const member in Currency) delete Currency[member];
  for (const member in mapCurrency) delete mapCurrency[member];
  for (const member in mapCurrencyIcon) delete mapCurrencyIcon[member];
  for (const member in currencyObj) delete currencyObj[member];
  currencies.length = 0;
  currName.length = 0;
  const activeCurrs = response.filter((cur: BaseCurrency) => cur.active);
  const newCurrenciesInds: Currency[] = activeCurrs.map(
    (cur: BaseCurrency) => cur.currency.id
  );
  currencies.push(...newCurrenciesInds);
  currName.push(...activeCurrs.map((cur: BaseCurrency) => cur.currency.title));
  activeCurrs.forEach((cur: BaseCurrency) => {
    Currency[cur.currency.title] = cur.currency.id;
    mapCurrency[cur.currency.id] = cur.currency.title;
    mapCurrencyIcon[cur.currency.id] = cur.currency.picture;
    currencyObj[cur.currency.id] = cur.currency.symbol;
  });

  // currencyObj[Currency.RUB] = "₽";
  // currencyObj[Currency.USD] = "$";
  // currencyObj[Currency.EUR] = "€";
  // console.log("from updateCurrObjects-2", Currency, mapCurrency);
};

const convertToInt = (str: string): string =>
  Math.trunc(Number(str)).toString();

export const loggedUersDebt = createAsyncThunk(
  "operations-user-debts",
  async (projectId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await postJson<any>("operations-user-debts", {
        projectId,
      });
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

function instanceOfDebtPayOffFormValues(
  object: any
): object is DebtPayOffFormValues {
  return "payerAccountId" in object;
}

// ********************  SAVE OPERATION ***********************//

export const operationsSave = createAsyncThunk(
  "operationsSave",
  async (
    { ...data }: OperationModelElementSave | DebtPayOffFormValues,
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("from operation-save", data);
      const formData = new FormData();

      if (instanceOfDebtPayOffFormValues(data)) {
        formData.append("makerAccountId", data.payerAccountId);
        formData.append("takerAccountId", data.payeeAccountId);
        formData.append("makerUserId", data.userId);
      } else {
        formData.append("makerAccountId", data.makerAccountId);
        formData.append("takerAccountId", data.takerAccountId);
        if (data.defaultUserId) {
          formData.append("defaultUserId", data.defaultUserId);
          if (data.userId) formData.append("makerUserId", data.userId);
        }

        if (data.currencyVolume)
          formData.append("currencyVolume", data.currencyVolume.toString());
        if (data.category) formData.append("category", data.category);
        if (data.exchangeRate)
          formData.append("rate", data.exchangeRate.toString());
        if (data.subcategory) formData.append("subcategory", data.subcategory);
        if (data.photo instanceof FileList && !!data.photo[0]) {
          formData.append("photo", data.photo[0], data.photo[0].name);
        }
      }
      if (data.creationDateByUser) {
        formData.append(
          "creationDateByUser",
          data.creationDateByUser.toString()
        );
      }

      formData.append("takerId", data.takerId);
      formData.append("description", data.description);
      formData.append("volume", convertToInt(data.volume));
      formData.append("operationType", data.operationType.toString());
      formData.append("projectId", data.projectId);
      if (data.newCurrency)
        formData.append("newCurrency", data.newCurrency.toString());

      const response = await postFormData<OperationModelElement>(
        "operations-create",
        formData
      );
      dispatch(projectsGetProject(data.projectId));
      dispatch(operationsGetProjectList(data.projectId));
      dispatch(loggedUersDebt(data.projectId));
      dispatch(operationsDebtsGetUsertList(data.projectId));
      dispatch(
        snackbarShow({
          message: `Операция создана`,
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const operationCategorySave = createAsyncThunk(
  "operationCategorySave",
  async (data: CustomCategory, { rejectWithValue, dispatch }) => {
    try {
      const response = await postJson<object>(
        "create-operation-castom-category",
        data
      );
      dispatch(
        snackbarShow({
          message: `Категория создана`,
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const operationCategoryUpdate = createAsyncThunk(
  "operationCategoryUpdate",
  async (data: UpdatedCategory, { rejectWithValue, dispatch }) => {
    try {
      const response = await postJson<object>(
        "operations-category-list-active",
        data
      );
      dispatch(
        snackbarShow({
          message: `Категории изменены`,
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

//

export const exploitationSave = createAsyncThunk(
  "exploitationSave",
  async ({ photo, ...data }: any, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      formData.append("volume", data.deviceInitialValues.toString());
      formData.append("userId", data.userId);
      formData.append("projectId", data.projectId);
      formData.append("description", data.description);
      formData.append("makerUserId", data.makerUserId);
      formData.append("createAtByUser", data.creationDateByUser.toISOString());
      if (photo instanceof FileList && !!photo[0]) {
        formData.append("photo", photo[0], photo[0].name);
      }
      const response = await postFormData<any>(
        "exploitations-create",
        formData
      );
      dispatch(projectsGetProject(data.projectId));
      dispatch(loggedUersDebt(data.projectId));
      dispatch(operationsExploitationGetList(data.projectId));
      dispatch(
        snackbarShow({
          message: "Эксплуатация создана",
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const operationDelete = createAsyncThunk(
  "operationDelete",
  async (
    { operationId, projectId }: { operationId: string; projectId: string },

    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("from operationDelete", operationId, projectId);
      const response = await deletetJson<{}>(`operations-delete`, {
        id: operationId,
      });
      dispatch(operationsGetProjectList(projectId));
      dispatch(
        snackbarShow({
          message: "Операция удалена",
          messageType: SnackbarMessageType.SUCCESS,
        })
      );
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Операция не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

// ***************** GET CURRENCIES ************************

export const getCurrencies = createAsyncThunk(
  "getCurrencies",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      // TODO: type categories
      const response = await getJson<BaseCurrency[]>("currency");
      updateCurrObjects(response);
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);
// ***************** GET CURRENCIES ENDS ************************

export const updateCurrencies = createAsyncThunk(
  "updateCurrencies",
  async (currsForUpdate: UpdtCurrs[], { rejectWithValue, dispatch }) => {
    try {
      // TODO: type categories
      const response = await putJson<BaseCurrency[]>(
        "currency",
        currsForUpdate
      );
      console.log("from updateCurrencies", response);
      updateCurrObjects(response);
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);

export const addCurrency = createAsyncThunk(
  "addCurrency",
  async (newCurr: AddCurr, { rejectWithValue, dispatch }) => {
    try {
      // TODO: type categories
      const response = await postJson<any>("currency", newCurr);
      updateCurrObjects(response);
      console.log("response", response);
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(
          snackbarShow({
            message: "Пользователь не найден.",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      } else {
        dispatch(
          snackbarShow({
            message: "Ошибка сервера, попробуйте позже",
            messageType: SnackbarMessageType.WRONG,
          })
        );
      }
      return rejectWithValue(error.data);
    }
  }
);
