import { memo, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { Provider } from "react-redux";
import { store } from "client/model";
import { getPageContext } from "client/common/pages/page-context";
import { PageName } from "client/common/pages/page-name";
import {
  PageRouter,
  RouterAuthorizationConfig,
} from "client/common/pages/page-router-config";
import { getPages, pageContext } from "../common/pages/page.settings";
import "client/common/locales";
import "normalize.css";
import "react-day-picker/lib/style.css";

function PageComponent() {
  const session = store.getState().user.isAuthorized;
  const [sessionData, setSessionData] = useState<boolean>(session);
  const config = useMemo(getPages, []);
  const { pathname } = useLocation();
  const context = useMemo(
    () => getPageContext(config, pathname),
    [config, pathname]
  );

  const authorizationConfig = useMemo(
    (): RouterAuthorizationConfig<PageName> => ({
      isAuthorized: sessionData,
      redirectTo: PageName.PROJECTS,
      authorizedRedirectTo: PageName.AUTH,
    }),
    [sessionData]
  );

  useEffect(
    () =>
      store.subscribe(() => {
        setSessionData(store.getState().user.isAuthorized);
      }),
    [setSessionData]
  );

  return (
    <Provider store={store}>
      <pageContext.Provider value={context}>
        <PageRouter
          config={config}
          context={context}
          authorizationConfig={authorizationConfig}
        />
      </pageContext.Provider>
    </Provider>
  );
}

export default memo(PageComponent);
