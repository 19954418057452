import { OperationType } from "../enums";

export const operationFormIntialValues = {
  volume: "",
  newCurrency: 0,
  currencyVolume: "",
  makerAccountId: "",
  takerId: "",
  userId: "",
  takerAccountId: "",
  projectId: "",
  operationType: OperationType.EXPENSES,
  description: "",
  photo: "",
  category: "",
  subcategory: "",
  exchangeRate: "",
};
