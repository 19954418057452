import {
  useCallback,
  useState,
  useMemo,
  memo,
  ButtonHTMLAttributes,
  useEffect,
} from "react";
import { backspace } from "../icons";
import { TypographyUITextStyles } from "../typography/typography.types";
import {
  KeyboardUIButtonStyle,
  KeyboardUIImgStyle,
  KeyboardUIPasswordPointStyle,
  KeyboardUIPasswordPointWrapperStyle,
  KeyboardUIPasswordStyle,
  KeyboardUIPassworWrapperdStyle,
  KeyboardUIRowsStyle,
  KeyboardUIRowStyle,
  KeyboardUIStyle,
} from "./keyboard.styles";
import { useSelector } from "react-redux";
import { StoreState } from "../../../client/model/types";
import { snackbarModel } from "client/model/user/user";

export interface KeyboardUIComponentProps {
  isSecurity?: boolean;
  onChange(values: string): void;
}

export const defaultProps = {
  isSecurity: false,
};

const buttonsKeyboarArray = Array.from(Array(9).keys()).map((key) => key + 1);

const Password = ({
  isSecurity,
  password,
}: {
  isSecurity: boolean;
  password: (number | undefined)[];
}) => {
  return (
    <KeyboardUIPassworWrapperdStyle>
      <KeyboardUIPasswordStyle>
        {password.map((value, index) => {
          const isActive = value !== undefined;

          return (
            <KeyboardUIPasswordPointWrapperStyle key={index}>
              <KeyboardUIPasswordPointStyle
                isSecurity={isSecurity}
                isActive={isActive}
                textStyle={TypographyUITextStyles.h5}
              >
                {!isSecurity && value}
              </KeyboardUIPasswordPointStyle>
            </KeyboardUIPasswordPointWrapperStyle>
          );
        })}
      </KeyboardUIPasswordStyle>
    </KeyboardUIPassworWrapperdStyle>
  );
};

const KeyboarButton = ({
  children,
  title,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <KeyboardUIButtonStyle
    // textStyle={TypographyUITextStyles.h5}
    type="button"
    element="button"
    {...props}
  >
    {children ?? title}
  </KeyboardUIButtonStyle>
);

const KeyboardUIComponent = ({
  isSecurity = defaultProps.isSecurity,
  onChange,
}: KeyboardUIComponentProps) => {
  const [password, setPassword] = useState<(number | undefined)[]>([]);
  const isDisableKey = useMemo(() => password.length >= 4, [password]);
  const isDisableDelete = useMemo(() => password.length < 1, [password]);
  const prettyPassword = useMemo(() => {
    const [
      value_1 = undefined,
      value_2 = undefined,
      value_3 = undefined,
      value_4 = undefined,
    ] = password;

    return [value_1, value_2, value_3, value_4];
  }, [password]);

  const formPinSubmitError = useSelector(snackbarModel);

  useEffect(() => {
    if (formPinSubmitError.message === "Пин-код введён неверно")
      setPassword([]);
  }, [formPinSubmitError]);

  const handleSetKeyboardValue = useCallback(
    (event) => {
      const newPasswordArray = [...password];
      newPasswordArray.push(Number(event.target.value));
      setPassword(newPasswordArray);
      onChange(newPasswordArray.join(""));
    },
    [password, setPassword, onChange]
  );

  const handleDeleteSymbolPassword = useCallback(() => {
    const newPasswordArray = [...password];
    newPasswordArray.pop();
    setPassword(newPasswordArray);
    onChange(newPasswordArray.join(""));
  }, [password, setPassword, onChange]);

  const buttonsKeyboar = useMemo(() => {
    const defaultOptions = {
      disabled: isDisableKey,
      onClick: handleSetKeyboardValue,
    };

    return (
      <KeyboardUIRowStyle>
        {buttonsKeyboarArray.map((button) => (
          <KeyboarButton
            key={button}
            title={button.toString()}
            value={button}
            {...defaultOptions}
          />
        ))}
      </KeyboardUIRowStyle>
    );
  }, [isDisableKey, handleSetKeyboardValue]);

  return (
    <KeyboardUIStyle>
      <Password isSecurity={isSecurity} password={prettyPassword} />
      <KeyboardUIRowsStyle>
        <KeyboardUIRowStyle>{buttonsKeyboar}</KeyboardUIRowStyle>
        <KeyboardUIRowStyle>
          <KeyboarButton
            title="0"
            value={0}
            disabled={isDisableKey}
            onClick={handleSetKeyboardValue}
          />
          <KeyboarButton
            disabled={isDisableDelete}
            onClick={handleDeleteSymbolPassword}
          >
            <KeyboardUIImgStyle src={backspace} />
          </KeyboarButton>
        </KeyboardUIRowStyle>
      </KeyboardUIRowsStyle>
    </KeyboardUIStyle>
  );
};

export const KeyboardUI = memo(KeyboardUIComponent);
