import styled from "styled-components";
import { colors } from "client/ui";

const flexContainer = `
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

export const MainFieldWrapper = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
`;

export const AddParticipantButton = styled.button`
  border: none;
  height: 48px;
  width: 100%;
  border-radius: 12px;
  background-color: ${colors.BackgroundGray};
  padding: 10px;
  margin-bottom: 24px;
  position: relative;
  ${flexContainer}
`;

export const AddUserText = styled.span`
  height: 27px;
  font-family: "WIX-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  margin-left: 16px;
  ${flexContainer}
`;

export const AddUserIconWrapper = styled.div`
  width: 8.5px;
  height: 8.5px;
  ${flexContainer}
`;
export const AddUserIcon = styled.img`
  /* width: 100%;
  height: 100%; */
`;

export const ParticipantWrapper = styled.div`
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #e7eaeb;
  background: inherit;
  ${flexContainer};
  margin-bottom: 8px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const InnerWrapper = styled.section`
  ${flexContainer};
  width: 100%;
  justify-content: flex-start;
  font-family: "WIX-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const UserButtonWrapper = styled.button`
  border: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  background-color: inherit;
`;

export const ParticipantNameWrapper = styled.span`
  display: flex;
  text-align: start;
  color: ${colors.textBlack};
  width: 135px;
  margin-left: 8px;
`;

export const DeleteIconWrapper = styled.button`
  width: 15px;
  height: 15px;
  margin-left: auto;
  margin-right: 3px;
  border: none;
  text-decoration: none;
  ${flexContainer}
`;

export const ShareWrapper = styled.span`
  height: 32px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: auto;
  padding: 10px;
  ${flexContainer}
`;

export const WarningWrapper = styled.div`
  ${flexContainer}
  align-items: flex-start;
  padding: 16px 24px;
  width: 100%;
  height: 70px;
  background: ${colors.white};
  border: 1px solid ${colors.BackgroundGray};
  border-radius: 15px;
  /* margin-top: 24px; */
  padding: 16px 10px 16px 25px;
`;

export const WariningIconWrapper = styled.div``;

export const WarningIcon = styled.img`
  width: 100%;
  height: 100%;
  ${flexContainer}
`;

export const WarningTextWrapper = styled.span`
  ${flexContainer}
  height: 37px;
  font-family: "WIX-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: ${colors.textBlack};
  margin-left: 14px;
`;
