import { useOutsideClick } from "client/common/utils";
import React, { memo, useCallback, useState } from "react";
import {
  FabButtonUIButtonIconStyle,
  FabButtonUIButtonsListStyle,
  FabButtonUIWrapperStyle,
  StyledButton,
} from "./fab-button.styles";
import { createOperation, createOperation2 } from "client/ui/icons";
import CreateOperationsOptions from "./components/CreateOperationsOptions";
import { StyledCurtain } from "client/ui/select/select.styles";
import { useSelector } from "react-redux";
import { projectsListModel } from "client/model/projects/projects";

export interface FabButtonUIComponentProps {
  isOnProjectDetailsPage?: boolean;
}

const CreateOperationMenu = ({
  isOnProjectDetailsPage = false,
}: FabButtonUIComponentProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  
  const projects = useSelector(projectsListModel);
  
  const openMenu = useCallback(() => {
    if(projects?.length) setShowMenu(true)
    else return
  }, [projects?.length]);

  const closetheEffingMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
      <FabButtonUIWrapperStyle isOnProjectDetailsPage={isOnProjectDetailsPage}>
        <FabButtonUIButtonsListStyle>
          <StyledButton onClick={openMenu}>
            <FabButtonUIButtonIconStyle
              src={isOnProjectDetailsPage ? createOperation2 : createOperation}
            />
          </StyledButton>
        </FabButtonUIButtonsListStyle>
      </FabButtonUIWrapperStyle>

      {showMenu && <StyledCurtain onClick={closetheEffingMenu} />}
      {showMenu && (
        <CreateOperationsOptions
          handleCloseMenu={closetheEffingMenu}
          isOnProjectDetailsPage={isOnProjectDetailsPage}
        />
      )}
    </>
  );
};

export default CreateOperationMenu;
