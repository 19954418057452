import styled from "styled-components";
import { colors } from "../common/colors";
import { Link } from "react-router-dom";

export const HeaderWrapper = styled.main`
  position: ralative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0 16px;
  margin-top: 25px;
  margin-bottom: 10px;
`;

export const HeaderTitle = styled.div<{ isAnimated: boolean }>`
  width: auto;
  height: 100%;
  font-family: "WIX-Medium";
  font-style: normal;
  font-weight: 700;
  font-size: ${({ isAnimated }) => (isAnimated ? "20px" : "30px")};
  line-height: ${({ isAnimated }) => (isAnimated ? "20px" : "30px")};
  transform: ${({ isAnimated }) =>
    isAnimated ? "translateX(20px)" : "translateX(0)"};
  display: flex;
  background: ${colors.Tuna};
  color: "#33333D";
  letter-spacing: 0.5px;
  align-items: center;
`;

export const commonIcon = `
  width: 40px;
  height: 40px;
  border: none;
  background: #E9E9E9;
  border-radius: 12px;
`;

export const SearchButtonWrapper = styled.button<{ isAnimated: boolean }>`
  display: flex;
  justify-content: center;
  align-self: center;
  transform: ${({ isAnimated }) =>
    isAnimated ? "translateX(-20px)" : "translateX(0)"};
  ${commonIcon}
`;

export const AddProjectButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  ${commonIcon}
`;

export const AddProjectButtonLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-left: 8px;
  ${commonIcon}
`;

export const ButtonIcon = styled.img`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const SearchFieldWraper = styled.main`
  display: flex;
`;

export const CancelText = styled.div`
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  position: absolute;
  height: 14px;
  right: 8px;
  z-index: 2;
  font-family: "PP Pangram Medium";
  font-style: normal;
  letter-spacing: 1.5px;
`;

export const HeaderButtonsNoSearch = styled.section`
  height: 100%;
  width: calc(100% - 56px);
  display: flex;
  justify-content: space-between;
  flex-shring: 0;
`;
