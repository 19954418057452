import styled from 'styled-components';
import { colors } from '../common/colors';
import { TypographyUI } from '../typography/typography.component';

export const ChipUIStyle = styled(TypographyUI)<{ colorText?: string; colorBackground?: string; }>`
    display: inline-block;
    margin-right: 10px;
    padding: 3px 18px;
    border-radius: 40px;
    background: ${({ colorBackground }) => colorBackground ?? colors.CaribbeanGreen};
    color: ${({ colorText }) => colorText ?? colors.CaribbeanGreen};

    &::last-of-type {
        margin-right: 0;  
    }
`;
