import { InputHTMLAttributes, memo } from "react";
import InputMask from "react-input-mask";
import { InputUI } from "../input/input.component";
import { colors } from "../common/colors";

export interface InputPhoneUIComponentProps
  extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  isHasError?: boolean;
}

export const defaultProps = {
  disabled: false,
  isHasError: false,
};

export const inviteInpuTextStyle = {
  fontFamily: "WIX-Regular",
  fontStyle: "Medium",
  fontSize: "14px",
  lineHeight: "14px",
  letterSpacing: "0.4px",
  border: "none",
  color: `${colors.textBlack}`,
};

const InputPhoneUIComponent = ({
  children,
  disabled = defaultProps.disabled,
  isHasError = defaultProps.isHasError,
  ...propsComponent
}: InputPhoneUIComponentProps) => {
  return (
    <InputMask
      mask="+7 999 999-99-99"
      alwaysShowMask={true}
      disabled={disabled}
      {...propsComponent}
    >
      {(inputProps: InputHTMLAttributes<HTMLInputElement>) => (
        <InputUI type="tel" {...inputProps} style={inviteInpuTextStyle} />
      )}
    </InputMask>
  );
};

export const InputPhoneUI = memo(InputPhoneUIComponent);
