export interface ButtonUISize {
    height: number;
    padding: number;
    fontSize: number;
    lineHeight: number;
}

export interface ButtonUIDisabledColor {
    background: string;
    color: string;
}

export enum ButtonUISizes {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

export enum ButtonUIDisabledColors {
    Trout = 'Trout',
    MidGray = 'MidGray',
}

export enum ButtonUItextTransform {
    None = 'none',
    Uppercase = 'uppercase',
}
