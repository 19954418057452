import styled from "styled-components";
import { colors, TypographyUI } from "..";
import { chevronDown } from "../icons";
import PopUpWrapper from "client/ui-components/pop-up-menu/popUpMenu";
import { closeButton } from "../icons";
import { useState, useEffect, CSSProperties } from "react";
import { chevronRight } from "../icons";
import { createTrue } from "typescript";
import {
  ProjectIconWrapper,
  StyledIcon,
} from "client/pages/projects/projectLink/projectButton.styles";
import { curvyRightArrow } from "../icons";
import { ArrowIcon } from "client/pages/projects/details/operations-list/OperationsList.styles";
import { editProjectIcon } from "../icons";

export const SelectUIWrapperStyle = styled.div<{ isDisabled?: boolean }>`
  position: relative;
  display: flex;
  ${({ isDisabled }) =>
    isDisabled ? "pointer-events: none" : ""}/* display:flex; */
`;

export const ChevronRightWrapper = styled.div`
  width: 10.67px;
  height: 5.33px;
  align-self: center;
  margin: auto 14px 19px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
  margin-top: auto;
`;

export const SelectUISelectStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  padding: 0 16px;
  background: ${colors.Tuna2};
  border-radius: 4px;
  text-overflow: ellipsis;
  outline: none;
  appearance: none;
`;

export const SelectUISelectWrapperElementsStyle = styled.div`
  width: 100%;
  margin: 5px 0;
  overflow: auto;
  position: absolute;
  top: 100%;
  background: ${colors.Tuna2};
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  z-index: 1000;
`;

export const SelectUISelectElementsStyle = styled.ul`
  background: ${colors.Tuna2};
  list-style: none;
  margin: 0;
  /* padding: 6px 0; */
  padding-left: 8px;
`;

export const SelectUISelectElementTitleStyle = styled(TypographyUI)<{
  isActive: boolean;
}>`
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #505050;
  padding: 16px 0;
  color: ${({ isActive }) =>
    isActive ? colors.CaribbeanGreen : colors.Mercury};
`;

export const SelectUISelectElementStyle = styled.li`
  width: 100%;
  padding: 0 16px;

  &:last-of-type ${SelectUISelectElementTitleStyle} {
    border-bottom: none;
  }
`;

// ========= new select style ================

export const CustomSelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 72px;
  width: 100%;
  padding: 8px 0;
  background: inherit;
  text-decoration: none;
  border: none;
  border-bottom: 1px solid #e7eaeb;
`;

export const SelectTitleIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ImgWrapper = styled.div<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: ${({ background }) => background};
  border-radius: 8px;
`;

export const SelectIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const StyledSelectText = styled.span`
  color: ${colors.textBlack};
  height: 24px;
  font-family: "WIX-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

export const ChevronDownWrapper = styled.img`
  width: 12px;
  height: 8px;
  align-self: center;
  margin-left auto;
  margin-right: 5px;
`;

export const SelectMenuWrapper = styled.main`
  width: 100%;
  padding-top: 12px;
  padding-left: 16px;
  padding-bottom: 32px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

export const SelectMenuTitleRow = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const StyledFieldTitle = styled.span`
  height: 24px;
  font-family: "WIX-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.15px;
  color: ${colors.textBlack};
`;

export const StyledCloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border: none;
  width: 12px;
  height: 12px;
  background: ${colors.Transparent};
`;

export const StyledOptionRadio = styled.div<{ isActive: boolean }>`
  width: 24px;
  height: 24px;
  background: ${({ isActive }) =>
    isActive ? colors.CaribbeanGreen : colors.Tuna2};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  align-self: center;
  margin-left: auto;
  border: ${({ isActive }) =>
    isActive ? "none" : `1px solid ${colors.BackgroundGray}`};
`;

export const RadioInnerCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffffff;
`;

export const StyledCurtain = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.textBlack};
  opacity: 0.2;
  z-index: 6;
`;

export const CustomSelect = ({
  title,
  icon,
  onClick,
  background,
  style,
  isIcon = true,
  isColoredIcon,
  textStyle,
  isPayee,
  isSubCategory = false,
}: {
  title: string;
  icon?: string;
  onClick: () => void;
  background: string;
  style?: CSSProperties;
  isIcon?: boolean;
  isColoredIcon?: boolean;
  textStyle?: CSSProperties;
  isPayee?: boolean;
  isSubCategory?: boolean;
}) => {
  return (
    <CustomSelectWrapper onClick={onClick} style={style}>
      <SelectTitleIconWrapper>
        {isIcon && (
          <>
            {isPayee && <ArrowIcon src={curvyRightArrow} />}
            <ImgWrapper background={background}>
              <SelectIcon src={icon} />
            </ImgWrapper>
          </>
        )}
        {isColoredIcon && (
          <ProjectIconWrapper>
            <StyledIcon src={icon} />
          </ProjectIconWrapper>
        )}
        <StyledSelectText style={{ ...textStyle }}>{title}</StyledSelectText>
      </SelectTitleIconWrapper>
      {!isSubCategory && (
        <ChevronDownWrapper src={chevronDown} alt={"chevron down icon"} />
      )}
    </CustomSelectWrapper>
  );
};

export const StyledOption = ({
  icon,
  title,
  isActive,
  onClick,
  height = "72px",
  background = colors.BackgroundGray,
  isIcon = true,
  isColoredIcon,
}: {
  icon?: string;
  title: string;
  isActive: boolean;
  onClick: () => void;
  height?: string;
  background: string;
  isIcon?: boolean;
  isColoredIcon?: boolean;
}) => {
  return (
    <CustomSelectWrapper onClick={onClick}>
      <SelectTitleIconWrapper>
        {isIcon && (
          <ImgWrapper background={background}>
            <SelectIcon src={icon} />
          </ImgWrapper>
        )}
        {isColoredIcon && (
          <ProjectIconWrapper>
            <StyledIcon src={icon} />
          </ProjectIconWrapper>
        )}
        <StyledSelectText>{title}</StyledSelectText>
      </SelectTitleIconWrapper>
      <StyledOptionRadio isActive={isActive}>
        {isActive && <RadioInnerCircle />}
      </StyledOptionRadio>
    </CustomSelectWrapper>
  );
};

interface SelectOptions {
  value: string | number;
  title: string;
  icon?: string;
}

// ------------------ POPUPSELECTOPTIONS -----------------------

export const PopUpSelectOptions = ({
  options,
  isOpen,
  fieldTitle,
  onClose,
  children,
  style,
  isOperationCategory = false,
  toEditCategories,
}: {
  options: SelectOptions[];
  isOpen: boolean;
  fieldTitle: string;
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
  style?: CSSProperties;
  isOperationCategory?: boolean;
  toEditCategories?: () => void;
}) => {
  const [clientHeight, setClientHeight] = useState(0);

  useEffect(() => {
    setClientHeight(document.documentElement.clientHeight);
  }, []);

  const contentHeight = isOperationCategory
    ? 143 + options.length * 80
    : 80 + options.length * 80;

  const height =
    contentHeight > clientHeight * 0.5 ? clientHeight * 0.5 : contentHeight;

  return (
    <PopUpWrapper isOpen={isOpen} menuHeight={height.toString()}>
      <SelectMenuWrapper style={style}>
        <SelectMenuTitleRow>
          <StyledFieldTitle>{fieldTitle}</StyledFieldTitle>
          <StyledCloseButton
            type="button"
            onClick={
              isOperationCategory && typeof toEditCategories === "function"
                ? toEditCategories
                : onClose
            }
          >
            <img
              src={
                isOperationCategory && typeof toEditCategories === "function"
                  ? editProjectIcon
                  : closeButton
              }
              alt="close button icon"
            />
          </StyledCloseButton>
        </SelectMenuTitleRow>
        <OptionsWrapper>{children}</OptionsWrapper>
      </SelectMenuWrapper>
    </PopUpWrapper>
  );
};

// ------------------ POPUPSELECTOPTIONS -----------------------

export const StyledOptionChevron = ({
  icon,
  title,
  onClick,
  height,
  background,
}: {
  icon: string;
  title: string;
  onClick: () => void;
  height?: string;
  background: string;
}) => {
  return (
    <CustomSelectWrapper onClick={onClick} style={{ border: "none" }}>
      <SelectTitleIconWrapper>
        <ImgWrapper background={background}>
          <SelectIcon src={icon} />
        </ImgWrapper>
        <StyledSelectText>{title}</StyledSelectText>
      </SelectTitleIconWrapper>
      <ChevronRightWrapper>
        <img src={chevronRight} alt="chevron icon" />
      </ChevronRightWrapper>
    </CustomSelectWrapper>
  );
};
