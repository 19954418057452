import { Suspense } from "react";
import { Switch } from "react-router-dom";
import { RouterConfig } from "client/common/pages/page.settings";
import { PageRoute } from "./page-route-config";
import { PageContext } from "./page-context";

export interface RouterAuthorizationConfig<PageName> {
  isAuthorized: boolean;
  redirectTo: PageName;
  authorizedRedirectTo: PageName;
}

export interface PageRouterComponent<PageName> {
  config: RouterConfig<PageName>[];
  context: PageContext<PageName>;
  authorizationConfig: RouterAuthorizationConfig<PageName>;
}

function renderChild<PageName>(
  {
    children,
    component: Component,
    name,
    needAuthorization,
    needRedirectIfAuthorization,
    redirectTo,
  }: RouterConfig<PageName>,
  context: PageContext<PageName>,
  authorizationConfig: RouterAuthorizationConfig<PageName>
) {
  let url = context.getPageUrl(name);

  const renderedChildren =
    children?.length !== undefined &&
    renderChildren(children, context, authorizationConfig);
  const authorizedRedirectTo = context.getPageUrl(
    authorizationConfig.authorizedRedirectTo
  );
  const baseRedirectTo = context.getPageUrl(authorizationConfig.redirectTo);
  const pageRedirectTo = !!redirectTo
    ? context.getPageUrl(redirectTo)
    : undefined;
  if (url === "" && children?.length !== undefined && children.length > 0) {
    url = `/(${children.reduce(
      (sum, { path }) =>
        path !== undefined && /(\/:)(\w+)/.exec(path) !== null
          ? sum
          : `${sum === "" ? "" : `${sum}|${path}`}`,
      ""
    )})`;
  }

  return (
    <PageRoute
      key={url}
      path={url}
      needAuthorization={needAuthorization}
      needRedirectIfAuthorization={needRedirectIfAuthorization}
      isAuthorized={authorizationConfig.isAuthorized}
      authorizedRedirectTo={authorizedRedirectTo}
      baseRedirectTo={baseRedirectTo}
      pageRedirectTo={pageRedirectTo}
    >
      <Suspense fallback={null}>
        {Component !== undefined ? (
          <Component>{renderedChildren}</Component>
        ) : (
          renderedChildren
        )}
      </Suspense>
    </PageRoute>
  );
}

function renderChildren<PageName>(
  children: RouterConfig<PageName>[],
  context: PageContext<PageName>,
  authorizationConfig: RouterAuthorizationConfig<PageName>
) {
  return (
    <Switch>
      {children.map((child) =>
        renderChild(child, context, authorizationConfig)
      )}
    </Switch>
  );
}

export function PageRouter<PageName>({
  config,
  context,
  authorizationConfig,
}: PageRouterComponent<PageName>) {
  return renderChildren(config, context, authorizationConfig);
}
