import { API_URL } from "../constants/constants";
import { Currency } from "client/model/enums";

export enum ApiMethods {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

export function setToken(token: string): void {
  localStorage.setItem("tKDcToKeN", token);
}

export function getApiStatic(path: string | null | undefined): string {
  return !!path ? `${API_URL}/${path}` : "";
}

export function postJson<T>(
  url: string,
  data: object = {},
  options?: RequestInit
): Promise<T> {
  return sendJson(url, withMethod(ApiMethods.POST, options), data);
}

export function putJson<T>(
  url: string,
  data: object = {},
  options?: RequestInit
): Promise<T> {
  return sendJson(url, withMethod(ApiMethods.PUT, options), data);
}

export function getJson<T>(url: string, options?: RequestInit): Promise<T> {
  const customOptions = {
    ...options,
    headers: {
      "Cache-Control": "no-cache, no-store, must-revalidate",
    },
  };

  return sendJson(url, withMethod(ApiMethods.GET, customOptions));
}

export function deletetJson<T>(
  url: string,
  data: object = {},
  options?: RequestInit
): Promise<T> {
  return sendJson(url, withMethod(ApiMethods.DELETE, options), data);
}

export function putFormData<T>(
  url: string,
  data: FormData,
  options?: RequestInit
): Promise<T> {
  return sendFormData(url, withMethod(ApiMethods.PUT, options), data);
}

export function postFormData<T>(
  url: string,
  data: FormData,
  options?: RequestInit
): Promise<T> {
  return sendFormData(url, withMethod(ApiMethods.POST, options), data);
}

function withMethod(method: ApiMethods, options?: RequestInit): RequestInit {
  if (options?.method !== undefined) {
    options.method = method;
  } else {
    return { method };
  }

  return options;
}

function sendJson<T>(
  url: string,
  options: RequestInit,
  data?: object
): Promise<T> {
  return fetchRequest(url, {
    ...options,
    body: JSON.stringify(data),
    headers: {
      ...options?.headers,
      "Content-Type": "Application/json; charset=utf-8",
    },
  });
}

function sendFormData<T>(
  url: string,
  options: RequestInit,
  data?: FormData
): Promise<T> {
  return fetchRequest(url, {
    ...options,
    body: data,
    headers: {
      ...options?.headers,
    },
  });
}

async function fetchRequest<T>(url: string, options?: RequestInit): Promise<T> {
  const token = localStorage.getItem("tKDcToKeN");
  const response = await fetch(`${API_URL}/${url}`, {
    ...options,
    headers: {
      ...options?.headers,
      ...(!!token && {
        Authorization: `Bearer ${token}`,
      }),
    },
  });

  if (!response.ok) {
    return Promise.reject({
      statusOk: response.ok,
      status: response.status,
      statusText: response.statusText,
    });
  }

  const data = await response.json();

  return data;
}

export async function fetchExchangeRate(currencyObj: {
  [key: string]: string;
}): Promise<{ [key: string]: number }> {
  const response = await fetch(`https://www.cbr-xml-daily.ru/latest.js`);
  const currencyList = Object.values(currencyObj);
  const exRates: { [key: string]: number } = {};
  if (!response.ok) {
    return Promise.reject({
      statusOk: response.ok,
      status: response.status,
      statusText: response.statusText,
    });
  }
  const data = await response.json();
  currencyList.forEach((currency) => {
    exRates[currency] = data.rates[currency];
  });
  return exRates;
}
