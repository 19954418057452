import { AvatarUISize, AvatarUISizes } from "./avatar.types";
import { realEstateIcon, transportIcon, financeIcon } from "../icons";
import { colors } from "../common/colors";
import { ProjectCategory } from "client/model/enums";

export const avatarUIsizes: { [key in AvatarUISizes]: AvatarUISize } = {
  [AvatarUISizes.small]: {
    size: 32,
  },
  [AvatarUISizes.mediumSmall]: {
    size: 60,
  },
  [AvatarUISizes.medium]: {
    size: 120,
  },
  [AvatarUISizes.large]: {
    size: 190,
  },
};

export const projectTypesIcons = [
  {
    type: ProjectCategory.REAL_ESTATE,
    background: "#FDBA72",
    icon: realEstateIcon,
  },
  {
    type: ProjectCategory.TRANSPORT,
    background: "#A5F3CF",
    icon: transportIcon,
  },
  {
    type: ProjectCategory.FINANCE,
    background: "#D8B4FE",
    icon: financeIcon,
  },
  {
    type: ProjectCategory.OTHER,
    background: colors.BackgroundGray,
    icon: financeIcon,
  },
];
