export enum PageName {
  HOME = "HOME",
  AUTH = "AUTH",
  PROJECTS = "PROJECTS",
  PROJECTS_OPERATION = "PROJECTS_OPERATION",
  PROJECTS_CREATE = "PROJECTS_CREATE",
  PROJECTS_DETAILS = "PROJECTS_DETAILS",
  PROJECTS_DETAILS_DEBTS = "PROJECTS_DETAILS_DEBTS",
  PROJECTS_DETAILS_DEBTS_CREATE = "PROJECTS_DETAILS_DEBTS_CREATE",
  PROJECTS_DETAILS_MYDEBTHOLDERS_CREATE = "PROJECTS_DETAILS_MYDEBTHOLDERS_CREATE",
  PROJECTS_DETAILS_OPERATION = "PROJECTS_DETAILS_OPERATION",
  PROJECTS_DETAILS_OPERATION_PROJECTS_LIST = "PROJECTS_DETAILS_OPERATION_PROJECTS_LIST",
  PROJECTS_DETAILS_OPERATION_CREATE_CUSTOM_CATEGORY = "PROJECTS_DETAILS_OPERATION_CREATE_CUSTOM_CATEGORY",
  PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CATEGORY = "PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CATEGORY",
  PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CURRENCY = "PROJECTS_DETAILS_OPERATION_UPDATE_CUSTOM_CURRENCY",
  PROJECTS_DETAILS_OPERATION_ADD_CUSTOM_CURRENCY = "PROJECTS_DETAILS_OPERATION_ADD_CUSTOM_CURRENCY",
  PROJECTS_DETAILS_OPERATIONS_LIST = "PROJECTS_DETAILS_OPERATIONS_LIST",
  PROJECTS_DETAILS_EXPLOITATIONS_LIST = "PROJECTS_DETAILS_EXPLOITATIONS_LIST",
  PROJECTS_DETAILS_MY_DEBT = "PROJECTS_DETAILS_MY_DEBT",
  PROJECTS_DETAILS_DEBT_PAYOFF = "PROJECTS_DETAILS_DEBT_PAYOFF",
  PROJECTS_DETAILS_MY_DEBTHOLDERS = "PROJECTS_DETAILS_MY_DEBTHOLDERS",
  PROJECTS_DETAILS_OPERATION_INFO = "PROJECTS_DETAILS_OPERATION_INFO",
  PROJECTS_DETAILS_EXPLOITATION_DETAILS = "PROJECTS_DETAILS_EXPLOITATION_DETAILS",
  PROJECTS_DETAILS_INFO = "PROJECTS_DETAILS_INFO",
  PROJECTS_DETAILS_INFO_CATEGORY = "PROJECTS_DETAILS_INFO_CATEGORY",
  PROJECTS_DETAILS_INFO_CATEGORY_ID = "PROJECTS_DETAILS_INFO_CATEGORY_ID",
  PROJECTS_DETAILS_INFO_PARTICIPANTS = "PROJECTS_DETAILS_INFO_PARTICIPANTS",
  PROJECTS_DETAILS_INFO_PARTICIPANTS_LIST = "PROJECTS_DETAILS_INFO_PARTICIPANTS_LIST",
  PROJECTS_DETAILS_INFO_PARTICIPANTS_INVITE = "PROJECTS_DETAILS_INFO_PARTICIPANTS_INVITE",
  PROJECTS_DETAILS_INFO_PARTICIPANTS_EDIT_ID = "PROJECTS_DETAILS_INFO_PARTICIPANTS_EDIT_ID",
  PROJECTS_DETAILS_INFO_PARTICIPANTS_ADD_ID = "PROJECTS_DETAILS_INFO_PARTICIPANTS_ADD_ID",
  PROJECTS_CREATE_CATEGORY = "PROJECTS_CREATE_CATEGORY",
  PROJECTS_CREATE_CATEGORY_ID = "PROJECTS_CREATE_CATEGORY_ID",
  PROJECTS_CREATE_PARTICIPANTS = "PROJECTS_CREATE_PARTICIPANTS",
  PROJECTS_CREATE_PARTICIPANTS_LIST = "PROJECTS_CREATE_PARTICIPANTS_LIST",
  PROJECTS_CREATE_PARTICIPANTS_INVITE = "PROJECTS_CREATE_PARTICIPANTS_INVITE",
  PROJECTS_CREATE_PARTICIPANTS_EDIT_ID = "PROJECTS_CREATE_PARTICIPANTS_EDIT_ID",
  PROJECTS_CREATE_PARTICIPANTS_ADD_ID = "PROJECTS_CREATE_PARTICIPANTS_ADD_ID",
  PROJECTS_CREATE_CUSTOM_CURRENCIES_EDIT = "PROJECTS_CREATE_CUSTOM_CURRENCIES_EDIT",
  PROJECTS_CREATE_CUSTOM_CURRENCIES_ADD = "PROJECTS_CREATE_CUSTOM_CURRENCIES_ADD",
  ACCOUNT = "ACCOUNT",
  ACCOUNT_EDIT = "ACCOUNT_EDIT",
  ACCOUNT_EDIT_PHOTO = "ACCOUNT_EDIT_PHOTO",
  ACCOUNT_SETTINGS = "ACCOUNT_SETTINGS",
  ACCOUNT_SETTINGS_PASSWORD = "ACCOUNT_SETTINGS_PASSWORD",
  MY_ACCOUNTS = "MY_ACCOUNTS",
  MY_ACCOUNTS_CREATE = "MY_ACCOUNTS_CREATE",
  MY_ACCOUNTS_DETAILS = "MY_ACCOUNTS_DETAILS",
  HELP = "HELP",
  ABOUT_PROJECT = "ABOUT_PROJECT",
  PAGE_NOT_FOUND = "PAGE_NOT_FOUND",
  EXPLOITATION = "EXPLOITATION",
  EXPLOITATION_CREATE = "EXPLOITATION_CREATE",
  EXPLOITATION_DETAIL = "EXPLOITATION_DETAIL",
  EXPLOITATION_SHOWDETAILS = "EXPLOITATION_SHOWDETAILS",
  EXPLOITATION_CREATE_WITH_PROJECT = "EXPLOITATION_CREATE_WITH_PROJECT",
}
