import CreateOperationMenu from "client/ui-components/fab-button";
import { CenterPieceMain, CenterPieceButton } from "./root-header.styles";

const CenterPiece = () => (
  <CenterPieceMain>
    <CenterPieceButton>
      <CreateOperationMenu />
    </CenterPieceButton>
  </CenterPieceMain>
);

export default CenterPiece;
