import { StoreState } from "../types";
import { createSelector } from "@reduxjs/toolkit";

export const getMyDebt = createSelector(
  (state: StoreState) => state.operations.myDebts,
  (myDebts) => myDebts
);

export const getOperationFormValues = createSelector(
  (state: StoreState) => state.operations.operationFormValues,
  (operationFormValues) => operationFormValues
);

export const getGoBackOption = createSelector(
  (state: StoreState) => state.operations.goBackOption,
  (goBackOption) => goBackOption
);

export const getIsCategoryMenuOpen = createSelector(
  (state: StoreState) => state.operations.isCategoryMenuOpen,
  (isCategoryMenuOpen) => isCategoryMenuOpen
);

export const getBaseCurrencies = createSelector(
  (state: StoreState) => state.operations.currencies,
  (currencies) => currencies
);
