import {
  TypographyUIElementStyle,
  TypographyUITextStyles,
} from "./typography.types";

export const textStyles: {
  [key in TypographyUITextStyles]: TypographyUIElementStyle;
} = {
  [TypographyUITextStyles.h1]: {
    fontWeight: 300,
    fontSize: 96,
    lineHeight: 112,
  },
  [TypographyUITextStyles.h2]: {
    fontWeight: 300,
    fontSize: 60,
    lineHeight: 70,
  },
  [TypographyUITextStyles.h3]: {
    fontWeight: 400,
    fontSize: 48,
    lineHeight: 63,
  },
  [TypographyUITextStyles.h4]: {
    fontWeight: 400,
    fontSize: 34,
    lineHeight: 40,
  },
  [TypographyUITextStyles.h5]: {
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 28,
  },
  [TypographyUITextStyles.h6]: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 23,
  },
  [TypographyUITextStyles.subtitle1]: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
  },
  [TypographyUITextStyles.subtitle2]: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 16,
  },
  [TypographyUITextStyles.body]: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 16,
  },
  [TypographyUITextStyles.button18]: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 21,
  },
  [TypographyUITextStyles.button16]: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 18,
  },
  [TypographyUITextStyles.button14]: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 16,
  },
  [TypographyUITextStyles.button12]: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 14,
  },
  [TypographyUITextStyles.caption]: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 14,
  },
  [TypographyUITextStyles.overline]: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 12,
  },
  [TypographyUITextStyles.tabtext]: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 16,
  },
  [TypographyUITextStyles.pageTop]: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 24,
  },
  [TypographyUITextStyles.inputText]: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 16,
  },
  [TypographyUITextStyles.errorText]: {
    fontSize: 14,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 16,
    letterSpacing: "0.4px",
  },
};
