import { createContext } from 'react';
import { PageParams, RouterConfig } from 'client/common/pages/page.settings';
import { generatePath, matchPath } from 'react-router';

export type GetPageUrlFunction<PageName> = (pageName: PageName, pageParams?: PageParams) => string;

export interface PageContext<PageName> {
    getPageUrl: GetPageUrlFunction<PageName>;
    checkCurrentPageName(pageName: PageName, pageParams?: PageParams): boolean;
    getCurrentPageName(pathName: string): string;
};

export function createPageContext<PageName>() {
    return createContext<PageContext<PageName>>({
        getPageUrl: () => '',
        checkCurrentPageName: () => false,
        getCurrentPageName: (pathName: string) => '',
    });
};

export function getPageContext<PageName>(routerConfig: RouterConfig<PageName>[], pathName: string) {
    const flatRouter = getFlatRouter(routerConfig);

    return {
        getPageUrl: (pageName: PageName, pageParams?: PageParams) => getPageUrl(flatRouter, pageName, pageParams),
        checkCurrentPageName: (pageName: PageName, pageParams?: PageParams) => checkCurrentPageName(flatRouter, pageName, pathName, pageParams),
        getCurrentPageName: (pathName: string) => getCurrentPageName(flatRouter, pathName),
    };
};


function checkCurrentPageName<PageName>(flatRouter: RouterConfig<PageName>[], pageName: PageName, pathName: string, pageParams?: PageParams) {
    if (pathName === undefined) {
        return false;
    }

    return getPageUrl(flatRouter, pageName, pageParams) === pathName;
};

function getPageUrl<PageName>(flatRouter: RouterConfig<PageName>[], pageName: PageName, pageParams?: PageParams) {
    let path = findPage(flatRouter, pageName)?.path;
    if (path === undefined) {
        throw new Error(`Path - ${path} doesn't exist.`);
    }

    if (pageParams !== undefined) {
        path = getPathWithParams(path, pageParams);
    }

    return path;
};

function getCurrentPageName<PageName>(flatRouter: RouterConfig<PageName>[], pathName: string) {
    const pathOrigin = pathName === '/' ? '' : pathName;
    const pageName = flatRouter.find(({ path }) => matchPath(
        pathOrigin, {
        exact: true,
        path,
        strict: false,
    }
    ));

    if (pageName === undefined) {
        throw new Error(`Page - ${pageName} doesn't exist.`);
    }

    return pageName.name
};

function getFlatRouter<PageName>(flatRouter: RouterConfig<PageName>[]) {
    const flats: RouterConfig<PageName>[] = [];
    const parentRoutes: { parentUrl: string; routes: RouterConfig<PageName>[]; }[] = [{ parentUrl: '', routes: flatRouter }];

    while (parentRoutes.length > 0) {
        const parentRoute = parentRoutes.pop();
        if (parentRoute === undefined) {
            continue;
        }

        const { parentUrl, routes } = parentRoute;

        for (const route of routes) {
            const { name, path = '', children } = route;
            const url = getUrl(parentUrl, path);

            if (children !== undefined) {
                parentRoutes.push({
                    parentUrl: url,
                    routes: children,
                });
            }

            if (name !== undefined) {
                flats.push({
                    ...route,
                    path: url,
                    sort: route.sort ?? 1,
                });
            }
        }
    }

    return flats.sort((a, b) => (a.sort as number) - (b.sort as number));
};

function getUrl(parentUrl = '', path = '') {
    return path === '' ? '' : `${parentUrl}/${path}`.replace(/[/]{2,}/, '/');
};

function findPage<PageName>(flatRouter: RouterConfig<PageName>[], pageName: PageName) {
    const page = flatRouter.find(({ name }) => Object.is(name, pageName));

    if (pageName === undefined) {
        throw new Error(`Page - ${pageName} doesn't exist.`); 
    }
    return page;
};

function getPathWithParams(path: string, params: PageParams) {
    const pathWithoutOptional = path.replace(/\((\w+)\)\?/g, '$1');

    return generatePath(pathWithoutOptional, params);
};
