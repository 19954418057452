import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { projectsModel } from "client/model/projects/projects";
import { OperationModelElementSave } from "client/model/operations/types";
import { handleNumberButton } from "./volume.calculator/volume.calculator.core";
import { useField } from "react-final-form";
import { AccountsModelElement } from "client/model/accounts/types";
import { Currency } from "client/model/enums";
import { colors } from "client/ui";
import { userModel } from "client/model/user/user";

export type CurrencyOption = Currency;

export const useProjectCurrency = () => {
  const loggedUserProjects = useSelector(projectsModel);
  const { input: newCurrencyField } = useField("newCurrency");
  const { input: projectField } = useField("projectId");
  const projectCurrency = loggedUserProjects.find(
    (project) => project.id === projectField.value
  )?.projectCurrency as Currency;
  const isCurrencyField = projectCurrency !== newCurrencyField.value;

  return { projectCurrency, isCurrencyField };
};

type ChangeValue = <T extends string>(name: T, value?: any) => void;

export const changeCurrencyVolume = (
  change: ChangeValue,
  projectCurrency: number,
  parameter: string,
  exRate: string
) => {
  const newVolume =
    projectCurrency === 0
      ? Number(parameter.replace(/\s/gm, "")) * Number(exRate)
      : Number(parameter.replace(/\s/gm, "")) / Number(exRate);
  change("currencyVolume", handleNumberButton(newVolume.toString()));
};

export const normalizeToNumber = (str: string) => {
  return Number(str.replace(/\s/gm, ""));
};

export const requiredValue = (value: string | number) =>
  value ? undefined : "Required";

export const requiredSelect =
  (exemption: string, message: string) => (value: string) => {
    return value && value !== exemption ? undefined : message;
  };

export const requiredField = (errorMsg: string) => (value: string) =>
  value ? undefined : errorMsg;

export const isRightAccount =
  (
    errorMsg: string,
    currencyFieldValue: Currency,
    accounts: AccountsModelElement[]
  ) =>
  (value: string) => {
    const filteredAccounts = accounts
      .filter((account) => account.accountCurrency === currencyFieldValue)
      .map((account) => account.id);
    if (filteredAccounts.includes(value)) {
      return undefined;
    }
    return errorMsg;
  };

export const composeValidators =
  (...validators: Function[]) =>
  (value: string) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const inputStyle = {
  // paddingBottom: "16px",
  marginBottom: "26px",
  borderBottom: `1px solid ${colors.BackgroundGray}`,
};

export const labelStyle = {
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "20px",
  letterSpacing: "0.1px",
  fontFamily: "Inter",
};

export const useIsAuthorized = (projectId: string, fieldName?: string) => {
  let isAuthorized;
  const loggedUser = useSelector(userModel);
  const projects = useSelector(projectsModel);
  const participants = projects.find(
    (project) => project.id === projectId
  )?.participants;
  const currentParticipant =
    typeof participants !== "undefined"
      ? participants.find(
          (participant) => participant.user.userId === loggedUser.id
        )
      : undefined;

  if (fieldName === "makerAccountId") {
    isAuthorized =
      typeof currentParticipant !== "undefined"
        ? currentParticipant.userRole === 2
        : undefined;
  } else {
    isAuthorized =
      typeof currentParticipant !== "undefined"
        ? currentParticipant.userRole !== 0
        : undefined;
  }
  return isAuthorized;
};
