import styled, { keyframes } from "styled-components";
import { colors } from "../common/colors";

const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

const fill = keyframes`
0% { width: 0%; }
12.5% { width: 12.5%; }
25% { width: 25%; }
50% { width: 50%; }
62.5% { width: 62.5%; }
75% { width: 75%; }
87.5% { width: 87.5%; }
100% { width: 100%; }
`;

export const LoaderContainer = styled.div`
  padding-top: 245px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
`;

export const DivStyledLikeLoader = styled.div`
  border: 10px solid #f3f3f3;
  border-top: 10px solid #00e096;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: ${spin} 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50%;
`;

export const LoaderProgressBar = styled.div`
  width: 197px;
  height: 12px;
  border-radius: 4px;
  background: #dbdbdb;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
`;

export const ProgressBarFill = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: ${colors.CaribbeanGreen};
  animation: ${fill} 1.5s linear infinite;
`;
