import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors, TypographyUI, UIPage } from "client/ui";
import { othersSearch } from "client/ui/icons";

export const ProjectLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 93px;
  padding: 12px 16px;
  background: ${colors.white};
  box-shadow: 0px 20px 40px rgba(212, 212, 212, 0.25);
  border-radius: 15px;
  text-decoration: none;
  font-family: "PP Pangram Medium";
`;

const side = `
  display: flex;
  height: 100%;
`;

export const LefSide = styled.section`
  flex-direction: column;
  width: 100%;
  ${side}
`;

export const RightSide = styled.section`
  ${side}
  width: 40%;
  align-items: center;
  flex-direction: column;
`;

export const IconAndTitleSection = styled.div`
  display: flex;
  //justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-family: "PP Pangram Medium";
`;

export const ProjectIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  /* background: #fdba72; */
  border-radius: 8px;
`;

export const StyledIcon = styled.img`
  marign: 0;
  padding: 0;
  min-height: 100%;
  min-width: 100%;
`;

export const ShareholdersNumWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 29px;
  margin-top: 10px;
  font-family: "PP Pangram Medium";
  align-items: flex-end;
  padding-bottom: 4px;
`;

export const ProjectTitle = styled.span`
  width: auto;
  height: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  align-items: center;
  letter-spacing: 0.4px;
  color: #191836;
  margin-left: 8px;
`;

export const ShareholdersNumTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #999999;
`;

export const NumberOfShareholders = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #191836;
  margin-left: 3px;
  text-align: center;
  margin-top: -3px;
`;

export const ProjectNetBudget = styled.span<{ isPositive: boolean }>`
  /* height: 24px; */
  font-family: "PP Pangram Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: ${({ isPositive }) => (isPositive ? "#1ac057" : colors.RadicalRed)};
  margin-left: auto;
`;

export const RightChevronIcon = styled.img`
  width: 6px;
  /* outline: 1px solid red; */
  padding: 0;
  margin-left: 8px;
`;

export const NewProjSign = styled.span`
  display: flex;
  width: 64px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #00e096;
  font-family: "WIX-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 181.818% */
  letter-spacing: 0.1px;
  text-align: center;
  color: ${colors.textBlack};
`;

export const BottomRightPart = styled.div`
  ${side}
  width: 100%;
`;

export const ChevronWrapper = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UpperPart = styled.section`
  width: 100%;
  display: flex;
  height: 58%;
  justify-content: space-between;
`;

export const LowerPart = styled(UpperPart)`
  height: 42%;
  align-items: flex-end;
`;
