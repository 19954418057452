import { TypographyUI } from "client/ui";
import styled from "styled-components";
import { colors } from "client/ui";

export const AuthCheckPhoneFromStyle = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;
`;

export const AuthCheckPhonePageStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AuthCheckPhoneLogoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthCheckPhoneLogoImgStyle = styled.div`
  margin-bottom: 14px;
`;

export const AuthCheckPhoneLogoTextImgStyle = styled.div`
  margin-bottom: 60px;
`;

export const AuthCheckPhoneSubtitleStyle = styled(TypographyUI)`
  margin-bottom: 24px;
  text-align: center;
`;

export const AuthCheckPhonePhoneFieldStyle = styled.div`
  margin-bottom: 24px;
  display: flex;
  background-color: ${colors.Tuna2};
  height: 56px;
  background: #f9f9f9;
  border: 1px solid #e7eaeb;
  align-items: center;
  padding: 20px 17.72px;
  border-radius: 12px;
`;

export const StyledAppTitle = styled.div`
  height: 48px;
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 9px;
`;

export const subtitleTextProps = {
  height: "40px",
  fontFamily: "PP Pangram Medium",
  fontStyle: "normal",
  fontWeight: "500",
};

export const PhoneIconWrapper = styled.div`
  width: 16.55px;
  height: 16.55px;
  margin-right: 19.73px;
`;

export const phoneInputProps = {
  height: "24px",
  fontFamily: "PP Pangram Medium",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#999999",
  letterSpacing: "0.4px",
  border: "none",
};

export const buttonProps = {
  borderRadius: "12px",
  width: "324px",
  height: "48px",
  padding: "10px",
};

export const generateButtonProps = (
  invalid: boolean
): { [key: string]: string } => {
  return {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    letterSpacing: "0.1px",
    fontFamily: "WIX-Regular",
    borderRadius: "12px",
    width: "100%",
    height: "48px",
    padding: "10px",
    backgroundColor: invalid ? "#E9E9E9" : `${colors.CaribbeanGreen}`,
    color: invalid ? "#999999" : `${colors.textBlack}`,
    marginLeft: "auto",
    marginRight: "auto",
  };
};


export const DemmoAccountModalWrapper = styled.main`
  width: 100%;
  display: flex;
  
`