import { logoLarge, logoSmall } from '../icons';
import { LogoUISize, LogoUISizes } from './logo.types';

export const logoUIsizes: { [key in LogoUISizes]: LogoUISize } = {
    [LogoUISizes.small]: {
        height: 98,
        width: 98,
        src: logoSmall,
    },
    [LogoUISizes.large]: {
        height: 144,
        width: 144,
        src: logoLarge,
    },
};
