import { createAsyncThunk, createReducer, createSelector } from '@reduxjs/toolkit';
import { deletetJson, getJson, postJson, putJson } from 'client/common/api';
import { AccountType, Currency, SnackbarMessageType } from '../enums';
import { snackbarShow } from '../snackbar/snackbar';
import { StoreState } from '../types';
import { AccountsModel, AccountsModelElement } from './types';

const initialState: AccountsModel = {
  accounts: [],
  details: {
    id: '',
    userId: '',
    title: '',
    volume: 0,
    accountType: AccountType.CASH,
    accountCurrency: Currency.RUB,
  },
  loading: false,
};

export const accountsGetList = createAsyncThunk('accountsGetList', async (_ ,{ rejectWithValue, dispatch }) => {
  try {
    const response = await getJson<{ list: AccountsModelElement[] }>('accounts-list');
    return response;
  } catch (error: any) {
    if(error.status === 404) {
        dispatch(snackbarShow({ message: 'Пользователь не найден.', messageType: SnackbarMessageType.WRONG }));
    } else {
        dispatch(snackbarShow({ message: 'Ошибка сервера, попробуйте позже', messageType: SnackbarMessageType.WRONG }));
    }
    return rejectWithValue(error.data);
  }
});

export const accountsGetDetails = createAsyncThunk('accountsGetDetails', async ({ id }: Pick<AccountsModelElement, 'id'>, { rejectWithValue, dispatch }) => {
  try {
    const response = await getJson<AccountsModelElement>(`accounts-get/${id}`);
    return response;
  } catch (error: any) {
    if(error.status === 404) {
        dispatch(snackbarShow({ message: 'Пользователь не найден.', messageType: SnackbarMessageType.WRONG }));
    } else {
        dispatch(snackbarShow({ message: 'Ошибка сервера, попробуйте позже', messageType: SnackbarMessageType.WRONG }));
    }
    return rejectWithValue(error.data);
  }
});

export const accountsSave = createAsyncThunk('accountsSave', async (data: Partial<AccountsModelElement>, { rejectWithValue, dispatch }) => {
  try {
    const response = await postJson<AccountsModelElement>('accounts-create', data);
    dispatch(snackbarShow({ message: `Счет ${response.title} создан`, messageType: SnackbarMessageType.SUCCESS }));
    return response;
  } catch (error: any) {
    if(error.status === 404) {
      dispatch(snackbarShow({ message: 'Пользователь не найден.', messageType: SnackbarMessageType.WRONG }));
    } else {
        dispatch(snackbarShow({ message: 'Ошибка сервера, попробуйте позже', messageType: SnackbarMessageType.WRONG }));
    }
    return rejectWithValue(error.data);
  }
});

export const accountsUpdate = createAsyncThunk('accountsUpdate', async (data: Partial<AccountsModelElement>, { rejectWithValue, dispatch }) => {
  try {
    const response = await putJson<AccountsModelElement>('accounts-update', data);
    dispatch(snackbarShow({ message: `Счет ${response.title} изменен`, messageType: SnackbarMessageType.SUCCESS }));
    return response;
  } catch (error: any) {
    if(error.status === 404) {
        dispatch(snackbarShow({ message: 'Пользователь не найден.', messageType: SnackbarMessageType.WRONG }));
    } else {
        dispatch(snackbarShow({ message: 'Ошибка сервера, попробуйте позже', messageType: SnackbarMessageType.WRONG }));
    }
    return rejectWithValue(error.data);
  }
});

export const accountsDelete = createAsyncThunk('accountsDelete', async ({ id }: Pick<AccountsModelElement, 'id'>, { rejectWithValue, dispatch }) => {
  try {
    const response = await deletetJson<null>('accounts-delete', { id });
    dispatch(snackbarShow({ message: 'Счет удален', messageType: SnackbarMessageType.SUCCESS }));
    return response;
  } catch (error: any) {
    if(error.status === 404) {
        dispatch(snackbarShow({ message: 'Пользователь не найден.', messageType: SnackbarMessageType.WRONG }));
    } else {
        dispatch(snackbarShow({ message: 'Ошибка сервера, попробуйте позже', messageType: SnackbarMessageType.WRONG }));
    }
    return rejectWithValue(error.data);
  }
});

export const accountsListModel = createSelector(
  (state: StoreState) => state.accounts.accounts,
  (accounts) => accounts
);

export const accountsDetailsModel = createSelector(
  (state: StoreState) => state.accounts.details,
  (details) => details
);

export const accountsLoadingModel = createSelector(
  (state: StoreState) => state.accounts.loading,
  (loading) => loading
);

export default createReducer(initialState, (builder) => {
  builder

  .addCase(accountsGetList.pending, (state) => {
    state.accounts = [];
    state.loading = true;
  })
  .addCase(accountsGetList.rejected, (state) => {
    state.accounts = [];
    state.loading = false;
  })
  .addCase(accountsGetList.fulfilled, (state, { payload }) => {
    state.accounts = payload.list;
    state.loading = false;
  })
 
  .addCase(accountsGetDetails.pending, (state) => {
    state.loading = true;
  })
  .addCase(accountsGetDetails.rejected, (state) => {
    state.loading = false;
  })
  .addCase(accountsGetDetails.fulfilled, (state, { payload }) => {
    state.loading = false;
    state.details = payload;
  })

  .addCase(accountsSave.pending, (state) => {
    state.loading = true;
  })
  .addCase(accountsSave.rejected, (state) => {
    state.loading = false;
  })
  .addCase(accountsSave.fulfilled, (state, { payload }) => {
    state.loading = false;
    state.details = payload;
  })

  .addCase(accountsUpdate.pending, (state) => {
    state.loading = true;
  })
  .addCase(accountsUpdate.rejected, (state) => {
    state.loading = false;
  })
  .addCase(accountsUpdate.fulfilled, (state, { payload }) => {
    state.loading = false;
    state.details = payload;
  })

  .addCase(accountsDelete.pending, (state) => {
    state.loading = true;
  })
  .addCase(accountsDelete.rejected, (state) => {
    state.loading = false;
  })
  .addCase(accountsDelete.fulfilled, (state) => {
    state.loading = false;
  })
}); 
