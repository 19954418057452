import { normalizeThousands } from "client/common/utils";
import { Dispatch } from "react";
import { generateButtonProps } from "client/pages/auth/check-phone/check-phone.styles";
import { Exploitation } from "client/model/enums";

const handledHoursMins = (val: string, isBckSp?: boolean) => {
  if (!val) return "0";
  let filteredValue = val;
  if (filteredValue.includes(".")) {
    filteredValue = filteredValue.replace(".", ":");
  }
  if (filteredValue[0] === "0" && filteredValue[1] !== ":") {
    return filteredValue[1];
  }
  if (filteredValue[0] === ":") return "";
  if (filteredValue && filteredValue?.includes(" ")) {
    filteredValue = filteredValue.replace(" ", "");
  }
  if (filteredValue?.includes(":")) {
    const wholePart = normalizeThousands(
      filteredValue.slice(0, filteredValue.indexOf(":"))
    );
    let decimalPart = filteredValue.slice(
      filteredValue.indexOf(":"),
      filteredValue.length
    );

    if (decimalPart.length === 1 && !isBckSp) decimalPart = decimalPart + "00";
    if (decimalPart.length === 2 && !isBckSp)
      decimalPart = decimalPart[0] + "0" + decimalPart[1];
    if (decimalPart.length === 4 && Number(decimalPart[2]) > 5) {
      decimalPart = decimalPart.slice(0, 3);
    }
    if (
      decimalPart.length === 4 &&
      Number(decimalPart[2]) <= 5 &&
      decimalPart[1] === "0"
    ) {
      decimalPart = decimalPart[0] + decimalPart.slice(-2);
    }
    if (
      decimalPart.length === 4 &&
      Number(decimalPart[2]) <= 5 &&
      decimalPart[1] !== "0"
    ) {
      decimalPart = decimalPart.slice(0, 3);
    }
    return wholePart + decimalPart;
  } else {
    return normalizeThousands(filteredValue);
  }
};

export const handleNumberButton = (str: string): string => {
  if (!str) return "0";
  let filteredValue = str;

  if (filteredValue[0] === "0" && filteredValue[1] !== ".")
    return filteredValue[1];
  if (filteredValue[0] === ".") return "";
  if (filteredValue && filteredValue?.includes(" ")) {
    filteredValue = filteredValue.replace(" ", "");
  }

  if (filteredValue?.includes(".")) {
    const wholePart = normalizeThousands(
      filteredValue.slice(0, filteredValue.indexOf("."))
    );
    let decimalPart = filteredValue
      .slice(filteredValue.indexOf("."), filteredValue.length)
      .slice(0, 3);
    if (decimalPart.slice(1, decimalPart.length).includes(".")) {
      decimalPart = decimalPart.replaceAll(".", "");
      decimalPart = "." + decimalPart;
    }
    return wholePart + decimalPart;
  } else {
    return normalizeThousands(filteredValue);
  }
};

const handleBackSpace = (str: string, isHrsMin?: boolean) => {
  if (!str.length) return handleNumberButton(str);
  let newStr = str.slice(0, -1);
  if (newStr[newStr.length - 1] === ".") {
    newStr = newStr.slice(0, -1);
  }
  if (isHrsMin) {
    return handledHoursMins(newStr, true);
  }
  return handleNumberButton(newStr);
};

export const calculatorButtons: string[][] = [
  ["1", "2", "3"],
  ["4", "5", "6"],
  ["7", "8", "9"],
  [".", "0", "bs"],
];

type stateSetter = Dispatch<React.SetStateAction<string>>;
// type voidFunc = (val: string) => void;

// function isStateSetter(setter: stateSetter | voidFunc): setter is stateSetter {
//   return setter.name === "bound dispatchAction";
// }

export const handleChange = (
  volumeSetter: stateSetter,
  button: string,
  explType: Exploitation
) => {
  const isHrsMin = explType === Exploitation.HOURS_MINUTES;
  if (button === "bs") {
    volumeSetter((p) => handleBackSpace(p, isHrsMin));
  } else {
    if (isHrsMin) {
      volumeSetter((p) => handledHoursMins(p + button));
    } else {
      volumeSetter((p) => handleNumberButton(p + button));
    }
  }
};

export const calculatorSubmitButton = {
  ...generateButtonProps(false),
  marginTop: "auto",
};
