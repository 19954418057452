import {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  CSSProperties,
  useEffect,
} from "react";
import { useOutsideClick } from "../../common/utils";
import {
  SelectUIWrapperStyle,
  CustomSelect,
  PopUpSelectOptions,
  StyledOption,
} from "./select.styles";
import { colors } from "client/ui";
import { StyledCurtain } from "./select.styles";
import { FieldMetaState } from "react-final-form";
import CategorySelectWrapper from "./category.select.wrapper";
import { getIsCategoryMenuOpen } from "client/model/operations/operations.selectors";
import { useDispatch, useSelector } from "react-redux";
import { setIsCategoryMenuOpen } from "client/model/operations/operations";
import { CustomSelectAccounts } from "./styled.select.accounts.styles";

interface OptionsProps {
  value: string | number;
  title: string;
  icon?: string;
  iconBg?: string;
  balance?: string;
}
const defaultProps = {
  selectOptions: [],
};

interface StyledSelectProps {
  selectOptions: OptionsProps[];
  value: string | number;
  onChange(value: string | number): void;
  disabled?: boolean;
  icon?: string;
  fieldName: string;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
  defaultOption?: OptionsProps;
  resetFieldState?: (name: string) => void;
  fieldName2?: string;
  setIsClicked?: React.Dispatch<React.SetStateAction<boolean>>;
  isIcon?: boolean;
  isPayee?: boolean;
  onAddCategory: () => void;
  onEditCategories?: () => void;
}

export const OperationNewAccountSelect = ({
  selectOptions = defaultProps.selectOptions,
  value: originValue,
  onChange,
  disabled,
  icon,
  fieldName,
  style,
  defaultOption,
  resetFieldState,
  fieldName2,
  wrapperStyle,
  setIsClicked,
  isIcon = true,
  isPayee,
  onAddCategory,
  onEditCategories,
}: StyledSelectProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const openMenuField = useSelector(getIsCategoryMenuOpen);
  // const [isCategoryMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(openMenuField === fieldName);



  useEffect(() => {
    setIsOpen(openMenuField === fieldName);
  }, [openMenuField]);

  const currentSelect = useMemo(
    () =>
      selectOptions.find(({ value }) => {
        return value.toString() === originValue.toString();
      }),
    [selectOptions, originValue]
  );

  const handleSelect = useCallback(
    (value: any) => () => {
      dispatch(setIsCategoryMenuOpen(""));
      setIsOpen(false);
      onChange(value);
      if (typeof setIsClicked === "function") setIsClicked(!isOpen);
    },
    [isOpen, setIsOpen, onChange]
  );

  const handleOpen = useCallback(() => {
    if (resetFieldState && fieldName2) resetFieldState(fieldName2);
    setIsOpen(!isOpen);
    if (typeof setIsClicked === "function") setIsClicked(!isOpen);
  }, [isOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    if (typeof setIsClicked === "function") setIsClicked(false);
  }, [setIsOpen]);

  const ItemsSelectRender = useMemo(() => {
    return (
      <PopUpSelectOptions
        options={selectOptions}
        isOpen={isOpen}
        fieldTitle={fieldName}
        onClose={onClose}
        isOperationCategory={typeof onAddCategory === "function"}
        toEditCategories={onEditCategories}
      >
        <CategorySelectWrapper
          onAddCategory={onAddCategory}
          onAddText={
            typeof onEditCategories === "function" ? undefined : "Добавьте счёт"
          }
        >
          {selectOptions.map(({ title, value, icon, iconBg }, index) => {
            const isActive =
              selectOptions[index].value.toString() === originValue.toString();
            return (
              <StyledOption
                key={title}
                icon={icon || ""}
                title={title}
                isActive={isActive}
                onClick={handleSelect(value)}
                height="80px"
                background={iconBg || colors.BackgroundGray}
                isIcon={isIcon}
                isColoredIcon={false}
              />
            );
          })}
        </CategorySelectWrapper>
      </PopUpSelectOptions>
    );
  }, [selectOptions, handleSelect, originValue]);

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <SelectUIWrapperStyle
      ref={ref}
      isDisabled={disabled}
      style={{ ...wrapperStyle }}
    >
      <CustomSelectAccounts
        icon={currentSelect?.icon || defaultOption?.icon || ""}
        title={currentSelect?.title || defaultOption?.title || ""}
        balance={currentSelect?.balance || defaultOption?.balance || ""}
        onClick={handleOpen}
        background={currentSelect?.iconBg || colors.BackgroundGray}
        style={{ ...style }}
        isDefault={false}
      />

      {isOpen && <StyledCurtain onClick={onClose} />}

      {ItemsSelectRender}
    </SelectUIWrapperStyle>
  );
};
