import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "client/ui";
import { ChevronDownWrapper } from "client/ui/select/select.styles";

export const HeaderWrapper = styled.main`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 46px;
  z-index: 5;
  background-color: inherit;
`;
export const HeaderContent = styled.div`
  display: flex;
  padding: 16px 16px 0 16px;
  height: 46px;
`;
export const ArrowBackWrapper = styled(Link)`
  width: 36px;
  height: 36px;
  display: flex;
  margin-left: 6px;
`;

export const ArrowBackIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-left: -1px;
`;

export const TitleWrapper = styled.span<{ isRightCornerElement?: boolean }>`
  color: ${colors.textBlack};
  font-size: 20px;
  font-family: "WIX-Medium";
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.15px;
  margin: 0 auto 0 auto;
  text-align: center;
  padding-right: ${({ isRightCornerElement }) =>
    isRightCornerElement ? "0" : "36px"};
  width: 100%;
`;

export const RightCornerWrapper = styled.div`
  display: flex;
  margin-top: -6px;
`;

export const WholePageWrapper = styled.main<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => (bgColor ? bgColor : colors.Tuna)};
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 15;
  min-height: 100%;
`;

export const OperationsListWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding: 16px;
  flex-grow: 5;
`;

export const OpenedCardTitleWrapper = styled.div`
  display: flex;
  min-height: 16px;
  max-height: 16px;
  margin-bottom: 16px;
  /* outline: 1px solid blue; */
`;

export const OpenedMonthWrapper = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  margin-bottom: 8px;
`;

export const ClosedMonthWraper = styled(OpenedMonthWrapper)`
  height: 56px;
  flex-direction: row;
  border-bottom: 1px solid ${colors.BackgroundGray};
`;

export const TitleWraper = styled.span`
  display: flex;
  /* align-items: center; */
  font-size: 18px;
  font-family: "WIX-Regular";
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.6px;
  /* text-align: center; */
`;

export const NumberPart = styled.span`
  margin-left: 8px;
`;

export const ChevronWrapper = styled.div`
  width: 10.67px;
  align-self: center;
  margin: auto 14px 19px auto;
  padding-top: 6px;
  height: 100%;
  display: flex;
  justify-content: center;
  /* outline: 1px solid red; */
`;

export const ChevronUpWrapper = styled(ChevronWrapper)`
  height: auto;
  padding-top: 0;
  margin-top: -2px;
  align-items: flex-start;
`;

export const ChevronIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 7px;
`;

export const DayCardMainWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const DayCardTitle = styled.main`
  color: #999;
  font-size: 14px;
  font-family: "WIX-Regular";
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-top: 16px;
`;

export const UsersFieldMainWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
`;

export const UserWrapper = styled.div`
  display: flex;
  height: 40px;
`;

export const ArrowIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
  align-self: center;
  margin-right: 8px;
  margin-left: 2px;
`;

export const UserNameWrapper = styled.span`
  color: ${colors.textBlack};
  font-size: 14px;
  font-family: "WIX-Regular";
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-left: 8px;
  align-self: center;
`;
