import styled, { css } from "styled-components";
import { colors } from "../common/colors";

const getStyle = ({ avatarSize }: { avatarSize: number }) => css`
  height: ${avatarSize}px;
  width: ${avatarSize}px;
`;

export const AvatarUIWrapperStyle = styled.div<{ avatarSize: number }>`
  ${getStyle}
  background: ${colors.Tuna};
  border-radius: 50%;
`;

export const AvatarUIStyle = styled.img`
  border-radius: 50%;
  object-fit: cover;
`;

export const IconWrapper = styled.section<{
  size: string;
  marginBottom?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "0"};
  border: none;
  border-radius: 50%;
  outline: none;
  overflow: hidden;
`;

export const Icon = styled.img`
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
