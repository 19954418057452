import { PopUpMenuWrapper } from "./popUpMenu.style";

const PopUpWrapper = ({
  children,
  isOpen,
  menuHeight,
}: {
  children: JSX.Element | JSX.Element[];
  isOpen: boolean;
  menuHeight: string;
}) => {
  return (
    <PopUpMenuWrapper menuHeight={menuHeight} isOpen={isOpen}>
      {children}
    </PopUpMenuWrapper>
  );
};

export default PopUpWrapper;
