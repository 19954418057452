import { memo, ForwardRefRenderFunction, HTMLProps, forwardRef, ComponentType } from 'react';
import { colors } from '../common/colors';
import { ColorsVariant } from '../common/types';
import { textStyles } from './typography.constants';
import { TypographyUIStyle } from './typography.styles';
import { TypographyUITextStyles } from './typography.types';

export interface TypographyUIComponentProps extends Omit<HTMLProps<HTMLElement>, 'size' | 'css'> {
    element?: keyof JSX.IntrinsicElements | ComponentType<unknown>;
    textStyle?: TypographyUITextStyles;
    textColor?: ColorsVariant;
    whiteSpace?: string;
}

export const defaultProps = {
    element: 'span',
    textStyle: TypographyUITextStyles.body,
    textColor: ColorsVariant.Mercury,
    whiteSpace: 'normal',

};

const TypographyUIComponent: ForwardRefRenderFunction<HTMLElement, TypographyUIComponentProps> = ({
    children,
    element = defaultProps.element,
    textStyle = defaultProps.textStyle,
    textColor = defaultProps.textColor,
    whiteSpace = defaultProps.whiteSpace,
    ...props
}, ref) => {
    const elementTextStyle = textStyles[textStyle];
    const currentlyTextColor = colors[textColor];

    return (
        <TypographyUIStyle
            whiteSpace={whiteSpace}
            textColor={currentlyTextColor}
            elementStyle={elementTextStyle}
            ref={ref}
            {...props}
            as={element}
        >
            {children}
        </TypographyUIStyle>
    );
};

export const TypographyUI = memo(forwardRef(TypographyUIComponent));
