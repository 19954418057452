import styled from "styled-components";
import { TypographyUI } from "../typography/typography.component";
import { colors } from "../common/colors";

export const InputUIWrapperStyle = styled.div`
  position: relative;
`;

export const DoubleInputUIWrapperStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  > * {
    &:not(span) {
      width: 130px;
    }
  }
`;

export const InputUIPlaceholderStyle = styled(TypographyUI)`
  position: absolute;
  top: 20px;
  left: 16px;
`;

const commonInput = `
width: 100%;
height: 56px;
background: #f9f9f9;
padding: 0 14px;
border-radius: 12px;
text-overflow: ellipsis;
font-family: "PP Pangram Medium";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18;
letter-spacing: 0.5px;
color: #000000;
padding-bottom: 0;`;

export const StyledInput = styled.input<{ isHasError: boolean }>`
  ${commonInput};
  border: ${({ isHasError }) =>
    isHasError ? `1px solid ${colors.Carnation}` : "none"};

  &:focus {
    /* &:not(:read-only) */
    outline: none;
  }
`;

export const InputUIStyle = styled(TypographyUI)<{
  isHasError: boolean;
  hasBorderOnFocus?: boolean;
  isFocused?: boolean;
}>`
    width: 100%;
    height: 56px;
    padding: 0 16px;
    background: ${colors.Tuna2};
    border-radius: 4px;
    text-overflow: ellipsis;
    border: 1px solid ${({ isHasError, isFocused }) =>
      isHasError
        ? colors.Carnation
        : isFocused
        ? colors.greenButton
        : "#E7EAEB"};
    border-radius: 12px;
    font-family: 'WIX-Medium';
    &:focus {
        &:not(:read-only)
        border: 1px solid ${colors.CaribbeanGreen};
    };
        &:focus-visible  {
        outline: none;
    }
`;

export const ProxyInputUIStyle = styled(TypographyUI)`
    width: 100%;
    height: 100%;
    padding: 0 20px;
    border: none;
    background-color: inherit;

    &::placeholder {
        color: ${colors.Mercury};
        font-height: 400px;
        font-size: 18px;
        line-height: 21px;
    }
     }
`;
