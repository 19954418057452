import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { TypographyUITextStyles } from '../typography/typography.types';
import { InputCodeInputUIStyle, InputCodeUIStyle } from './input-code.styles';

export interface InputCodeUIComponentProps {
    onChange(code: string): void;
}

const InputCodeUIComponent = ({ onChange }: InputCodeUIComponentProps) => {
    const [ code, setCode ] = useState<string[]>([...Array(4)].map(() => ''));
    const codeInputs = useRef<HTMLInputElement[]>([]);

    const handleOnChange = useCallback((index: number) =>
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            const value = target.value;
            if(!!value && !(/[^0-9]/.test(value))) {
                const newCode = [...code];
                const isNotEmty = newCode[index] !== '';
                const isLastCell = index === newCode.length - 1;
                if((!isNotEmty || isNotEmty) && isLastCell) {
                    newCode[index] = value;
                } else if(isNotEmty) {
                    newCode[index] = value;
                    codeInputs.current[index + 1].select();
                } else if(!isNotEmty) {
                    newCode[index] = value;
                    codeInputs.current[index + 1].select();
                }
                setCode(newCode);
                onChange(newCode.join(''));
            }
      },[code, onChange]);

    const handleKeyUp = useCallback((index: number) =>
        ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
             if (key === 'Backspace') {
                const newCode = [...code];
                const isNotEmty = newCode[index] !== '';
                const isFirstCell = index === 0;
                if(isNotEmty || (isFirstCell && isNotEmty)) {
                    newCode[index] = '';
                } else if (!isFirstCell && !isNotEmty) {
                    newCode[index - 1] = '';
                    codeInputs.current[index - 1].focus();
                }
                setCode(newCode);
                onChange(newCode.join(''));
            }
      },[code, onChange]);

    const codeRender = useMemo(() => {
        return code.map((value: string, index: number) => (
            <InputCodeInputUIStyle
                key={index}
                element='input'
                inputMode='numeric'
                type='text'
                maxLength={1}
                value={value}
                isActive={!!value}
                autoFocus={!code[0].length && index === 0}
                onChange={handleOnChange(index)}
                onKeyUp={handleKeyUp(index)}
                ref={(ref: HTMLInputElement) => codeInputs.current[index] = ref}
                textStyle={TypographyUITextStyles.h5}
            />
        ));
    }, [code, handleOnChange, handleKeyUp]);

    return (
        <InputCodeUIStyle>
            {codeRender}
        </InputCodeUIStyle>
    );
};

export const InputCodeUI = memo(InputCodeUIComponent);
