import styled from "styled-components";
import { colors } from "client/ui";

export const AddCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 5;
  border: none;
  width: 100%;
  position: relative;
`;

export const ButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  border-radius: 12px;
  background-color: ${colors.BackgroundGray};
  padding: 10px;
  margin-bottom: 16px;
  position: relative;
`;
