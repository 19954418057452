import { memo } from 'react';
import { colors } from '../common/colors';
import { ColorsVariant } from '../common/types';
import { ChipUIStyle } from './chip.styles';

export interface ChipUIComponentProps {
    chipBackgroundColor?: ColorsVariant;
    chipTextColor?: ColorsVariant;
    chipText: string;
}

export const defaultProps = {
    chipBackgroundColor: ColorsVariant.MidGray,
    chipTextColor: ColorsVariant.Mercury,
}

const ChipUIComponent = ({
    chipBackgroundColor = defaultProps.chipBackgroundColor,
    chipTextColor = defaultProps.chipTextColor,
    chipText,
    ...props
}: ChipUIComponentProps) => {
    const colorBackground = colors[chipBackgroundColor];
    const colorText = colors[chipTextColor];

    return (
        <ChipUIStyle {...props} colorText={colorText} colorBackground={colorBackground}>
            {chipText}
        </ChipUIStyle>
    );
};

export const ChipUI = memo(ChipUIComponent);
