import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { SnackbarMessageType } from '../enums';
import { StoreState } from '../types';
import { SnackbarModel } from './types';

const initialState: SnackbarModel = {
  messageType: SnackbarMessageType.SUCCESS,
  message: '',
  show: false,
};

export const snackbarShow = createAction<Omit<SnackbarModel, 'show'>>('snackbarShow');
export const snackbarHide = createAction('snackbarHide');

export const snackbarModel = createSelector(
  (state: StoreState) => state.snackbar,
  (snackbar) => snackbar
);

export default createReducer(initialState, (builder) => {
  builder
  .addCase(snackbarShow, (state, { payload }) => {
    state.messageType = payload.messageType;
    state.message = payload.message;
    state.show = true;
  })
  .addCase(snackbarHide, () => initialState)
}); 
