/* eslint-disable @typescript-eslint/no-redeclare */
export enum UserRole {
  GUEST = 0,
  USER = 1,
  ADMIN = 2,
  SUPER_ADMIN = 3,
}

export enum AccountType {
  CASH = 0,
  NON_CASH = 1,
  BANK_CARD = 2,
  E_WALLET = 3,
}

// export enum Currency {
//   RUB = 0,
//   USD = 1,
//   EUR = 2,
// }
export const currencies = [0, 1, 2];
export type Currency = (typeof currencies)[number];
export const currName = ["RUB", "USD", "EUR"];
export type CurrName = (typeof currName)[number];

export const Currency: { [key: CurrName]: Currency } = {
  RUB: 0,
  USD: 1,
  EUR: 2,
};

export enum ExploitationType {
  NONE = 0,
  ABSOLUTE_VALUE = 1,
  TRACKING_DEVICE = 2,
}

export enum Exploitation {
  NONE = 0,
  HOURS_MINUTES = 1,
  HOURS_FRACTION = 2,
  KILO = 3,
}

export enum ExploitationTrackingUnits {
  NONE = 0,
  HOURS_MINUTES = 1,
  HOURS_FRACTIONS = 2,
  KMS = 3,
}

export enum OperationType {
  EXPENSES = 0,
  INCOME = 1,
  USER = 2,
}

export enum ProjectsParticipantsRole {
  USER = 0,
  ADMIN = 1,
  SUPER_ADMIN = 2,
}

export enum SnackbarMessageType {
  SUCCESS = "success",
  WRONG = "wrong",
}

export enum AccountingMethod {
  ABSOLUTE_VALUE = 0,
  ACCOUNTING_DEVICE = 1,
}

export enum ProjectCategory {
  REAL_ESTATE = 1,
  TRANSPORT = 2,
  FINANCE = 3,
  OTHER = 4,
}

export enum ExplCreationMode {
  PROJECT_EDIT = 1,
  PROJECT_CREATION = 2,
  EXPLOITATION_CREATION = 3,
}
