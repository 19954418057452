import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user';
import accountsReducer from './accounts/accounts';
import projectsReducer from './projects/projects';
import snackbarReducer from './snackbar/snackbar';
import operationsReducer from './operations/operations';
import { StoreState } from './types';
import { useDispatch } from 'react-redux';

// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch = useDispatch<AppDispatch>();

export const store = configureStore<StoreState>({
    reducer: {
      user: userReducer,
      accounts: accountsReducer,
      projects: projectsReducer,
      snackbar: snackbarReducer,
      operations: operationsReducer,
    },
    devTools: true,
});

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();


