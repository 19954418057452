import {
  memo,
  useContext,
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageName } from "client/common/pages/page-name";
import { pageContext } from "client/common/pages/page.settings";
import {
  menuAccount,
  menuAccountActive,
  menuProjects,
  menuProjectsActive,
} from "../icons";
import SideSection from "./SideSection";
import CenterPiece from "./CenterPiece";
import {
  MenuMainContainer,
  MenuSectionsWrapper,
  BottomSectionWrapper,
} from "./root-header.styles";

const FooterMenu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { getPageUrl, checkCurrentPageName, getCurrentPageName } =
    useContext(pageContext);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(getCurrentPageName(pathname));

  const handleSetActiveTab = useCallback(
    (link: string) => () => {
      history.push(link);
    },
    [history]
  );

  useEffect(() => {
    setCurrentPage(getCurrentPageName(pathname));
  }, [pathname]);

  return (
    <MenuMainContainer>
      <MenuSectionsWrapper>
        <SideSection
          imageSource={
            checkCurrentPageName(PageName.PROJECTS)
              ? menuProjectsActive
              : menuProjects
          }
          isActive={checkCurrentPageName(PageName.PROJECTS)}
          title={t("PROJECTS.MAIN_MENU")}
          onClick={handleSetActiveTab(getPageUrl(PageName.PROJECTS))}
        />
        <CenterPiece />
        <SideSection
          imageSource={
            checkCurrentPageName(PageName.ACCOUNT)
              ? menuAccountActive
              : menuAccount
          }
          isActive={checkCurrentPageName(PageName.ACCOUNT)}
          title={t("ACCOUNT.MAIN_MENU")}
          onClick={handleSetActiveTab(getPageUrl(PageName.ACCOUNT))}
        />
      </MenuSectionsWrapper>
      <BottomSectionWrapper />
    </MenuMainContainer>
  );
};

export const FooterAppMenu = memo(FooterMenu);
