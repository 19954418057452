import styled from "styled-components";
import { colors } from "client/ui";

export const AccountEditFromStyle = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0;
`;

export const Label = styled.span`
  height: 14px;
  font-family: "WIX-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  margin-bottom: 8px;
  color: ${colors.textBlack};
`;

export const InputWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const AvatarInputWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  overflow: hidden;
`;
