import styled, { css } from "styled-components";
import { colors } from "client/ui";

export const FabButtonUIButtonStyle = styled.button`
  height: 64px;
  width: 64px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
`;

export const FabButtonUIWrapperStyle = styled.div<{
  isOnProjectDetailsPage: boolean;
}>`
  position: fixed;
  bottom: 48px;
  right: ${({ isOnProjectDetailsPage }) =>
    isOnProjectDetailsPage ? "20px" : "calc(50% - 32px)"};
  z-index: 2;
`;

export const FabButtonUIButtonsListStyle = styled.div`
  padding: 25px;
  margin: -25px;
`;

export const FabButtonUIMainButtonStyle = styled(FabButtonUIButtonStyle)<{
  isOpen: boolean;
}>`
  background-color: ${colors.greenButton};
  transition: background-color 0.3s ease;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: ${colors.RadicalRed};
    `}
`;

export const StyledButton = styled(FabButtonUIButtonStyle)`
  background-color: ${colors.greenButton};
  transition: background-color 0.3s ease;
`;

export const FabButtonUIButtonListUlStyle = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style: none;
`;

export const FabButtonUIButtonListLiStyle = styled.li`
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  padding: 8px 0;
  margin: -8px 0;
  transition: transform 0.2s ease-in-out;
`;

export const FabButtonUIButtonOneListLiStyle = styled(
  FabButtonUIButtonListLiStyle
)<{ isOpen: boolean }>`
  transform: translateY(-80px) scale(0);
  transition-delay: 0.03s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(-80px) scale(1);
      transition-delay: 0.03s;
    `}
`;

export const FabButtonUIButtonTwoListLiStyle = styled(
  FabButtonUIButtonListLiStyle
)<{ isOpen: boolean }>`
  transform: translateY(-160px) scale(0);
  transition-delay: 0.03s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(-160px) scale(1);
      transition-delay: 0.09s;
    `}
`;

export const FabButtonUIButtonthreeListLiStyle = styled(
  FabButtonUIButtonListLiStyle
)<{ isOpen: boolean }>`
  transform: translateY(-240px) scale(0);
  transition-delay: 0.03s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(-240px) scale(1);
      transition-delay: 0.15s;
    `}
`;

export const FabButtonUIButtonListStyle = styled(FabButtonUIButtonStyle)`
  background-color: ${colors.CaribbeanGreen};
`;

export const FabButtonUIButtonListStyleExpenses = styled(
  FabButtonUIButtonStyle
)`
  background-color: ${colors.Carnation};
`;

export const FabButtonUIButtonListStyleExploitation = styled(
  FabButtonUIButtonStyle
)`
  background-color: ${colors.FuelYellow};
`;

export const FabButtonUIButtonIconListStyle = styled.img`
  height: 17px;
  width: 17px;
`;

export const FabButtonUIButtonIconStyle = styled.img`
  height: 24px;
  width: 24px;
  object-fit: cover;
`;
