import styled, { css } from "styled-components";
import { colors } from "../common/colors";

export const MenuMainContainer = styled.main`
  width: 100%;
  height: 85px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

export const MenuSectionsWrapper = styled.div`
  height: 73px;
  width: 100%;
  display: flex;
  background-color: ${colors.white};
`;

const section = `
  width: 33.33%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
border: none;
`;

export const Sides = styled.div`
  ${section}
  padding-top: 10px;
  padding-bottom: 12px;

  background-color: ${colors.white};
`;

export const CenterPieceMain = styled.section`
  ${section}
`;

export const CenterPieceButton = styled.div`
  width: 100%;
  height: 71px;
  background: ${colors.white};
`;

export const UpperEmptyDiv = styled.div`
  width: 100%;
  height: 24px;
`;

export const MenuButtonSection = styled.section`
  height: 36px;
  width: 100%;
  background: ${colors.white};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ButtonImg = styled.img`
  height: 24px;
  width: 24px;
  background: ${colors.white};
  align-self: center;
  // align-self: center;
`;

export const DotSection = styled.section`
  width: 100%;
  height: 5px;
  background: ${colors.white};
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
`;

export const Dot = styled.div<{ isActive?: boolean }>`
  min-height: 5px;
  min-width: 5px;
  max-width: 5px;
  border-radius: 50%;
  align-self: center;
  background: ${({ isActive }) =>
    isActive ? colors.CaribbeanGreen : colors.white};
  margin: 0 auto 4px auto;
`;

export const MenuTitleSecton = styled.div`
  height: 28px;
  display: flex;
  justify-content: center;
  // align-items: center;
  background: ${colors.white};
`;

export const StyledTitle = styled.div<{ isActive: boolean }>`
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? "#10B77F" : "#8F8F8F")};
  text-align: center;
`;

export const BottomSectionWrapper = styled.div`
  width: 100%;
  height: 15px;
  background: ${colors.white};
  display: flex;
`;

const bottomBorder = `2px solid black`;

export const BottomActiveMenuSection = styled.section<{ isActive: boolean }>`
  width: 22%;
  border-top: ${({ isActive }) => (isActive ? bottomBorder : "none")};
  margin-top: 3px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`;

//==================old UI================================//
export const FooterAppMenuStyle = styled.div`
  width: 100%;
  height: 71px;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background: ${colors.Gray85};
`;

export const TabsUIStyle = styled.div`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
`;

export const TabsButtonWrapperUIStyle = styled.span`
  width: 24px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TabsButtonIconUIStyle = styled.img`
  height: 24px;
  width: 24px;
`;

export const TabsButtonTitleUIStyle = styled.span`
  // position: absolute;
  right: 0;
  opacity: 0;
  visibility: hidden;
  color: ${colors.CaribbeanGreen};
  font-weight: bold;
  transition: transform 1s;
  transform: translate(10px, 0);
`;

export const TabsButtonUIStyle = styled.button<{ isActive: boolean }>`
  height: 71px;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  padding-right: 12px;
  padding-left: 12px;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  outline: none;
  background: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1;
  transition: flex 1s ease-in-out;
  transition: width 1s ease-in-out;

  ${({ isActive }) =>
    isActive &&
    css`
      ${TabsButtonWrapperUIStyle} {
        width: 126px;
      }

      ${TabsButtonTitleUIStyle} {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
      }
    `}
`;
