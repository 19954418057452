export enum ColorsVariant {
  CaribbeanGreen = "CaribbeanGreen",
  CaribbeanGreen2 = "CaribbeanGreen2",
  Aquamarine = "Aquamarine",
  AeroBlue = "AeroBlue",
  Tuna = "Tuna",
  Tuna2 = "Tuna2",
  Concrete = "Concrete",
  Trout = "Trout",
  Gray = "Gray",
  DarkGray = "DarkGray",
  MidGray = "MidGray",
  GoldenTainoi = "GoldenTainoi",
  Carnation = "Carnation",
  BaliHai = "BaliHai",
  AzureRadiance = "AzureRadiance",
  Heliotrope = "Heliotrope",
  Mercury = "Mercury",
  Mako = "Mako",
  SilverChalice = "SilverChalice",
  Silver = "Silver",
  Bombay = "Bombay",
  Jumbo = "Jumbo",
  VividTangerine = "VividTangerine",
  Manatee = "Manatee",
  Mandy = "Mandy",
  FuelYellow = "FuelYellow",
  Cerulean = "Cerulean",
  RadicalRed = "RadicalRed",
  Transparent = "Transparent",
  StormGrey = "StormGrey",
  Gray85 = "Gray85",
  White = "white",
  GreenButton = "greenButton",
  TextBlack = "textBlack",
  BackgroundGray = "BackgroundGray",
  TextGray = "TextGray",
  RedText = "RedText",
}
