import {
  memo,
  Children,
  ReactNode,
  ReactElement,
  JSXElementConstructor,
  useState,
  useRef,
} from "react";
import { ListtUIElementStyle, ListtUIStyle } from "./list.styles";

export interface ListUIComponentProps {
  children: any[];
}

const ListUIComponent = ({ children, ...props }: ListUIComponentProps) => {
  const list = Children.map(children, (child: ReactElement, index) => {
    let marginBottom = "16px";
    if (child === undefined || child === null) {
      marginBottom = "0";
    }

    return <>{child}</>;
  });

  return <ListtUIStyle {...props}>{list}</ListtUIStyle>;
};

export const ListUI = memo(ListUIComponent);
