import styled from "styled-components";
import { colors } from "../common/colors";
import { arrowLeft } from "../icons";
import { TypographyUI } from "../typography/typography.component";
import { ColorsVariant } from "../common/types";

export const UIPageStyle = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.Tuna};
  padding: 0 16px;
`;

export const UIPageMobileViewStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.Tuna};
`;

export const UIPageMobileViewHeaderStyle = styled.div`
  height: 56px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: inherit;
`;

export const UIPageMobileViewHeaderTitleStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const UIPageMobileViewHeaderOnCloseStyle = styled.button`
  height: 36px;
  width: 36px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  background: ${colors.Transparent} url(${arrowLeft});
  color: ${colors.textBlack};
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
`;

export const UIPageMobileViewHeaderTitleTextStyle = styled(TypographyUI)<{
  color: string;
  isRightCornerElement?: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  align-self: center;
  color: ${({ color }) => color};
  font-family: "WIX-Medium";
  padding-right: ${({ isRightCornerElement }) =>
    isRightCornerElement ? "0" : "36px"};
`;

export const UIPageMobileViewContentStyle = styled.div`
  height: 100%;
  flex: 1 1 0%;
  position: relative;
  padding: 0 16px;
`;

export const UIPageMobileViewContentScrollStyle = styled.div`
  height: 100%;
  flex: 1 1 0%;
  position: relative;
`;

export const UIPageMobileViewContentInnerScrollStyle = styled.div`
  padding: 0 16px;
`;
