import styled from "styled-components";
import { TypographyUI } from "../typography/typography.component";
import { ColorsVariant } from "client/ui/common/types";
//'../common/types';
import { TypographyUITextStyles } from "client/ui/typography/typography.types";
import { colors } from "client/ui";
//'./typography.constants';

export const ErrorWrapperUIStyle = styled(TypographyUI)`
  display: inline-block;
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  letter-spacing: 0.4px;
`;

export const ComplexErrorWrapperUIStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 8px;
  color: ${colors.Carnation};
  fontweight: 400;
  fontsize: 14;
  lineheight: 16;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
`;

export const WrapperNoMistakesYet = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  > * {
    &:not(span) {
      width: 100px;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
