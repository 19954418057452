export interface TypographyUIElementStyle {
  fontWeight: number;
  fontSize: number;
  lineHeight: number;
  fontFamily?: string;
  fontStyle?: string;
  letterSpacing?: string;
}

export enum TypographyUITextStyles {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  subtitle1 = "subtitle1",
  subtitle2 = "subtitle2",
  body = "body",
  button18 = "button18",
  button16 = "button16",
  button14 = "button14",
  button12 = "button12",
  caption = "caption",
  overline = "overline",
  tabtext = "tabtext",
  pageTop = "pageTop",
  inputText = "inputText",
  errorText = "errorText",
}
